export * from './activation-volume';

export interface IReportsSelector {
  isLoading: boolean;
}

export interface IReportPageProps {
  page: string;
}

export enum EReportPage {
  ACTIVATION_VOLUME = 'activationVolume',
  BULK_INCIDENTS = 'bulkIncidents',
}

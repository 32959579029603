/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Space, Tooltip, Typography } from 'antd';
import './styles/list-incidents.scss';
import { useTranslation } from 'react-i18next';
import Headers from './components/list-incidents/Headers';
import Filters from './components/list-incidents/Filters';
import { IFiltersIncidentsFormValues } from './interfaces';
import moment from 'moment';
import CreateIncidents from './components/list-incidents/CreateIncidents';
import { useAppSelector } from 'app/hooks';
import { loadingIncidentsAction } from 'actions/incidents';
import { useHistory } from 'react-router';
import { FlagFilled } from '@ant-design/icons';
import { trim, get } from 'lodash';
import { renderImageResponseStatus } from './helpers';
import InfinityLoadTable from 'components/common/InfinityLoadTable';
import { isEmpty } from 'lodash';

const ListIncidents = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const showHidden: boolean = useAppSelector((state) => state.auth.showHidden);
  const list = useAppSelector((state) => state.incidents.list);
  const isLoading = useAppSelector((state) => state.incidents.isLoading);
  const [isVisibleCreate, setIsVisibleCreate] = React.useState<boolean>(false);

  const filtersRef: any = React.useRef();
  const [query, setQuery] = React.useState<any>(null);
  const [trigger, setTrigger] = React.useState(0);

  const [sortType, setSortType] = React.useState<string>('');
  const [ascOrder, setAscOrder] = React.useState<boolean>(true);
  const myOrg = useAppSelector((state) => state.users.selectedOrg);

  React.useEffect(() => {
    const filtersValues: IFiltersIncidentsFormValues = filtersRef.current.getFilterValues();
    const query = {
      organization_id: myOrg._id,
      search: trim(filtersValues.search),
      status: filtersValues.status,
      response_status: filtersValues.responseStatus,
      assigned_to_me: filtersValues.assigned,
      show_hidden: showHidden,
      sort_by: '',
      sort_order: -1,
    };
    setQuery(query);
  }, [myOrg, showHidden, trigger]);

  React.useEffect(() => {
    return () => {
      setIsVisibleCreate(false);
    };
  }, []);

  const handleVisibleCreate = () => {
    setIsVisibleCreate(true);
  };

  const handleCancelVisibleCreate = () => {
    setIsVisibleCreate(false);
  };

  const onFilter = () => {
    setTrigger((pre) => pre + 1);
  };

  const onRow = (item: any, index: any) => {
    const isUnread = get(item, 'status.code', '') === 1;
    let className = index % 2 === 0 ? 'row-odd' : '';
    className = isUnread ? `${className} row-bold` : className;
    return {
      className: className,
      onClick: () => history.push(`/admin/incidents/${item._id}`),
    };
  };

  const handleClickSorter = (sorter: any) => {
    const newAscOrder = sorter.order === 'ascend';
    const type = sorter.field;
    if (sorter.order) {
      setSortType(type);
      setAscOrder(newAscOrder);
    } else {
      setSortType('');
      setAscOrder(newAscOrder);
    }
    const sortOrder = newAscOrder ? 1 : -1;
    setQuery({ ...query, sort_by: type, sort_order: sortOrder });
  };

  const columns: any = [
    {
      title: () => {
        return (
          <>
            <div className="table-header">
              <span
                className={
                  sortType === 'sequence'
                    ? ascOrder === true
                      ? 'upIcon'
                      : 'downIcon'
                    : 'normalIcon'
                }
              >
                {t('incidents.list.incident')}
              </span>
            </div>
          </>
        );
      },
      dataIndex: 'sequence',
      width: 104,
      className: 'incident-sequence',
      align: 'center',
      showSorterTooltip: false,
      ellipsis: {
        showTitle: false,
      },
      sorter: (a: any, b: any) => a.sequence - b.sequence,
      render: (sequence: number) => (
        <Tooltip placement="bottomLeft" title={sequence}>
          <Typography.Text className="txt-incident">{sequence}</Typography.Text>
        </Tooltip>
      ),
    },
    {
      title: t('incidents.list.msgs'),
      dataIndex: 'totalMessage',
      width: 96,
      render: (totalMessage: number, record: any) => {
        const { src, tooltip } = renderImageResponseStatus(record?.response_status, totalMessage);
        return (
          <Space>
            {src && (
              <Tooltip placement="bottomLeft" title={t(`incidents.list.${tooltip}`)}>
                <img alt="" src={src} />
              </Tooltip>
            )}
            <Typography.Text>{totalMessage || 0}</Typography.Text>
          </Space>
        );
      },
    },
    {
      title: t('incidents.list.status'),
      dataIndex: 'status',
      width: 146,
      ellipsis: {
        showTitle: false,
      },
      render: (status: any) => {
        return (
          <Tooltip placement="bottomLeft" title={status?.name}>
            <Typography.Text>{status?.name}</Typography.Text>
          </Tooltip>
        );
      },
    },
    {
      title: t('incidents.list.description'),
      dataIndex: 'description',
      width: 330,
      ellipsis: {
        showTitle: false,
      },
      render: (record: string) => {
        return (
          <Tooltip
            placement="bottomLeft"
            title={
              <span style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{record}</span>
            }
          >
            <span className="text-ellipsis">{record}</span>
          </Tooltip>
        );
      },
    },
    {
      title: t('incidents.list.org'),
      dataIndex: 'organization',
      width: 300,
      ellipsis: {
        showTitle: false,
      },
      render: (_: any, record: any) => {
        const orgName: string = record?.org_id?.name;
        return (
          <Tooltip placement="bottomLeft" title={orgName}>
            {orgName?.length < 60 ? orgName : orgName.substring(0, 32) + `...`}
            {/* {orgName} */}
          </Tooltip>
        );
      },
    },
    {
      title: () => {
        return (
          <div className="table-header">
            <span
              className={
                sortType === 'updatedAt'
                  ? ascOrder === true
                    ? 'upIcon'
                    : 'downIcon'
                  : 'normalIcon'
              }
            >
              {t('incidents.list.updated')}
            </span>
          </div>
        );
      },
      dataIndex: 'updatedAt',
      width: 125,
      sorter: (a: any, b: any) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
      showSorterTooltip: false,
      ellipsis: {
        showTitle: false,
      },
      render: (record: string) => {
        const text: string = moment(record).format('YYYY/MM/DD');
        return (
          <Tooltip placement="bottomLeft" title={text}>
            {text}
          </Tooltip>
        );
      },
    },
    {
      title: () => {
        return (
          <div className="table-header">
            <span
              className={
                sortType === 'createdAt'
                  ? ascOrder === true
                    ? 'upIcon'
                    : 'downIcon'
                  : 'normalIcon'
              }
            >
              {t('incidents.list.submitted')}
            </span>
          </div>
        );
      },
      dataIndex: 'createdAt',
      width: 125,
      sorter: (a: any, b: any) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      showSorterTooltip: false,
      ellipsis: {
        showTitle: false,
      },
      render: (record: string) => {
        const text: string = moment(record).format('YYYY/MM/DD');
        return (
          <Tooltip placement="bottomLeft" title={text}>
            {text}
          </Tooltip>
        );
      },
    },
    {
      title: t('incidents.list.priority'),
      dataIndex: 'priority',
      width: 80,
      align: 'center',
      ellipsis: {
        showTitle: false,
      },
      render: (priority: any) => {
        const { name, color } = priority;
        return (
          <>
            {
              <Tooltip placement="bottomLeft" title={priority?.name}>
                {!isEmpty(name) && !isEmpty(color) ? (
                  <FlagFilled style={{ color: priority?.color }} />
                ) : (
                  <div></div>
                )}
              </Tooltip>
            }
          </>
        );
      },
    },
  ];

  return (
    <div className="list-incidents">
      <Headers />
      <Filters onFilter={onFilter} handleVisibleCreate={handleVisibleCreate} ref={filtersRef} />
      <InfinityLoadTable
        columns={columns}
        dataSource={list}
        className="table-incident"
        onRow={onRow}
        scroll={{ y: 'calc(100vh - 290px)' }}
        query={query}
        loadDataAction={loadingIncidentsAction}
        isLoading={isLoading}
        dataType={t('list.empty.incident')}
        onchangeSorter={handleClickSorter}
      />
      <CreateIncidents isVisible={isVisibleCreate} onCancel={handleCancelVisibleCreate} />
    </div>
  );
};

export default React.memo(ListIncidents);

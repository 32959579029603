/* eslint-disable react-hooks/exhaustive-deps */
import {
  Space,
  Typography,
  Form,
  Input,
  Spin,
  Comment,
  Popover,
  notification,
  Tooltip,
} from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  EFilterResponseStatusIncidents,
  IPayloadAppendMessageIncidents,
  IPayloadLoadingChatIncidents,
  IPayloadReadChatIncidents,
  IResponseIncidentDetail,
} from 'pages/incidents/interfaces';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Picker from 'emoji-picker-react';
import { ModalConfirm } from 'components/modal-confirm';
import {
  disconnectSocket,
  initialSocketChat,
  leaveRoom,
  onHandleErrorSocket,
  onReceiveMessage,
  sendMessage,
} from 'libs/socket';
import { useParams } from 'react-router';
import {
  appendMessageAction,
  changeStatusChatAction,
  loadingChatIncidentsAction,
  readChatIncidentsAction,
  resetChatAction,
} from 'actions/incidents';
import { storage } from 'utils';
import { EStatus } from './Notes';
import trim from 'lodash/trim';
import get from 'lodash/get';
import Drag from './Drag';

// import { Picker } from 'emoji-mart';

interface IChatProps {
  isVisible: boolean;
  isDisable: boolean;
  onCancel: () => void;
  data: IResponseIncidentDetail | null;
  defaultVisibleChat: boolean;
  currentRole: string;
}

const Chat = ({
  isDisable,
  isVisible,
  onCancel,
  data,
  defaultVisibleChat = false,
  currentRole,
}: IChatProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { id }: any = useParams();
  const dispatch = useAppDispatch();

  const inputRef: any = React.useRef();
  const profile: any = useAppSelector((state) => state.auth.profile);
  const statusChats: string = useAppSelector((state) => state.incidents.statusChats);
  const chats: any[] = useAppSelector((state) => state.incidents.chats);

  const [isMinimize, setIsMinimize] = React.useState<boolean>(false);
  const [isVisibleClose, setIsVisibleClose] = React.useState<boolean>(false);
  const [isDisableInputChat, setIsDisableInputChat] = React.useState<boolean>(false);

  const isClosed: boolean = data?.status?.code === EStatus.CLOSED;

  const initialChatRoom = () =>
    new Promise<void>((resolve) => {
      const token: string = storage.getToken();

      if (id && token) {
        initialSocketChat(token, id);
      }
      resolve();
    });

  const loadingChats = React.useCallback(() => {
    const payload: IPayloadLoadingChatIncidents = {
      incident_id: id,
    };

    return dispatch(loadingChatIncidentsAction(payload));
  }, [id]);

  React.useEffect(() => {
    if (!defaultVisibleChat) {
      loadingChats();
    }

    return () => {
      dispatch(resetChatAction());
    };
  }, []);

  React.useEffect(() => {
    if (isVisible) {
      loadingChats();

      initialChatRoom().then(() => {
        onReceiveMessage((msg: any) => {
          const payload: IPayloadAppendMessageIncidents = {
            msg,
            onSuccess: () => {
              form.resetFields();
              inputRef?.current?.focus?.();
              changeChatStatus(EFilterResponseStatusIncidents.READ_ANSWERED);
              if (!msg?.is_admin) {
                handleReadMessage();
              }
            },
          };
          return dispatch(appendMessageAction(payload));
        });
        onHandleErrorSocket((msg: any) => {
          if (msg) {
            switch (get(msg, 'response.error_code')) {
              case 'IC1007':
                notification.error({
                  message: t(`incidents.detail.listDetail.header.text.closed`),
                });
                form.resetFields();
                setIsDisableInputChat(true);
                break;
              // others case
              default:
                break;
            }
          }
        });
      });
    }

    return () => {
      if (isVisible) {
        leaveRoom(id);
        disconnectSocket();
      }
    };
  }, [isVisible]);

  const changeChatStatus = (status: EFilterResponseStatusIncidents | string) =>
    dispatch(changeStatusChatAction(status));

  React.useEffect(() => {
    if (data?.response_status) {
      changeChatStatus(data.response_status);
    }

    if (data?.status?.code === EStatus.CLOSED) {
      form.resetFields();
    }
  }, [data]);

  const handleConfirmClose = () => {
    form.resetFields();
    handleCancelClose();
    setTimeout(() => {
      onCancel();
    }, 300);
  };

  const handleVisibleClose = (event: any) => {
    event.stopPropagation();
    setIsVisibleClose(true);
  };

  const handleCancelClose = () => {
    setIsVisibleClose(false);
  };

  const onClickClose = (event: any) => {
    const textInput: string = form.getFieldValue('message');

    if (!textInput) {
      return handleConfirmClose();
    }

    return handleVisibleClose(event);
  };

  const onEmojiClick = (event: any, emojiObject: any) => {
    const messageValue: string = form.getFieldValue('message');

    const newMessage: string = `${messageValue} ${emojiObject?.emoji} `;

    if (!isClosed) {
      form.setFieldsValue({ message: newMessage });
    }
  };

  const fetchMoreData = () => {
    console.log('🚀 ~ fetchMoreData');
  };

  const onMinimizeChat = (event: any) => {
    setIsMinimize(true);
  };

  const renderIconChat = () => {
    let src: string = '';
    switch (statusChats) {
      case EFilterResponseStatusIncidents.READ_ANSWERED:
        src = '/images/ic-read-anwered.png';
        break;
      case EFilterResponseStatusIncidents.READ_UNANSWERED:
        src = '/images/ic-read-unanwered.png';
        break;
      default:
        src = '/images/ic-unread.png';
        break;
    }

    return <img alt="" src={src} />;
  };

  const onSendMessage = (values) => {
    if (!values) return;
    let currentMessage: any;

    if (typeof values === 'object') {
      currentMessage = values?.message;
    } else {
      currentMessage = values;
    }

    const trimMessage: string = trim(currentMessage);
    if (isClosed) {
      form.resetFields();
      setIsDisableInputChat(true);
      return notification.error({
        message: t(`incidents.detail.listDetail.header.text.closed`),
      });
    }

    if (trimMessage.length) {
      sendMessage(id, trimMessage);
    }
  };

  const handleReadMessage = () => {
    if (isMinimize || chats.length === 0) return;
    const payload: IPayloadReadChatIncidents = {
      values: {
        incident_id: id,
      },
    };
    const lastItemChat: any = chats[0];
    if (
      (!lastItemChat?.is_admin || // anonymous chat
        (lastItemChat?.is_admin && !lastItemChat?.sender_id)) && // autoresponse
      statusChats !== EFilterResponseStatusIncidents.READ_UNANSWERED
    ) {
      changeChatStatus(EFilterResponseStatusIncidents.READ_UNANSWERED);
      return dispatch(readChatIncidentsAction(payload));
    }
  };

  const submitMessage = () => form.submit();
  const pickerEmoji = <Picker onEmojiClick={onEmojiClick} />;

  if (!isVisible) return null;
  return (
    <Drag
      onMinimizeChat={onMinimizeChat}
      onClickClose={onClickClose}
      onClick={() => {
        if (isMinimize) {
          setIsMinimize(false);
        }
      }}
      header={
        <Space size={10}>
          {renderIconChat()}
          <Typography.Text className="title">{`${t('incidents.detail.chat')} (${
            chats.length
          })`}</Typography.Text>
        </Space>
      }
      isMinimize={isMinimize}
    >
      {!isMinimize && (
        <React.Fragment>
          <div className="chat-content" id="scrollableDiv" onClick={handleReadMessage}>
            <InfiniteScroll
              dataLength={chats.length}
              next={fetchMoreData}
              hasMore={false}
              loader={<Spin />}
              height={445}
              scrollableTarget="scrollableDiv"
              inverse
              className="inifinite-scroll"
            >
              {chats.map((item: any) => {
                const isOwner: boolean = item?.is_admin || item?.sender_id?._id === profile?._id;

                return (
                  <Comment
                    key={item?._id}
                    content={
                      <Space>
                        <Space size={0} direction="vertical" className="space-content-bubble">
                          <div className="container_text">
                            <Typography.Text className="txt-message">
                              <div>
                                {item?.files
                                  ? t('incidents.detail.chat.attachment', {
                                      number: item?.files,
                                    })
                                  : ''}
                              </div>
                              <div>{item?.text ? item?.text : ''}</div>
                            </Typography.Text>
                          </div>

                          <Space
                            split="·"
                            size={4}
                            className={
                              isOwner ? 'space-info-bubble ' : 'space-info-bubble anonymouse'
                            }
                          >
                            {isOwner && (
                              <Tooltip
                                className="message-name"
                                placement="topLeft"
                                title={
                                  item?.sender_id
                                    ? `${item?.sender_id?.last_name || ''} ${
                                        item?.sender_id?.first_name || ''
                                      }`
                                    : t('title.chat.automatedResponse')
                                }
                              >
                                <Typography.Text ellipsis>
                                  {item?.sender_id
                                    ? `${item?.sender_id?.last_name || ''} ${
                                        item?.sender_id?.first_name || ''
                                      }`
                                    : t('title.chat.automatedResponse')}
                                </Typography.Text>
                              </Tooltip>
                            )}
                            <Typography.Text>
                              {moment(item?.createdAt).format('YYYY/MM/DD HH:mm')}
                            </Typography.Text>
                            {isOwner && (
                              <Typography.Text>
                                {t(
                                  `incidents.detail.chat.${item?.anonymous_read ? 'read' : 'sent'}`
                                )}
                              </Typography.Text>
                            )}
                          </Space>
                        </Space>
                        {isOwner && (
                          <img
                            alt=""
                            src={`/images/ic-check-${item?.anonymous_read ? 'read' : 'sent'}.png`}
                          />
                        )}
                      </Space>
                    }
                    className={`bubble-chat ${isOwner && 'owner'}`}
                  />
                );
              })}
            </InfiniteScroll>
          </div>

          <Form
            form={form}
            initialValues={{ message: '' }}
            onFinish={onSendMessage}
            onClick={handleReadMessage}
            className={''}
          >
            {!isDisable &&
              currentRole !== 'RO' &&
              !isDisableInputChat &&
              data?.active_user_id?.is_active === true &&
              data?.active_user_id?.is_logged_out === false && (
                <Space className={'space-input-message '}>
                  <Popover
                    content={pickerEmoji}
                    trigger="click"
                    zIndex={99}
                    destroyTooltipOnHide={false}
                  >
                    <img alt="" src="/images/ic-emoji.png" className="ic-emoji" />
                  </Popover>
                  <Form.Item name="message" noStyle>
                    <Input.TextArea
                      className="input-message"
                      placeholder={t('incidents.detail.chat.writeMsg')}
                      ref={inputRef}
                      maxLength={12000}
                      autoSize={{ minRows: 3, maxRows: 3 }}
                      disabled={
                        isDisableInputChat ||
                        data?.active_user_id?.is_active === false ||
                        data?.active_user_id?.is_logged_out === true
                      }
                    />
                  </Form.Item>
                  <img
                    alt=""
                    src="/images/ic-send.png"
                    className="ic-send"
                    onClick={submitMessage}
                  />
                </Space>
              )}
          </Form>
        </React.Fragment>
      )}
      <ModalConfirm
        visible={isVisibleClose}
        handleOk={handleConfirmClose}
        handleCancel={handleCancelClose}
        title={t('incidents.detail.chat.close')}
        textConfirmBtn={t('incidents.detail.chat.closeConfirm.btn')}
        textConfirm={t('incidents.detail.chat.closeConfirm')}
      />
    </Drag>
  );
};

export default Chat;

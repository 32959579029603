/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import { useEffect, useState, memo } from 'react';
import { ESecurity } from 'api/security';
import { Space, Spin, Switch, Typography } from 'antd';
import ListIP from './components/List';
import { ModalConfirm } from 'components/modal-confirm';
import { useAppDispatch, useMyOrganizationID } from 'app/hooks';
import {
  DataChangeStatus,
  IPayloadActionUpdateStatus,
  IPayloadGetListIP,
  IQueryGetListSecurity,
  ListSecuritySetting,
} from 'pages/setting/interfaces/security';
import { statusAction, getSecuritySetingByIdAction } from 'actions/security';
import { showSuccess } from 'components/standby-notice';
import './security.scss';
const { Text, Title } = Typography;

const Security = () => {
  const { t } = useTranslation();

  const listTextSecurity = [
    {
      titleEnable: t('settingPage.security.titleEnable.Email'),
      label: t('settingPage.security.label.Email'),
      titleDisable: t('settingPage.security.titleDisable.Email'),
      text: t('settingPage.security.text.Email'),
      type: ESecurity.AUTH_EMAIL,
      borderBottom: '1px solid #EBEBEC',
      padding: '32px 0',
    },
    {
      titleEnable: t('settingPage.security.titleEnable.Login'),
      label: t('settingPage.security.label.Login'),
      titleDisable: t('settingPage.security.titleDisable.Login'),
      text: t('settingPage.security.text.Login'),
      type: ESecurity.LOGIN_LOCKOUT,
      borderBottom: '1px solid #EBEBEC',
      padding: '32px 0',
    },
    {
      titleEnable: t('settingPage.security.titleEnable.IP'),
      label: t('settingPage.security.label.IP'),
      titleDisable: t('settingPage.security.titleDisable.IP'),
      text: t('settingPage.security.text.IP'),
      type: ESecurity.IP_LIMITER,
      borderBottom: '',
      padding: '32px 0 0 0',
    },
  ];

  const dispatch = useAppDispatch();
  const myOrgId: string = useMyOrganizationID();

  const [loading, setLoading] = useState<boolean>(false);
  const [dataCheck, setDataCheck] = useState<DataChangeStatus>({
    organization_id: '',
    status: false,
    type: '',
  });

  const [isModalConfirm, setIsModalConfirm] = useState<boolean>(false);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [current, setCurrent] = useState<IQueryGetListSecurity>();
  const [data, setData] = useState<ListSecuritySetting>();

  const getListStatus = () => {
    setLoading(true);
    const payload: IPayloadGetListIP = {
      query: {
        organization_id: myOrgId,
      },
      onSuccess: (data) => {
        setData(data);
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },
    };

    return dispatch(getSecuritySetingByIdAction(payload));
  };

  useEffect(() => {
    getListStatus();
    setDataCheck({ ...dataCheck, organization_id: myOrgId });
  }, [myOrgId]);

  const handleClick = (ele: any) => {
    const newCurrent = {
      titleEnable: ele.titleEnable,
      titleDisable: ele.titleDisable,
      type: ele.type,
    };
    setCurrent(newCurrent);
    setLogicSwitch(ele);
  };

  const setLogicSwitch = (ele: any) => {
    if (ele.type === ESecurity.AUTH_EMAIL) {
      if (data?.is_authen_email) {
        setIsModalConfirm(true);
        setIsDeleted(true);
        setDataCheck({ ...dataCheck, type: ele.type, status: false });
      } else {
        setIsModalConfirm(true);
        setIsDeleted(false);
        setDataCheck({ ...dataCheck, type: ele.type, status: true });
      }
    } else if (ele.type === ESecurity.LOGIN_LOCKOUT) {
      if (data?.is_authen_login_lockout) {
        setIsModalConfirm(true);
        setIsDeleted(true);
        setDataCheck({ ...dataCheck, type: ele.type, status: false });
      } else {
        setIsModalConfirm(true);
        setIsDeleted(false);
        setDataCheck({ ...dataCheck, type: ele.type, status: true });
      }
    } else {
      if (data?.is_limit_ip) {
        setIsModalConfirm(true);
        setIsDeleted(true);
        setDataCheck({ ...dataCheck, type: ele.type, status: false });
      } else {
        setIsModalConfirm(true);
        setIsDeleted(false);
        setDataCheck({ ...dataCheck, type: ele.type, status: true });
      }
    }
  };
  const handleCancel = () => {
    setIsModalConfirm(false);
  };
  const enableStatus = () => {
    setLoading(true);
    setIsModalConfirm(false);
    const payload: IPayloadActionUpdateStatus = {
      data: dataCheck,
      onSuccess: () => {
        setLoading(false);
        showSuccess(t(`settingPage.security.notice.disable.success`, { type: t(dataCheck.type) }));
        setTimeout(() => {
          getListStatus();
        }, 0);
      },
      onError: () => {
        setLoading(false);
      },
    };

    return dispatch(statusAction(payload));
  };
  const disableStatus = () => {
    setLoading(true);
    setIsModalConfirm(false);
    const payload: IPayloadActionUpdateStatus = {
      data: dataCheck,
      onSuccess: () => {
        setLoading(false);
        showSuccess(t(`settingPage.security.notice.enable.success`, { type: t(dataCheck.type) }));
        setTimeout(() => {
          getListStatus();
        }, 0);
      },
      onError: () => {
        setLoading(false);
      },
    };

    return dispatch(statusAction(payload));
  };
  return (
    <Spin spinning={loading} size="small">
      <div className="security">
        {listTextSecurity.map((ele, index) => {
          const backgroundEmail = data && data.is_authen_email ? '#34C759' : '#FF3B30';
          const backgroundLogin = data && data.is_authen_login_lockout ? '#34C759' : '#FF3B30';
          const backgroundIp = data && data.is_limit_ip ? '#34C759' : '#FF3B30';
          return (
            <div key={index}>
              <div
                className="type"
                style={{
                  borderBottom: ele.borderBottom,
                  padding: ele.padding,
                }}
              >
                <Switch
                  style={
                    ele.type === ESecurity.AUTH_EMAIL
                      ? { background: backgroundEmail }
                      : ele.type === ESecurity.LOGIN_LOCKOUT
                      ? { background: backgroundLogin }
                      : { background: backgroundIp }
                  }
                  checked={
                    data && ele.type === ESecurity.AUTH_EMAIL
                      ? data.is_authen_email
                      : data && ele.type === ESecurity.LOGIN_LOCKOUT
                      ? data.is_authen_login_lockout
                      : data?.is_limit_ip
                  }
                  onClick={() => handleClick(ele)}
                />
                <Space size={24} direction="vertical">
                  <Title className="title">{ele.label}</Title>
                  <Text className="text break-line-localize">{ele.text}</Text>
                </Space>
              </div>
            </div>
          );
        })}
        {data && data.is_limit_ip && <ListIP name={dataCheck.type} selectedOrgId={myOrgId} />}
      </div>
      <ModalConfirm
        isDeleted={isDeleted}
        visible={isModalConfirm}
        handleOk={isDeleted ? enableStatus : disableStatus}
        handleCancel={handleCancel}
        title={isDeleted ? current?.titleDisable : current?.titleEnable}
        contentText={
          isDeleted
            ? t('settingPage.security.disable_email.body')
            : t('settingPage.security.enable_email.body')
        }
        textConfirm={current?.type === ESecurity.AUTH_EMAIL ? '' : ''}
        textConfirmBtn={
          isDeleted
            ? t('settingPage.security.disable_email.ok')
            : t('settingPage.security.enable_email.ok')
        }
      />
    </Spin>
  );
};

export default memo(Security);

import {
  CHANGE_VISIBILITY,
  CHANGE_VISIBILITY_FAILED,
  CHANGE_VISIBILITY_SUCCESS,
  CREATE_QUESTION,
  CREATE_QUESTION_FAILED,
  CREATE_QUESTION_SUCCESS,
  DELETE_QUESTION,
  DELETE_QUESTION_FAILED,
  DELETE_QUESTION_SUCCESS,
  DRAG_QUESTION,
  DRAG_QUESTION_FAILED,
  DRAG_QUESTION_SUCCESS,
  EDIT_QUESTION,
  EDIT_QUESTION_FAILED,
  EDIT_QUESTION_SUCCESS,
  LOADING_INCIDENT_QS,
  LOADING_INCIDENT_QS_FAILED,
  LOADING_INCIDENT_QS_SUCCESS,
} from 'actions/incident-question';
import { notification } from 'antd';
import {
  getListIncidentQuestion,
  postDragIncidentQuestion,
  postCreateIncidentQuestion,
  postEditIncidentQuestion,
  postDeleteIncidentQuestion,
  putVisibilityIncidentQuestion,
} from 'api/incident-question';
import i18n from 'config/i18n';
import {
  IActionChangeVisibilityIncidentQuestion,
  IActionCreateIncidentQuestion,
  IActionDeleteIncidentQuestion,
  IActionDragIncidentQuestion,
  IActionEditIncidentQuestion,
  IActionLoadingIncidentQs,
} from 'pages/setting/interfaces';
import { call, put, takeLatest } from 'redux-saga/effects';
import { OK, QUESTION_NOT_EDIT } from 'utils';

function* loadingQs({ payload }: IActionLoadingIncidentQs) {
  try {
    const get = yield call(getListIncidentQuestion, payload.query);

    if (get?.status === OK) {
      const { data } = get;
      if (data?.org_id === payload.query?.organization_id) {
        yield put({
          type: LOADING_INCIDENT_QS_SUCCESS,
          payload: Array.isArray(data?.values) ? data.values : [],
        });
      } else {
        yield put({ type: LOADING_INCIDENT_QS_FAILED });
      }
    } else {
      yield put({ type: LOADING_INCIDENT_QS_FAILED });
      if (payload?.onError) yield payload.onError(get?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: LOADING_INCIDENT_QS_FAILED });
    if (payload?.onError) yield payload.onError('');
  }
}

function* dragQs({ payload }: IActionDragIncidentQuestion) {
  try {
    const drag = yield call(postDragIncidentQuestion, payload.values);

    if (drag?.status === OK) {
      yield put({ type: DRAG_QUESTION_SUCCESS });
      if (payload?.onSuccess) yield payload.onSuccess();
    } else {
      yield put({ type: DRAG_QUESTION_FAILED });
      if (payload?.onError) yield payload.onError(drag?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: DRAG_QUESTION_FAILED });
    if (payload?.onError) yield payload.onError('');
  }
}

function* createQs({ payload }: IActionCreateIncidentQuestion) {
  try {
    const create = yield call(postCreateIncidentQuestion, payload.values);

    if (create?.status === OK) {
      yield put({ type: CREATE_QUESTION_SUCCESS });
      if (payload?.onSuccess) yield payload.onSuccess();
    } else {
      yield put({ type: CREATE_QUESTION_FAILED });
      if (payload?.onError) yield payload.onError(create?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: CREATE_QUESTION_FAILED });
    if (payload?.onError) yield payload.onError('');
  }
}

function* editQs({ payload }: IActionEditIncidentQuestion) {
  try {
    const edit = yield call(postEditIncidentQuestion, payload.values);

    if (edit?.status === OK) {
      yield put({ type: EDIT_QUESTION_SUCCESS });
      if (payload?.onSuccess) yield payload.onSuccess();
    } else {
      yield put({ type: EDIT_QUESTION_FAILED });
      if (payload?.onError) yield payload.onError(edit?.error_code);

      let message: string = i18n.t('error.common');

      switch (edit?.error_code) {
        case QUESTION_NOT_EDIT:
          message = i18n.t('settingPage.incidentQuestion.notEdit');
          break;
        default:
          break;
      }

      yield notification.error({ message });
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: EDIT_QUESTION_FAILED });
    if (payload?.onError) yield payload.onError('');
  }
}

function* deleteQs({ payload }: IActionDeleteIncidentQuestion) {
  try {
    const del = yield call(postDeleteIncidentQuestion, payload.values);

    if (del?.status === OK) {
      yield put({ type: DELETE_QUESTION_SUCCESS });
      if (payload?.onSuccess) yield payload.onSuccess();
    } else {
      yield put({ type: DELETE_QUESTION_FAILED });
      if (payload?.onError) yield payload.onError(del?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: DELETE_QUESTION_FAILED });
    if (payload?.onError) yield payload.onError('');
  }
}

function* visibilityQs({ payload }: IActionChangeVisibilityIncidentQuestion) {
  try {
    const change = yield call(putVisibilityIncidentQuestion, payload.values);

    if (change?.status === OK) {
      yield put({ type: CHANGE_VISIBILITY_SUCCESS, payload: payload.values });
      if (payload?.onSuccess) yield payload.onSuccess();
    } else {
      yield put({ type: CHANGE_VISIBILITY_FAILED });
      if (payload?.onError) yield payload.onError(change?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: CHANGE_VISIBILITY_FAILED });
    if (payload?.onError) yield payload.onError('');
  }
}

export default function* incidentQuestionSaga() {
  yield takeLatest(LOADING_INCIDENT_QS, loadingQs);
  yield takeLatest(DRAG_QUESTION, dragQs);
  yield takeLatest(CREATE_QUESTION, createQs);
  yield takeLatest(EDIT_QUESTION, editQs);
  yield takeLatest(DELETE_QUESTION, deleteQs);
  yield takeLatest(CHANGE_VISIBILITY, visibilityQs);
}

import { InfoCircleFilled } from '@ant-design/icons';
import { checkAccessCodeOrganizationAction } from 'actions/organization';
import {
  Button,
  Cascader,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Radio,
  Row,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  EActionAccessCode,
  EBrandingType,
  ETypeOrganization,
  IFormCreateACOGProps,
  IPayloadCheckAccessCode,
} from 'pages/setting/interfaces';
import { memo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ACCESS_CODE_ALREADY_EXIST, ACCESS_CODE_EXIST, validateAccessCodeRegex } from 'utils';
const maxLengthAccessCode = 20;

const FormCreate = ({
  formRef,
  onFinish,
  isVisible,
  onCancel,
  selectedItem,
  optionsCascaderOG,
}: IFormCreateACOGProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const isLoading: boolean = useAppSelector((state) => state.organizations.isLoading);

  const [isLoadingCheckAvailableCode, setIsLoadingCheckAvailableCode] = useState<boolean>(false);
  const [isReadOnlyBranding, setIsReadOnlyBranding] = useState<boolean>(false);

  const isCreateAtSchool: boolean = !!selectedItem?.parent_id;

  useEffect(() => {
    if (selectedItem?.key && isVisible) {
      form.resetFields();

      const [organization, school] = selectedItem.key.split('-');
      const newPostion = [organization];

      if (school) {
        newPostion.push(`${organization}-${school}`);
      }

      form.setFieldsValue({
        position: newPostion,
        home_message: selectedItem?.root?.home_message,
        incident_message: selectedItem?.root?.incident_message,
        working_time: selectedItem?.root?.working_time,
      });

      const checkReadOnly: boolean = selectedItem?.root?.branding_type === EBrandingType.READ_ONLY;

      setIsReadOnlyBranding(checkReadOnly);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, isVisible]);

  const onErrorAccessCode = (code: string) => {
    let message: string = t('error.common');

    switch (code) {
      case ACCESS_CODE_ALREADY_EXIST:
      case ACCESS_CODE_EXIST:
        message = t('settingPage.ogSetup.accessCodeExist');
        break;

      default:
        break;
    }

    return notification.error({ message });
  };

  const handleCheckAvailable = (index: number, isChecked = true, cb: () => void) => {
    setIsLoadingCheckAvailableCode(true);
    const getListAccess_code = form.getFieldValue('access_codes');
    const fields = form.getFieldsValue();
    console.log('getListAccess_code', getListAccess_code[getListAccess_code.length - 1]);

    const newAccessCodes = [...fields?.access_codes];
    const accessCodeItem = newAccessCodes[index];

    const onError = (errorCode: string) => {
      onErrorAccessCode(errorCode);
      setIsLoadingCheckAvailableCode(false);
    };

    const onSuccess = (data?: any) => {
      if (data) {
        if (!data?.available) {
          return onError(ACCESS_CODE_ALREADY_EXIST);
        }
      }

      newAccessCodes.splice(index, 1, { ...accessCodeItem, isChecked });
      const filterCodes: any[] = newAccessCodes.filter((item: any) => !!item?.isChecked);

      if (!isChecked) {
        filterCodes.push({
          access_code: getListAccess_code[getListAccess_code.length - 1]?.access_code,
        });
      }

      form.setFieldsValue({ access_codes: filterCodes });

      setIsLoadingCheckAvailableCode(false);

      if (isChecked) {
        cb();
      }
    };

    if (!isChecked) {
      return onSuccess();
    }

    const payload: IPayloadCheckAccessCode = {
      values: {
        access_code: accessCodeItem?.access_code,
        action: EActionAccessCode.VALIDATE,
        organization_id: '',
      },
      onSuccess,
      onError,
    };

    return dispatch(checkAccessCodeOrganizationAction(payload));
  };

  return (
    <Modal
      maskClosable={false}
      title={t('settingPage.ogSetup.createOg')}
      centered
      visible={isVisible}
      onCancel={onCancel}
      footer={[
        <Button
          key="create"
          type="primary"
          className="btn-ok"
          size="large"
          onClick={() => formRef.current?.submit()}
          loading={isLoading}
        >
          {t('settingPage.ogSetup.createBtn')}
        </Button>,
        <Button key="close" onClick={onCancel} className="btn-close" size="large">
          {t('common.close')}
        </Button>,
      ]}
      className="md-og md-og-lg"
    >
      <Form
        layout="vertical"
        ref={formRef}
        onFinish={onFinish}
        requiredMark={false}
        initialValues={{
          type: ETypeOrganization.SINGLE,
          home_message: '',
          incident_message: '',
          working_time: '',
          access_codes: [
            // { access_code: '123', isChecked: true, isVerified: true },
            {},
          ],
        }}
        form={form}
        scrollToFirstError
      >
        <Form.Item
          name="name"
          label={t('settingPage.ogSetup.ogName')}
          rules={[
            {
              required: true,
              whitespace: true,
              message: t('settingPage.ogSetup.ogNameRequired'),
            },
          ]}
        >
          <Input
            size="large"
            placeholder={t('settingPage.ogSetup.ogNamePlaceholder')}
            className="input-og-name"
            maxLength={70}
          />
        </Form.Item>

        <Form.Item label={t('settingPage.ogSetup.positionStructure')} name="position">
          <Cascader
            options={optionsCascaderOG}
            placeholder={t('settingPage.ogSetup.positionStructure')}
            size="large"
            displayRender={(label) => label.join(' > ')}
            allowClear={false}
            className="position-structure"
            disabled
          />
        </Form.Item>

        {!isCreateAtSchool && (
          <div>
            <Space className="label-info">
              <Typography.Text className="txt">{t('settingPage.ogSetup.ogType')}</Typography.Text>
              <Tooltip title={t('settingPage.ogSetup.ogTypeDesciption')}>
                <InfoCircleFilled className="ic-info" />
              </Tooltip>
            </Space>

            <Divider className="div-label-info" />

            <Form.Item
              name="type"
              rules={[{ required: true, message: `${t('settingPage.ogSetup.ogTypeRequired')}` }]}
            >
              <Radio.Group className="radio-og-type">
                <Space direction="vertical" size={16}>
                  <Radio value={ETypeOrganization.SINGLE}>
                    {t('settingPage.ogSetup.singleLayer')}
                  </Radio>
                  <Radio value={ETypeOrganization.MULTIPLE}>
                    {t('settingPage.ogSetup.twoLayer')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </div>
        )}

        <Space className="label-info">
          <Typography.Text className="txt">{t('settingPage.ogSetup.accessCode')}</Typography.Text>
          <Tooltip title={t('settingPage.ogSetup.accessCodeDesciption')}>
            <InfoCircleFilled className="ic-info" />
          </Tooltip>
        </Space>

        <Divider className="div-label-info" />

        <div className="wrap-access-codes">
          <Form.List name="access_codes">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => {
                  return (
                    <Row gutter={[16, 16]} key={key}>
                      <Col span={12}>
                        <Form.Item
                          noStyle
                          shouldUpdate={(p, n) => {
                            const prevState: any = p?.access_codes?.[name];
                            const nextState: any = n?.access_codes?.[name];

                            const prevChecked: boolean = !!prevState?.isChecked;
                            const nextChecked: boolean = !!nextState?.isChecked;

                            const prevDeactived: boolean = !!prevState?.isDeactived;
                            const nextDeactived: boolean = !!nextState?.isDeactived;

                            const shouldUpdate: boolean =
                              prevChecked !== nextChecked || prevDeactived !== nextDeactived;

                            return shouldUpdate;
                          }}
                        >
                          {({ getFieldValue }) => {
                            const isChecked: boolean =
                              !!getFieldValue('access_codes')?.[name]?.isChecked;
                            const isDeactived: boolean =
                              !!getFieldValue('access_codes')?.[name]?.isDeactived;
                            const isEnhance: boolean = !(isChecked && fields.length > name + 1);

                            const hasFeedback = (): boolean => {
                              if (fields.length > name + 1) return isEnhance;

                              if (isChecked) return isChecked;

                              return false;
                            };

                            const className = (): string => {
                              if (isEnhance) {
                                if (isChecked) return 'input-code-checked';

                                return '';
                              }

                              if (isDeactived) {
                                return 'input-code-checked-deactive';
                              }

                              return 'input-code-checked-disabled';
                            };

                            const disabled = (): boolean => {
                              if (isDeactived) return true;

                              return !isEnhance || isChecked;
                            };

                            const suffix = () => {
                              if (isDeactived) {
                                return (
                                  <Typography.Text className="txt-deactivated">
                                    {t('settingPage.ogSetup.deactivated')}
                                  </Typography.Text>
                                );
                              }

                              return null;
                            };

                            return (
                              <Form.Item
                                {...restField}
                                name={[name, 'access_code']}
                                fieldKey={[fieldKey, 'access_code']}
                                label={t(
                                  `settingPage.ogSetup.${
                                    isEnhance ? 'createAccessCode' : 'accessCode'
                                  }`
                                )}
                                hasFeedback={hasFeedback()}
                                rules={[
                                  {
                                    pattern: new RegExp(validateAccessCodeRegex),
                                    message: t('settingPage.ogSetup.accessCodeFormat'),
                                  },
                                  () => ({
                                    validator(_rule, value) {
                                      const allAccessCodes: any[] = getFieldValue('access_codes');

                                      const indexCode: number = allAccessCodes.findIndex(
                                        (item: any) => item?.access_code === value
                                      );

                                      const checkExist: boolean = allAccessCodes.some(
                                        (item: any) =>
                                          item?.access_code === value &&
                                          item?.isChecked &&
                                          name !== indexCode
                                      );

                                      if (checkExist) {
                                        return Promise.reject(
                                          new Error(t('settingPage.ogSetup.accessCodeExist'))
                                        );
                                      }

                                      return Promise.resolve();
                                    },
                                  }),
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder={t('settingPage.ogSetup.accessCodePlaceholder')}
                                  className={`input-access-code ${className()}`}
                                  disabled={disabled()}
                                  suffix={suffix()}
                                  maxLength={maxLengthAccessCode}
                                />
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'isChecked']}
                          fieldKey={[fieldKey, 'isChecked']}
                          noStyle
                        >
                          <Input type="hidden" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'isVerified']}
                          fieldKey={[fieldKey, 'isVerified']}
                          noStyle
                        >
                          <Input type="hidden" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'isDeactived']}
                          fieldKey={[fieldKey, 'isDeactived']}
                          noStyle
                        >
                          <Input type="hidden" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item shouldUpdate noStyle>
                          {({ getFieldValue }) => {
                            const isChecked: boolean =
                              !!getFieldValue('access_codes')?.[name]?.isChecked;

                            const isDeactived: boolean =
                              !!getFieldValue('access_codes')?.[name]?.isDeactived;

                            const accessCode: string =
                              getFieldValue('access_codes')?.[name]?.access_code;

                            const allAccessCodes: any[] = getFieldValue('access_codes');

                            const checkExist: boolean = allAccessCodes.some(
                              (item: any) => item?.access_code === accessCode && item?.isChecked
                            );

                            const isValidCode: boolean = validateAccessCodeRegex.test(accessCode);

                            const isDisabledAvailable: boolean =
                              isValidCode && accessCode?.length >= 8;

                            if (isDeactived) return null;

                            if (isChecked) {
                              return (
                                <Form.Item label=" ">
                                  <Space size={12}>
                                    <Button
                                      size="large"
                                      onClick={() => {
                                        handleCheckAvailable(name, false, () => remove(name + 1));
                                      }}
                                      className="btn-cancel"
                                    >
                                      {t('common.cancelBtn')}
                                    </Button>
                                  </Space>
                                </Form.Item>
                              );
                            }

                            return (
                              <Form.Item label=" ">
                                <Button
                                  type="primary"
                                  size="large"
                                  onClick={() => handleCheckAvailable(name, true, add)}
                                  disabled={checkExist || !isDisabledAvailable}
                                  className="btn-check-availability"
                                  loading={isLoadingCheckAvailableCode}
                                >
                                  {t('settingPage.ogSetup.checkAvailability')}
                                </Button>
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                      </Col>
                    </Row>
                  );
                })}
              </>
            )}
          </Form.List>
        </div>

        <Space className="label-info">
          <Typography.Text className="txt">{t('settingPage.ogSetup.branding')}</Typography.Text>
          <Tooltip title={t('settingPage.ogSetup.brandingDesciption')}>
            <InfoCircleFilled className="ic-info" />
          </Tooltip>
        </Space>

        <Divider className="div-label-info" />

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              name="home_message"
              label={t('settingPage.ogSetup.homeScreenAppMesseger')}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: t('settingPage.ogSetup.homeScreenAppMessegerRequired'),
                },
              ]}
            >
              <Input.TextArea
                size="large"
                placeholder={t('settingPage.ogSetup.homeScreenAppMessegerPlaceholder')}
                autoSize={{ minRows: 3, maxRows: 5 }}
                className="input-home-message"
                maxLength={70}
                disabled={isReadOnlyBranding}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="incident_message"
              label={t('settingPage.ogSetup.disclaimer')}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: t('settingPage.ogSetup.disclaimerRequired'),
                },
              ]}
            >
              <Input.TextArea
                size="large"
                placeholder={t('settingPage.ogSetup.disclaimerPlaceholder')}
                autoSize={{ minRows: 3, maxRows: 5 }}
                className="input-incident-message"
                maxLength={70}
                disabled={isReadOnlyBranding}
              />
            </Form.Item>
          </Col>
        </Row>

        <Space className="label-info">
          <Typography.Text className="txt">{t('settingPage.ogSetup.businessHour')}</Typography.Text>
          <Tooltip title={t('settingPage.ogSetup.businessHourDescription')}>
            <InfoCircleFilled className="ic-info" />
          </Tooltip>
        </Space>

        <Divider className="div-label-info" />

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name="working_time" label={t('settingPage.ogSetup.businessHour')}>
              <Input
                size="large"
                placeholder={t('settingPage.ogSetup.businessHourDescription')}
                className="input-working-time"
                maxLength={30}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default memo(FormCreate);

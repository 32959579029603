/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Space, Table, Tooltip, Typography } from 'antd';
import { InfoCircleFilled, MenuOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useRef } from 'react';
import {
  SortableContainer as SortableContainerC,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import ModalFormCreate from './ModalFormCreate';
import './sos-setup.scss';
import { getListSOSByOrg, orderSOSAction } from 'actions/sos';
import { useAppDispatch, useAppSelector, useMyOrganizationID } from 'app/hooks';
import { IPayloadGetSOSListByOrg, IPayloadOrderSOS } from 'pages/setting/interfaces';
import ModalFormEdit from './ModalFormEdit';
import { arrayMoveImmutable } from 'array-move';
import { useTranslation } from 'react-i18next';
import { showError, showSuccess } from 'components/standby-notice';
import ConfirmDeleteModal from 'components/common/ConfirmDeleteModal';
import { deleteSOSAction } from 'actions/sos';
import { IPayloadDeleteSOS } from 'pages/setting/interfaces';
import { ModalConfirm } from 'components/modal-confirm';

const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: 'grab', color: '#000000' }} />
));

const SortableItem = SortableElement((props: any) => <tr {...props} />);
const SortableContainer = SortableContainerC((props: any) => <tbody {...props} />);

const SOSSetup = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [showModalCreate, setShowModalCreate] = React.useState<boolean>(false);
  const [showModalDelete, setShowModalDelete] = React.useState<boolean>(false);
  const [showModalEdit, setShowModalEdit] = React.useState<boolean>(false);

  const [record, setRecord] = React.useState<any>(null);

  const listSOS: any[] = useAppSelector((state) => state.sos.listSOS);
  const isLoading: boolean = useAppSelector((state) => state.sos.isLoading);
  const createRef: any = useRef();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [payloadOrder, setPayloadOrder] = React.useState<any>({});
  const myOrg = useAppSelector((state) => state.users.selectedOrg);

  const myOrgId: string = useMyOrganizationID();
  const loadingListSOS = React.useCallback(() => {
    const payload: IPayloadGetSOSListByOrg = {
      query: {
        organization_id: myOrgId,
      },
      onSuccess: () => {},
      onError: (errorCode: string) => {
        console.log('🚀 ~ errorCode', errorCode);
      },
    };
    return dispatch(getListSOSByOrg(payload));
  }, [myOrgId]);

  React.useEffect(() => {
    if (myOrgId) {
      loadingListSOS();
    }
  }, [myOrgId]);

  const onDelete = () => {
    if (record._id) {
      const payload: IPayloadDeleteSOS = {
        query: {
          sosId: record._id,
        },
        onSuccess: () => {
          loadingListSOS();
          setShowModalDelete(false);
          setRecord(null);
          showSuccess(t('settingPage.sosSetUp.delete.message.success'));
        },
        onError: (errorCode: string) => {
          setShowModalDelete(false);
          console.log('🚀 ~ errorCode', errorCode);
        },
      };

      return dispatch(deleteSOSAction(payload));
    }
  };

  const columns: any = [
    {
      width: 48,
      align: 'center',
      className: 'drag-visible',
      render: () => (
        <Tooltip placement="topLeft" title={t('setting.tooltip.dragIcon')}>
          <div>
            <DragHandle />
          </div>
        </Tooltip>
      ),
    },
    {
      title: () => (
        <Space>
          <Typography.Text>{t(`settingPage.sosSetUp.table.name`)}</Typography.Text>
          <Tooltip title={t(`settingPage.sosSetUp.table.name.description`)}>
            <InfoCircleFilled className="ic-info" />
          </Tooltip>
        </Space>
      ),
      dataIndex: 'title',
      width: 430,
      ellipsis: {
        showTitle: false,
      },
      render: (title: string) => {
        return (
          <Tooltip placement="bottomLeft" title={title}>
            <Typography.Text>{title}</Typography.Text>
          </Tooltip>
        );
      },
    },
    {
      title: t('settingPage.sosSetUp.table.type'),
      dataIndex: 'type',
      width: 230,
      render: (type: string) => {
        return <Typography.Text>{t(`settingPage.sosSetUp.type.${type}`)}</Typography.Text>;
      },
    },
    {
      title: t('settingPage.sosSetUp.table.value'),
      dataIndex: 'value',
      width: 230,
      ellipsis: {
        showTitle: false,
      },
      render: (value: string) => {
        return (
          <Tooltip placement="bottomLeft" title={value}>
            <Typography.Text>{value}</Typography.Text>
          </Tooltip>
        );
      },
    },
    {
      title: () => (
        <Button
          className="btn-add-sos"
          type="primary"
          onClick={() => setShowModalCreate(true)}
          icon={<PlusOutlined />}
        >
          {t('settingPage.sosSetUp.addBtn')}
        </Button>
      ),
      dataIndex: 'Action',
      align: 'right',
      className: 'drag-hidden',
      render: (_: any, record: any) => {
        const createdBySelectedOrg = myOrgId === record.org_id;
        if (!createdBySelectedOrg) return null;
        return (
          <Space size={15}>
            <Tooltip placement="topLeft" title={t('setting.tooltip.editIcon')}>
              <img
                alt=""
                src="/images/ic-edit.png"
                className="ic-edit"
                onClick={() => {
                  setRecord(record);
                  setShowModalEdit(true);
                }}
              />
            </Tooltip>

            <Tooltip placement="topLeft" title={t('setting.tooltip.deleteIcon')}>
              <img
                alt=""
                src="/images/ic-delete.png"
                className="ic-delete"
                onClick={() => {
                  setRecord(record);
                  setShowModalDelete(true);
                }}
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable([].concat(...listSOS), oldIndex, newIndex).filter(
        (el) => !!el
      );

      const ids: string[] = newData.map((item: any) => item?._id).filter((item: any) => !!item);

      const payload: IPayloadOrderSOS = {
        org_id: myOrgId,
        values: ids,
        onSuccess: () => {
          showSuccess(t('settingPage.sosSetUp.order.message.success'));
        },
        onError: (errorCode: string) => {
          console.log('🚀 ~ errorCode', errorCode);
          showError(t('settingPage.sosSetUp.order.message.error'));
        },
      };
      setPayloadOrder(payload);
      if (!myOrg.childrens || !myOrg.childrens.length) {
        dispatch(orderSOSAction(payload));
        return;
      }
      setIsModalOpen(true);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleConfirm = () => {
    dispatch(orderSOSAction(payloadOrder));
    setIsModalOpen(false);
  };

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging sos-item-drag"
      onSortEnd={onSortEnd}
      // shouldCancelStart={() => true}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const dataSource = listSOS;
    const index = dataSource.findIndex((x) => x._id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <div className="sos-setup">
      <Table
        loading={isLoading}
        pagination={false}
        dataSource={listSOS}
        columns={columns}
        rowKey="_id"
        components={
          listSOS.length
            ? {
                body: {
                  wrapper: DraggableContainer,
                  row: DraggableBodyRow,
                },
              }
            : undefined
        }
        className="table-sos"
        scroll={{ y: 'calc(100vh - 260px)' }}
        locale={{ emptyText: t('no_data', { name: t('settingPage.sosSetUp.title') }) }}
      />

      <ModalFormCreate
        visible={showModalCreate}
        onCancel={() => {
          setShowModalCreate(false);
          createRef.current!.resetFields();
        }}
        loadingListSOS={loadingListSOS}
        myOrgId={myOrgId}
        ref={createRef}
      />

      <ModalFormEdit
        visible={showModalEdit}
        onCancel={() => {
          setShowModalEdit(false);
          setRecord(undefined);
        }}
        loadingListSOS={loadingListSOS}
        record={record}
      />
      <ConfirmDeleteModal
        isVisible={showModalDelete}
        handleDelete={onDelete}
        handleCancel={() => {
          setShowModalDelete(false);
          setRecord(null);
        }}
        modalTitle={t('settingPage.sosSetUp.delete.title')}
        confirmText={t('settingPage.sosSetUp.delete.body')}
        deleteButtonTitle={t('settingPage.sosSetUp.deleteBtn')}
        isLoading={isLoading}
      />
      <ModalConfirm
        textConfirmBtn={t('settingMenu.message.saveBtn')}
        visible={isModalOpen}
        handleCancel={handleCancel}
        textConfirm={t('modal.confirmEdit.confirmText')}
        title={t('modal.confirmEdit.title')}
        handleOk={handleConfirm}
      />
    </div>
  );
};

export default React.memo(SOSSetup);

import { Button, Result } from 'antd';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

const NotFound = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Result
      status="404"
      title="404"
      subTitle={t('error.notFoundPage')}
      className="not-found-page"
      extra={
        <Button type="primary" onClick={() => history.goBack()} size="large" className="btn-back">
          {t('common.ok')}
        </Button>
      }
    />
  );
};

export default memo(NotFound);

import { Tabs } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import HoursOfOperation from './components/HoursOfOperation';
import Holiday from './components/Holiday';

const { TabPane } = Tabs;

const AutomatedResponse = () => {
  const { t } = useTranslation();

  return (
    <div className="automated-response">
      <Tabs defaultActiveKey="1" className="automated-response-tabs">
        <TabPane tab={t('settingPage.automatedResponse.hoursOfOperation')} key="1">
          <HoursOfOperation />
        </TabPane>
        <TabPane tab={t('settingPage.automatedResponse.holiday')} key="2">
          <Holiday />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default React.memo(AutomatedResponse);

import {
  IActionLoadingReportActiveTimeline,
  IActionLoadingReportActiveUsers,
  IPayloadLoadingReportActiveTimeline,
  IPayloadLoadingReportActiveUsers,
} from 'pages/reports/interfaces';

export const LOADING_ACTIVE_USER = 'LOADING_ACTIVE_USER';
export const LOADING_ACTIVE_USER_SUCCESS = 'LOADING_ACTIVE_USER_SUCCESS';
export const LOADING_ACTIVE_USER_FAILED = 'LOADING_ACTIVE_USER_FAILED';

export const LOADING_ACTIVE_TIMELINE = 'LOADING_ACTIVE_TIMELINE';
export const LOADING_ACTIVE_TIMELINE_SUCCESS = 'LOADING_ACTIVE_TIMELINE_SUCCESS';
export const LOADING_ACTIVE_TIMELINE_FAILED = 'LOADING_ACTIVE_TIMELINE_FAILED';

export const loadingActiveUsersAction = (
  payload: IPayloadLoadingReportActiveUsers
): IActionLoadingReportActiveUsers => ({
  type: LOADING_ACTIVE_USER,
  payload,
});

export const loadingActiveTimelineAction = (
  payload: IPayloadLoadingReportActiveTimeline
): IActionLoadingReportActiveTimeline => ({
  type: LOADING_ACTIVE_TIMELINE,
  payload,
});

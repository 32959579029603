import {
  LOADING_ORGANIZATION,
  LOADING_ORGANIZATION_FAILED,
  LOADING_ORGANIZATION_SUCCESS,
  CREATE_ORGANIZATION,
  CREATE_ORGANIZATION_SUCCESS,
  CREATE_ORGANIZATION_FAILED,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_FAILED,
  UPDATE_ORGANIZATION_SUCCESS,
  CHANGE_SHOW_HIDDEN_ORGANIZATION,
  SEARCH_ORGANIZATION,
  EXPAND_ORGANIZATION,
  GET_DETAIL_ORGANIZATION,
  GET_DETAIL_ORGANIZATION_SUCCESS,
  GET_DETAIL_ORGANIZATION_FAILED,
  HIDE_MULTI_ORGANIZATION,
  STRUCTURE_ORGANIZATION,
  STRUCTURE_ORGANIZATION_FAILED,
  HIDE_MULTI_ORGANIZATION_SUCCESS,
  HIDE_MULTI_ORGANIZATION_FAILED,
  STRUCTURE_ORGANIZATION_SUCCESS,
} from 'actions/organization';
import { IOrganizationSelector } from 'pages/setting/interfaces';
import { ActionProps } from 'reducers';
import { LOGOUT_SUCCESS } from 'actions/auth';

const initialState: IOrganizationSelector = {
  organizations: [],
  isLoading: false,
  isExpand: true,
  isShowHiddenOrganization: false,
  keySearch: '',
};

export const OrganizationReducer = (state = initialState, action: ActionProps) => {
  switch (action.type) {
    case CREATE_ORGANIZATION:
    case UPDATE_ORGANIZATION:
    case LOADING_ORGANIZATION:
    case GET_DETAIL_ORGANIZATION:
    case HIDE_MULTI_ORGANIZATION:
    case STRUCTURE_ORGANIZATION:
      return {
        ...state,
        isLoading: true,
      };
    case LOADING_ORGANIZATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        organizations: action.payload,
      };
    case LOADING_ORGANIZATION_FAILED:
      return {
        ...state,
        isLoading: false,
        organizations: [],
      };
    case HIDE_MULTI_ORGANIZATION_SUCCESS:
    case HIDE_MULTI_ORGANIZATION_FAILED:
    case STRUCTURE_ORGANIZATION_SUCCESS:
    case STRUCTURE_ORGANIZATION_FAILED:
    case CREATE_ORGANIZATION_FAILED:
    case CREATE_ORGANIZATION_SUCCESS:
    case UPDATE_ORGANIZATION_FAILED:
    case UPDATE_ORGANIZATION_SUCCESS:
    case GET_DETAIL_ORGANIZATION_SUCCESS:
    case GET_DETAIL_ORGANIZATION_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case CHANGE_SHOW_HIDDEN_ORGANIZATION:
      return {
        ...state,
        isShowHiddenOrganization: !!action.payload,
      };
    case SEARCH_ORGANIZATION:
      return {
        ...state,
        keySearch: action.payload,
      };
    case EXPAND_ORGANIZATION:
      return {
        ...state,
        isExpand: !!action.payload,
      };
    case LOGOUT_SUCCESS:
      return { ...initialState };
    default:
      return state;
  }
};

import {
  LOADING_ORGANIZATION,
  LOADING_ORGANIZATION_FAILED,
  LOADING_ORGANIZATION_SUCCESS,
  CREATE_ORGANIZATION,
  CREATE_ORGANIZATION_SUCCESS,
  CREATE_ORGANIZATION_FAILED,
  CHECK_ACCESS_CODE,
  GET_DETAIL_ORGANIZATION,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_FAILED,
  UPDATE_ORGANIZATION,
  MULTI_ACTION_ORGANIZATION,
  HIDE_MULTI_ORGANIZATION,
  STRUCTURE_ORGANIZATION,
  GET_DETAIL_ORGANIZATION_SUCCESS,
  GET_DETAIL_ORGANIZATION_FAILED,
  HIDE_MULTI_ORGANIZATION_SUCCESS,
  HIDE_MULTI_ORGANIZATION_FAILED,
  STRUCTURE_ORGANIZATION_SUCCESS,
  STRUCTURE_ORGANIZATION_FAILED,
} from 'actions/organization';
import { notification } from 'antd';
import {
  getDetailOrganization,
  getOrganization,
  hiddenMultiOrganization,
  multiActionOrganization,
  postCheckAccessCode,
  postCreateOrganization,
  putUpdateOrganization,
  structureOrganization,
} from 'api/organization';
import i18n from 'config/i18n';
import {
  IActionCheckAccessCodeOrganization,
  IActionCreateOrganization,
  IActionDetailOrganization,
  IActionLoadingOrganiztion,
  IActionStructureOrganization,
  IActionUpdateOrganization,
  IHideMultiOrganizationAction,
  IMultiActionOrganization,
  EActionAccessCode,
} from 'pages/setting/interfaces';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { INVALID_PARENT_ORG, OK } from 'utils';

function* loadingOG({ payload }: IActionLoadingOrganiztion) {
  try {
    const get = yield call(getOrganization, payload?.query);

    if (get?.status === OK) {
      const {
        data: { organizations },
      } = get;

      const newOrganization: any[] = Array.isArray(organizations)
        ? organizations
            .map((organization) => {
              if (organization?._id === organizations?.[0]._id) {
                /**
                 * Just pick first root
                 */
                let newSchools: any = [];

                if (organization.childrens?.length) {
                  newSchools = organization.childrens.map((school: any) => {
                    let newGrades: any = [];
                    if (school.childrens?.length) {
                      newGrades = school.childrens.map((grade: any) => {
                        return {
                          ...grade,
                          isGrade: true,
                          title: grade?.name,
                          key: `${organization?._id}-${school?._id}-${grade?._id}`,
                        };
                      });
                    }

                    return {
                      ...school,
                      title: school?.name,
                      children: newGrades,
                      key: `${organization?._id}-${school?._id}`,
                    };
                  });
                }

                return {
                  ...organization,
                  title: organization?.name,
                  children: newSchools,
                  key: organization?._id,
                  disableCheckbox: true,
                };
              }
              return null;
            })
            .filter((e) => !!e)
        : [];

      if (!payload?.ignoreDispatchReducer) {
        yield put({ type: LOADING_ORGANIZATION_SUCCESS, payload: newOrganization });
      } else {
        /**
         * Fake action to set @isLoading === @false
         */
        yield put({ type: CREATE_ORGANIZATION_SUCCESS });
      }

      if (payload?.onSuccess) yield payload.onSuccess(newOrganization);
    } else {
      yield put({ type: LOADING_ORGANIZATION_FAILED });
      if (payload?.onError) yield payload.onError(get?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: LOADING_ORGANIZATION_FAILED });
  }
}

function* createOG({ payload }: IActionCreateOrganization) {
  try {
    const create = yield call(postCreateOrganization, payload.values);
    if (create?.status === OK) {
      yield put({ type: CREATE_ORGANIZATION_SUCCESS });
      if (payload?.onSuccess) yield payload.onSuccess();
    } else {
      yield put({ type: CREATE_ORGANIZATION_FAILED });
      if (payload?.onError) yield payload.onError(create?.error_code);

      let message: string = i18n.t('error.common');

      switch (create?.error_code) {
        case INVALID_PARENT_ORG:
          message = i18n.t('settingPage.ogSetup.invalidParentOg');
          break;
        default:
          break;
      }

      yield notification.error({ message });
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: CREATE_ORGANIZATION_FAILED });
  }
}

function* updateOG({ payload }: IActionUpdateOrganization) {
  try {
    const update = yield call(putUpdateOrganization, payload.values);
    if (update?.status === OK) {
      yield put({ type: UPDATE_ORGANIZATION_SUCCESS });
      if (payload?.onSuccess) yield payload.onSuccess();
    } else {
      yield put({ type: UPDATE_ORGANIZATION_FAILED });
      if (payload?.onError) yield payload.onError(update?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: UPDATE_ORGANIZATION_FAILED });
  }
}

function* checkAccessCodeOG({ payload }: IActionCheckAccessCodeOrganization) {
  try {
    const check = yield call(postCheckAccessCode, payload.values);

    if (check?.status === OK) {
      if (payload.values.action !== EActionAccessCode.VALIDATE) {
        const { isShowHiddenOrganization, keySearch } = yield select(
          (state) => state.organizations
        );
        yield put({
          type: LOADING_ORGANIZATION,
          payload: {
            query: {
              show_hidden: isShowHiddenOrganization,
              name: keySearch,
            },
          },
        });
      }
      if (payload?.onSuccess) yield payload.onSuccess(check?.data);
    } else {
      if (payload?.onError) yield payload.onError(check?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    if (payload?.onError) yield payload.onError('');
  }
}

function* getDetailOG({ payload }: IActionDetailOrganization) {
  try {
    const get = yield call(getDetailOrganization, payload.organization_id);

    if (get?.status === OK) {
      yield put({ type: GET_DETAIL_ORGANIZATION_SUCCESS });
      if (payload?.onSuccess) yield payload.onSuccess(get?.data);
    } else {
      yield put({ type: GET_DETAIL_ORGANIZATION_FAILED });
      if (payload?.onError) yield payload.onError(get?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: GET_DETAIL_ORGANIZATION_FAILED });
    if (payload?.onError) yield payload.onError('');
  }
}

function* multiActionOG({ payload }: IMultiActionOrganization) {
  try {
    const action = yield call(multiActionOrganization, payload.values);

    if (action?.status === OK) {
      if (payload?.onSuccess) yield payload.onSuccess();
    } else {
      if (payload?.onError) yield payload.onError(action?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    if (payload?.onError) yield payload.onError('');
  }
}

function* hideMultiOG({ payload }: IHideMultiOrganizationAction) {
  try {
    const hide = yield call(hiddenMultiOrganization, payload.orgIds);

    if (hide?.status === OK) {
      yield put({ type: HIDE_MULTI_ORGANIZATION_SUCCESS });
      if (payload?.onSuccess) yield payload.onSuccess();
    } else {
      yield put({ type: HIDE_MULTI_ORGANIZATION_FAILED });
      if (payload?.onError) yield payload.onError(hide?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: HIDE_MULTI_ORGANIZATION_FAILED });
    if (payload?.onError) yield payload.onError('');
  }
}

function* structureOG({ payload }: IActionStructureOrganization) {
  try {
    const structure = yield call(structureOrganization, payload.values);

    if (structure?.status === OK) {
      yield put({ type: STRUCTURE_ORGANIZATION_SUCCESS });
      if (payload?.onSuccess) yield payload.onSuccess();
    } else {
      yield put({ type: STRUCTURE_ORGANIZATION_FAILED });
      if (payload?.onError) yield payload.onError(structure?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: STRUCTURE_ORGANIZATION_FAILED });
    if (payload?.onError) yield payload.onError('');
  }
}

export default function* organizationSaga() {
  yield takeLatest(LOADING_ORGANIZATION, loadingOG);
  yield takeLatest(CREATE_ORGANIZATION, createOG);
  yield takeLatest(UPDATE_ORGANIZATION, updateOG);
  yield takeLatest(CHECK_ACCESS_CODE, checkAccessCodeOG);
  yield takeLatest(GET_DETAIL_ORGANIZATION, getDetailOG);
  yield takeLatest(MULTI_ACTION_ORGANIZATION, multiActionOG);
  yield takeLatest(HIDE_MULTI_ORGANIZATION, hideMultiOG);
  yield takeLatest(STRUCTURE_ORGANIZATION, structureOG);
}

import { IAuthSelector } from 'pages/auth/interfaces';
import { API_URL } from 'config';
import io from 'socket.io-client';
import store from 'app/store';
let socket: any;

const config = (token: string) => {
  return {
    transports: ['websocket'],
    secure: true,
    path: '/socket.io',
    query: { token, from: 'admin' },
  };
};

export const initialSocketChat = (token: string, roomId) => {
  socket = io(`${API_URL}/chat`, config(token));
  onConnect(roomId);
};

export const initialSocketNotice = (token: string) => {
  socket = io(`${API_URL}/notifications`, config(token));
};

/**
 * @socket
 * @emit
 */

export const joinRoom = (room: string) => {
  if (socket) {
    return socket.emit('joinRoom', room);
  }
};

export const leaveRoom = (room: string) => {
  if (socket) {
    return socket.emit('leaveRoom', room);
  }
};

export const sendMessage = (room: string, text: string) => {
  if (socket) {
    return socket.emit('msgToServer', { text, room });
  }
};

/**
 * @socket
 * @on
 */

export const onReceiveMessage = (callback: (msg: any) => void) => {
  if (socket) {
    return socket.on('msgToClient', (msg: any) => {
      callback(msg);
    });
  }
};

export const onHandleErrorSocket = (callback: (msg: any) => void) => {
  if (socket) {
    return socket.on('Error', (msg: any) => {
      callback(msg);
    });
  }
};

export const onConnect = (roomId) => {
  if (socket) {
    socket.on('connect', () => {
      joinRoom(roomId);
    });
  }
};

export const onDisconnect = (onMessErrConnect) => {
  if (socket) {
    return socket.on('disconnect', () => {
      onMessErrConnect && onMessErrConnect();
      console.log('🚀 ~ disconnect');
    });
  }
};

export const onJoinRoom = (callback: (room: string) => void) => {
  if (socket) {
    return socket.on('joinedRoom', (room: string) => {
      callback(room);
    });
  }
};

export const onLeftRoom = (callback: (room: string) => void) => {
  if (socket) {
    return socket.on('leftRoom', (room: string) => {
      callback(room);
    });
  }
};

export const onReceiveNotice = (callback: (msg: any) => void) => {
  const auth: IAuthSelector = store.getState?.()?.auth;
  if (socket && auth?.profile) {
    const onEvent: string = `notifications_root_org_id_${auth.profile?.root_org_id}`;
    return socket.on(onEvent, (msg: any) => {
      callback(msg);
    });
  }
};

export const onReceiveNoticeIncidentAssign = (callback: (msg: any) => void) => {
  const auth: IAuthSelector = store.getState?.()?.auth;
  if (socket && auth?.profile) {
    const onEvent: string = `notifications_incident_asignee_${auth.profile?._id}`;
    return socket.on(onEvent, (msg: any) => {
      callback(msg);
    });
  }
};

export const onStartMaintaince = (callback: (msg: any) => void) => {
  if (socket) {
    const onEvent: string = `start_maintaince`;
    return socket.on(onEvent, (msg: any) => {
      callback(msg);
    });
  }
};

export const onStopMaintaince = (callback: (msg: any) => void) => {
  if (socket) {
    const onEvent: string = `stop_maintaince`;
    return socket.on(onEvent, (msg: any) => {
      callback(msg);
    });
  }
};

/**
 * Disconnect socket
 */

export const disconnectSocket = () => {
  if (socket) {
    return socket.disconnect();
  }
};

import API from 'libs/axios';
import {
  IQueryLoadingReportActiveTimeline,
  IQueryLoadingReportActiveUsers,
} from 'pages/reports/interfaces';
import { queryString } from 'utils';

const PATH: string = 'admin/reports';

export const getActiveTimeline = (query: IQueryLoadingReportActiveTimeline) => {
  const params: any = {};

  Object.entries(query).forEach(([key, value]: any) => {
    if (value) params[key] = value;
  });

  const qs: string = queryString(params);

  return API.get(`${PATH}/timeline_users?${qs}`);
};

export const getActiveUser = (query: IQueryLoadingReportActiveUsers) => {
  const params: any = {};

  Object.entries(query).forEach(([key, value]: any) => {
    if (value) params[key] = value;
  });

  const qs: string = queryString(params);

  return API.get(`${PATH}/active_users?${qs}`);
};

import {
  CREATE_INCIDENTS,
  CREATE_INCIDENTS_FAILED,
  CREATE_INCIDENTS_SUCCESS,
  CREATE_NOTE_INCIDENTS,
  CREATE_NOTE_INCIDENTS_FAILED,
  LOADING_INCIDENTS,
  LOADING_INCIDENTS_DETAIL,
  LOADING_INCIDENTS_DETAIL_LIST_ORG_ADMIN,
  LOADING_INCIDENTS_DETAIL_SEARCH_TARGETS,
  LOADING_INCIDENTS_FAILED,
  LOADING_INCIDENTS_SUCCESS,
  LOADING_ACTIVITYLOG,
  LOADING_NOTE_INCIDENTS,
  LOADING_NOTE_INCIDENTS_FAILED,
  LOADING_NOTE_INCIDENTS_SUCCESS,
  SHARE_INCIDENTS,
  UPDATE_INCIDENTS,
  LOADING_ACTIVITYLOG_SUCCESS,
  LOADING_ACTIVITYLOG_FAILED,
  DEACTIVE_USER_FAILED,
  DEACTIVE_USER_SUCCESS,
  DEACTIVE_USER,
  UPDATE_INCIDENTS_FAILED,
  UPDATE_INCIDENTS_SUCCESS,
  LOADING_TOTAL_INFO_INCIDENTS,
  READ_CHAT_INCIDENTS,
  LOADING_CHAT_INCIDENTS,
  LOADING_CHAT_INCIDENTS_SUCCESS,
  LOADING_CHAT_INCIDENTS_FAILED,
  APPEND_MESSAGE_INCIDENT_SUCCESS,
  APPEND_MESSAGE_INCIDENT,
  LOADING_OTHER_INCIDENTS,
  LOADING_INCIDENTS_AT_NOTICE,
  LOADING_ALL_INCIDENTS_ATTR_SUCCESS,
  LOADING_ALL_INCIDENTS_ATTR_FAILED,
  LOADING_ALL_INCIDENTS_ATTR,
} from 'actions/incidents';
import {
  getIncident,
  postCreateIncidents,
  getIncidentDetail,
  getInfoOrgAdmin,
  updateFormDetailIncident,
  apiSearch,
  getNotes,
  postCreateNotes,
  postShareIncidents,
  getActiviLog,
  postDeactiveUser,
  getTotalInfoIncidents,
  getChatIncidents,
  postReadChatIncidents,
  getOtherIncidents,
  getListIncidentAttrAll,
} from 'api/incidents';
import { history } from 'libs';
import {
  IActionLoadingIncidents,
  ICreateIncidentsAction,
  IActionGetDetail,
  IActionGetOrgAdmin,
  IActionUpdateDetailIncident,
  IActionSearch,
  IActionLoadingNotesIncidents,
  IActionCreateNotesIncidents,
  IActionShareIncidents,
  IActionGetActivityLog,
  IActionDeactiveUser,
  IActionLoadingTotalInfoIncidents,
  IActionLoadingChatIncidents,
  IActionReadChatIncidents,
  IActionAppendMessageIncidents,
  IActionLoadingOtherIncidents,
  IActionGetListAttrAll,
} from 'pages/incidents/interfaces';
import { ESelectOrganizationValues } from 'pages/setting/interfaces';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { FORBIDDEN, OK, OS_CANT_MANAGE_INCIDENT } from 'utils';

function* loadingIncidents({ payload }: IActionLoadingIncidents) {
  try {
    const get = yield call(getIncident, payload.query);

    if (get?.status === OK) {
      const { data } = get;
      if (!payload.ignoreDispatchReducer) {
        yield put({ type: LOADING_INCIDENTS_SUCCESS, payload: data });
      }

      if (payload?.onSuccess) yield payload.onSuccess(data);
    } else {
      yield put({ type: LOADING_INCIDENTS_FAILED });
      if (payload?.onError) yield payload.onError(get?.error_code);
      switch (get?.error_code) {
        case FORBIDDEN:
        case OS_CANT_MANAGE_INCIDENT:
          return history.push({
            pathname: '/admin/no-permission',
          });
        default:
          break;
      }
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: LOADING_INCIDENTS_FAILED });
    if (payload?.onError) yield payload.onError('');
  }
}

function* createIncidents({ payload }: ICreateIncidentsAction) {
  try {
    const create = yield call(postCreateIncidents, payload.values);

    if (create?.status === OK) {
      const { data } = create;
      yield put({ type: CREATE_INCIDENTS_SUCCESS });
      if (payload?.onSuccess) yield payload.onSuccess(data);
    } else {
      yield put({ type: CREATE_INCIDENTS_FAILED });
      if (payload?.onError) yield payload.onError(create?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: CREATE_INCIDENTS_FAILED });
    if (payload?.onError) yield payload.onError('');
  }
}

function* incidentDetail({ payload }: IActionGetDetail) {
  try {
    const response = yield call(getIncidentDetail, payload.query);
    if (response?.status === OK) {
      if (payload?.onSuccess) yield payload?.onSuccess(response.data);
    } else {
      if (payload?.onError) yield payload?.onError();
      history.replace({
        pathname: '/admin/no-permission',
      });
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    if (payload?.onError) yield payload?.onError();
  }
}

function* loadingListOrgAdmin({ payload }: IActionGetOrgAdmin) {
  try {
    const response = yield call(getInfoOrgAdmin, payload.query);
    if (response?.status === OK) {
      if (payload?.onSuccess) yield payload?.onSuccess(response.data);
    } else {
      if (payload?.onError) yield payload?.onError();
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    if (payload?.onError) yield payload?.onError();
  }
}

function* waittingActionUpdate({ payload }: IActionUpdateDetailIncident) {
  try {
    const response = yield call(updateFormDetailIncident, payload.data);

    if (response?.status === OK) {
      yield put({ type: UPDATE_INCIDENTS_SUCCESS });
      if (payload?.onSuccess) yield payload?.onSuccess();
    } else {
      yield put({ type: UPDATE_INCIDENTS_FAILED });

      if (payload?.onError) yield payload?.onError(response?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: UPDATE_INCIDENTS_FAILED });

    if (payload?.onError) yield payload?.onError('');
  }
}

function* loadingSearchTargets({ payload }: IActionSearch) {
  try {
    const response = yield call(apiSearch, payload.query);
    if (response?.status === OK) {
      if (payload?.onSuccess) yield payload?.onSuccess(response.data);
    } else {
      if (payload?.onError) yield payload?.onError();
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    if (payload?.onError) yield payload?.onError();
  }
}

function* waitingActiviLog({ payload }: IActionGetActivityLog) {
  try {
    const response = yield call(getActiviLog, payload.query);
    if (response?.status === OK) {
      yield put({ type: LOADING_ACTIVITYLOG_SUCCESS });

      if (payload?.onSuccess) yield payload?.onSuccess(response.data);
    } else {
      yield put({ type: LOADING_ACTIVITYLOG_FAILED });
      if (payload?.onError) yield payload?.onError();
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: LOADING_ACTIVITYLOG_FAILED });
    if (payload?.onError) yield payload?.onError();
  }
}

function* loadingNotes({ payload }: IActionLoadingNotesIncidents) {
  try {
    const get = yield call(getNotes, payload.incident_id);

    if (get?.status === OK) {
      yield put({ type: LOADING_NOTE_INCIDENTS_SUCCESS });
      if (payload?.onSuccess) yield payload?.onSuccess(get.data);
    } else {
      yield put({ type: LOADING_NOTE_INCIDENTS_FAILED });
      if (payload?.onError) yield payload?.onError(get?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: LOADING_NOTE_INCIDENTS_FAILED });
    if (payload?.onError) yield payload?.onError('');
  }
}

function* createNotes({ payload }: IActionCreateNotesIncidents) {
  try {
    const create = yield call(postCreateNotes, payload.values);

    if (create?.status === OK) {
      yield put({ type: LOADING_NOTE_INCIDENTS_SUCCESS });
      if (payload?.onSuccess) yield payload?.onSuccess();
    } else {
      yield put({ type: CREATE_NOTE_INCIDENTS_FAILED });
      if (payload?.onError) yield payload?.onError(create?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: CREATE_NOTE_INCIDENTS_FAILED });
    if (payload?.onError) yield payload?.onError('');
  }
}

function* shareIncidents({ payload }: IActionShareIncidents) {
  try {
    const share = yield call(postShareIncidents, payload.values);

    if (share?.status === OK) {
      if (payload?.onSuccess) yield payload?.onSuccess();
    } else {
      if (payload?.onError) yield payload?.onError(share?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    if (payload?.onError) yield payload?.onError('');
  }
}

function* waitingDeaciveUser({ payload }: IActionDeactiveUser) {
  try {
    const create = yield call(postDeactiveUser, payload);

    if (create?.status === OK) {
      yield put({ type: DEACTIVE_USER_SUCCESS });
      if (payload?.onSuccess) yield payload?.onSuccess();
    } else {
      yield put({ type: DEACTIVE_USER_FAILED });
      if (payload?.onError) yield payload?.onError(create?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: DEACTIVE_USER_FAILED });
    if (payload?.onError) yield payload?.onError('');
  }
}

function* getTotalInfo({ payload }: IActionLoadingTotalInfoIncidents) {
  try {
    const get = yield call(getTotalInfoIncidents, payload.query);

    if (get?.status === OK) {
      const { data } = get;
      if (payload?.onSuccess) yield payload?.onSuccess(data);
    } else {
      if (payload?.onError) yield payload?.onError(get?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    if (payload?.onError) yield payload?.onError('');
  }
}

function* loadOtherIncidents({ payload }: IActionLoadingOtherIncidents) {
  try {
    let selectedOrgId = yield select((state) => state.users.selectedOrgId);
    selectedOrgId = selectedOrgId === ESelectOrganizationValues.ALL_IN_CHARGE ? '' : selectedOrgId;
    const { showHidden } = yield select((state) => state.auth);
    const get = yield call(getOtherIncidents, payload.device_user_id, selectedOrgId, showHidden);

    if (get?.status === OK) {
      const { data } = get;
      if (payload?.onSuccess) yield payload?.onSuccess(data);
    } else {
      if (payload?.onError) yield payload?.onError(get?.error_code);
    }
  } catch (error) {}
}

// Chat

function* getChats({ payload }: IActionLoadingChatIncidents) {
  try {
    const get = yield call(getChatIncidents, payload.incident_id);

    if (get?.status === OK) {
      const { data } = get;
      let newData: any = [];

      if (Array.isArray(data)) {
        newData = data.reverse();
      }

      yield put({ type: LOADING_CHAT_INCIDENTS_SUCCESS, payload: newData });
      if (payload?.onSuccess) yield payload?.onSuccess(data);
    } else {
      yield put({ type: LOADING_CHAT_INCIDENTS_FAILED });
      if (payload?.onError) yield payload?.onError(get?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: LOADING_CHAT_INCIDENTS_FAILED });
    if (payload?.onError) yield payload?.onError('');
  }
}

function* appendMessage({ payload }: IActionAppendMessageIncidents) {
  yield put({ type: APPEND_MESSAGE_INCIDENT_SUCCESS, payload: payload.msg });
  if (payload?.onSuccess) yield payload?.onSuccess();
}

function* readChat({ payload }: IActionReadChatIncidents) {
  try {
    const read = yield call(postReadChatIncidents, payload.values);

    if (read?.status === OK) {
      const { data } = read;
      if (payload?.onSuccess) yield payload?.onSuccess(data);
    } else {
      if (payload?.onError) yield payload?.onError(read?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    if (payload?.onError) yield payload?.onError('');
  }
}

function* waitingAllIncidentAttr({ payload }: IActionGetListAttrAll) {
  try {
    const get = yield call(getListIncidentAttrAll, payload.query);

    if (get?.status === OK) {
      const { data } = get;
      yield put({ type: LOADING_ALL_INCIDENTS_ATTR_SUCCESS });
      if (payload?.onSuccess) yield payload?.onSuccess(data);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: LOADING_ALL_INCIDENTS_ATTR_FAILED });
    if (payload?.onError) yield payload?.onError();
  }
}

export default function* incidentsSaga() {
  yield takeLatest(LOADING_INCIDENTS, loadingIncidents);
  yield takeLatest(LOADING_INCIDENTS_AT_NOTICE, loadingIncidents);
  yield takeLatest(CREATE_INCIDENTS, createIncidents);
  yield takeLatest(LOADING_INCIDENTS_DETAIL, incidentDetail);
  yield takeLatest(LOADING_INCIDENTS_DETAIL_LIST_ORG_ADMIN, loadingListOrgAdmin);
  yield takeLatest(LOADING_INCIDENTS_DETAIL_SEARCH_TARGETS, loadingSearchTargets);
  yield takeLatest(UPDATE_INCIDENTS, waittingActionUpdate);
  yield takeLatest(LOADING_ALL_INCIDENTS_ATTR, waitingAllIncidentAttr);
  yield takeLatest(LOADING_ACTIVITYLOG, waitingActiviLog);
  yield takeLatest(LOADING_NOTE_INCIDENTS, loadingNotes);
  yield takeLatest(CREATE_NOTE_INCIDENTS, createNotes);
  yield takeLatest(SHARE_INCIDENTS, shareIncidents);
  yield takeLatest(DEACTIVE_USER, waitingDeaciveUser);
  yield takeLatest(LOADING_TOTAL_INFO_INCIDENTS, getTotalInfo);
  yield takeLatest(LOADING_OTHER_INCIDENTS, loadOtherIncidents);
  yield takeLatest(LOADING_CHAT_INCIDENTS, getChats);
  yield takeLatest(APPEND_MESSAGE_INCIDENT, appendMessage);
  yield takeLatest(READ_CHAT_INCIDENTS, readChat);
}

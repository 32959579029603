import API from 'libs/axios';
import {
  IDeleteBroadcastFormValues,
  IUpdateBroadcastFormValues,
} from 'pages/broadcasts/interfaces/detail-broadcast';
import {
  ICreateBroadcastFormValues,
  IPayloadGetBroadcastByOrg,
} from 'pages/setting/interfaces/broadcast';
import { queryString } from 'utils';

const PATH: string = 'admin/broad-cast';

export const getBroadcastbyOrg = (query: IPayloadGetBroadcastByOrg['query']) => {
  const params: any = {};

  Object.entries(query).forEach(([key, value]: any) => {
    if (value) params[key] = value;
  });

  const qs: string = queryString(params);
  return API.get(`${PATH}/list?${qs}`);
};

export const getBroadcastDetail = (id: string) => API.get(`${PATH}/detail?id=${id}`);

export const postCreateBroadcast = (payload: ICreateBroadcastFormValues) =>
  API.post(`${PATH}/action`, payload);
export const postUpdateBroadcast = (payload: IUpdateBroadcastFormValues) =>
  API.post(`${PATH}/action`, payload);
export const postDeleteBroadcast = (payload: IDeleteBroadcastFormValues) =>
  API.post(`${PATH}/action`, payload);
export const postResendBroadcast = (id: string) => API.post(`${PATH}/resend-broad-cast`, { id });
export const updatePublicBroadcast = (payload) => API.post(`${PATH}/update-public`, payload);

import React, { forwardRef } from 'react';
import { createSOSAction } from 'actions/sos';
import { Button, Form, Input, Modal, Space } from 'antd';
import { Radio } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ICreateSOSFormValues, IPayloadCreateSOS } from 'pages/setting/interfaces';
import { showSuccess, showError } from 'components/standby-notice';
import { useTranslation } from 'react-i18next';
import trim from 'lodash/trim';
import ConfirmButton from 'components/common/ConfirmButton';

const ModalFormCreate = ({ visible, onCancel, loadingListSOS, myOrgId }, ref) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const isLoading: boolean = useAppSelector((state) => state.sos.isLoading);

  const onFinish = (values: ICreateSOSFormValues) => {
    values.org_id = myOrgId;

    const payload: IPayloadCreateSOS = {
      values: {
        ...values,
        title: trim(values.title),
        value: trim(values.value),
      },
      onSuccess: () => {
        loadingListSOS();
        form.resetFields();

        onCancel();
        showSuccess(t('settingPage.sosSetUp.create.message.success'));
      },
      onError: (errorCode: string) => {
        console.log('🚀 ~ errorCode', errorCode);
        showError(t('settingPage.sosSetUp.create.message.error'));
      },
    };

    return dispatch(createSOSAction(payload));
  };

  const handleChangeType = () => {
    form.setFieldsValue({ value: '' });
  };

  const isValidHttpUrl = (rule, value, callback) => {
    let url;

    if (value) {
      try {
        url = new URL(value);
      } catch (_) {
        // throw new Error(t('settingPage.sosSetUp.form.require.url'));
        return Promise.reject(new Error(t('settingPage.sosSetUp.form.require.url')));
      }
      if (url.protocol !== 'http:' && url.protocol !== 'https:') {
        return Promise.reject(new Error(t('settingPage.sosSetUp.form.require.url')));
      }
    }

    return Promise.resolve();
  };

  return (
    <Modal
      maskClosable={false}
      title={t('settingPage.sosSetUp.addSos')}
      centered
      visible={visible}
      onCancel={onCancel}
      width={625}
      className="modal md-form-sos"
      footer={[
        <ConfirmButton
          className="btn-add"
          buttonTitle={t('settingPage.sosSetUp.create.add')}
          onClick={() => form.submit()}
          form={form}
          keyName="create"
          size="large"
          isLoading={isLoading}
        />,
        <Button key="close" onClick={onCancel} className="btn-close" size="large">
          {t('common.cancelBtn')}
        </Button>,
      ]}
    >
      <Form
        onFinish={onFinish}
        layout="vertical"
        requiredMark={false}
        form={form}
        ref={ref}
        initialValues={{
          type: 'PHONE',
        }}
      >
        <Form.Item
          label={t('settingPage.sosSetUp.create.nameSos')}
          className="title"
          name="title"
          rules={[
            {
              required: true,
              whitespace: true,
              message: t('settingPage.sosSetUp.form.require.nameSOS'),
            },
          ]}
        >
          <Input
            size="large"
            placeholder={t('settingPage.sosSetUp.create.nameSos')}
            maxLength={70}
            className="input-name"
          />
        </Form.Item>

        <Form.Item name="type">
          <Radio.Group className="radio-sos" onChange={handleChangeType}>
            <Space direction="vertical" size={20} className="space-radio-sos">
              <Radio value="PHONE" className="cb-values">
                <Form.Item noStyle shouldUpdate={(p, n) => p.type !== n.type}>
                  {({ getFieldValue }) => {
                    const getType: String = getFieldValue('type');
                    return (
                      <Form.Item
                        className="radio-title"
                        label={t('settingPage.sosSetUp.create.phone')}
                        name={getType !== 'PHONE' ? '' : 'value'}
                        rules={[
                          {
                            required: getType === 'PHONE',
                            message: t('settingPage.sosSetUp.form.require.callNumber'),
                          },
                          {
                            pattern: new RegExp(/^[0-9]+$/),
                            message: t('settingPage.myInfo.phoneFormat'),
                          },
                        ]}
                      >
                        <Input
                          disabled={getType !== 'PHONE'}
                          size="large"
                          className="input-value"
                          placeholder={t('settingPage.sosSetUp.create.phone')}
                          maxLength={12}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Radio>
              <Radio value="URL" className="cb-values">
                <Form.Item noStyle shouldUpdate={(p, n) => p.type !== n.type}>
                  {({ getFieldValue }) => {
                    const getType: String = getFieldValue('type');
                    return (
                      <Form.Item
                        className="radio-title"
                        label={t('settingPage.sosSetUp.create.url')}
                        name={getType !== 'URL' ? '' : 'value'}
                        rules={[
                          {
                            required: getType === 'URL',
                            message: t('settingPage.sosSetUp.form.require.url'),
                          },

                          { validator: isValidHttpUrl },
                        ]}
                      >
                        <Input
                          disabled={getType !== 'URL'}
                          size="large"
                          className="input-value"
                          placeholder={t('settingPage.sosSetUp.create.url')}
                          maxLength={256}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default forwardRef(ModalFormCreate);

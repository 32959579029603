import { useState, useEffect } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { GithubPicker } from 'react-color';
import { useTranslation } from 'react-i18next';

interface IProps {
  color?: string;
  callback: (hex: string) => void;
  triggerValidate?: boolean;
  label?: string;
  isOpenSlcColor?: boolean;
  cbCloseSlcColor?: any;
}

const PickerColor = ({
  color,
  callback,
  triggerValidate = false,
  label,
  isOpenSlcColor,
  cbCloseSlcColor,
}: IProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  const [colorPicked, setColorPicked] = useState<string | undefined>(color);

  const handleChangeComplete = (color: any) => {

    setOpen(false);
    if (callback) {
      callback(color.hex);
      return;
    }
    setColorPicked(undefined);
  };

  useEffect(() => {
    setColorPicked(color);
  }, [color]);

  useEffect(() => {
    if (isOpenSlcColor) {
      setOpen(false);
    }
  }, [isOpenSlcColor, cbCloseSlcColor]);

  return (
    <div>
      <div style={{ fontSize: 14, lineHeight: '120%', marginBottom: 3 }}>
        {label ? label : t('settingPage.incidentManagement.crud.modal.form.color.label')}
      </div>
      <div
        onClick={() => {
          setOpen((preState) => !preState);
          cbCloseSlcColor(isOpenSlcColor);
        }}
        style={{
          borderRadius: 4,
          border: `1px solid ${triggerValidate && !colorPicked ? '#ff4d4f' : '#DADADA'}`,
          height: 40,
          width: 150,
          display: 'flex',
          justifyItems: 'center',
          cursor: 'pointer',
          lineHeight: '32px',
          position: 'relative',
        }}
      >
        <div style={{ width: '85%', paddingLeft: 12, paddingRight: 12 }}>
          <div
            style={{ backgroundColor: colorPicked, height: 20, marginTop: 9, borderRadius: 2 }}
          />
        </div>
        <div
          style={{
            width: '15%',
            padding: 5,
            display: 'flex',
            alignItems: 'center',
            paddingLeft: 3,
          }}
        >
          <DownOutlined style={{ fontSize: 12 }} />
        </div>
      </div>

      {open ? (
        <div style={{ position: 'absolute' }}>
          <GithubPicker color={colorPicked} onChangeComplete={handleChangeComplete} />
        </div>
      ) : null}
      {triggerValidate && !colorPicked ? (
        <span style={{ color: '#ff4d50', fontSize: 15 }}>
          {t('settingPage.incidentManagement.crud.modal.form.color.required')}
        </span>
      ) : null}
    </div>
  );
};

export { PickerColor };

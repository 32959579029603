import { Button, Space, Typography } from 'antd';
import { ICheckableActionProps } from 'pages/setting/interfaces';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

const CheckableAction = ({
  selected,
  handleMove,
  handleHide,
  isLoading,
  disabledMove
}: ICheckableActionProps) => {
  const { t } = useTranslation();
  return (
    <Space className="checkable-action-container" size={10}>
      <Space className="selected-txt-block" size={4}>
        <Typography.Text className="txt-selected">
          {t('settingPage.ogSetup.multiSelect.selected', { count: selected })}
        </Typography.Text>
      </Space>
      <Button className="btn-move" onClick={handleMove} loading={isLoading} disabled={!!disabledMove}>
        {t('settingPage.ogSetup.moveOg')}
      </Button>
      <Button className="btn-hide" danger onClick={handleHide} loading={isLoading}>
        {t('settingPage.ogSetup.hideOg')}
      </Button>
    </Space>
  );
};

export default memo(CheckableAction);

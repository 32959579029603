import {
  CHANGE_AUTOMATED_RESPONSE,
  CHANGE_AUTOMATED_RESPONSE_FAILED,
  CHANGE_AUTOMATED_RESPONSE_SUCCESS,
  CREATE_HOLIDAY,
  CREATE_HOLIDAY_FAILED,
  CREATE_HOLIDAY_SUCCESS,
  DELETE_HOLIDAY,
  DELETE_HOLIDAY_FAILED,
  DELETE_HOLIDAY_SUCCESS,
  EDIT_HOLIDAY,
  EDIT_HOLIDAY_FAILED,
  EDIT_HOLIDAY_SUCCESS,
  LOADING_AUTOMATED_RESPONSE,
  LOADING_AUTOMATED_RESPONSE_FAILED,
  LOADING_AUTOMATED_RESPONSE_SUCCESS,
  LOADING_HOLIDAY,
  LOADING_HOLIDAY_FAILED,
  LOADING_HOLIDAY_SUCCESS,
  CHANGE_ON_OFF_RESPONSE,
} from 'actions/automated-response';
import { notification } from 'antd';
import {
  deleteHoliday,
  getAutomatedResponse,
  getHoliday,
  postChangeAutomatedResponse,
  postCreateHoliday,
  putUpdateHoliday,
  postOnOffAutomatedResponse,
} from 'api/automated-response';
import i18n from 'config/i18n';
import {
  IActionChangeAutomatedResponse,
  IActionCreateHoliday,
  IActionDeleteHoliday,
  IActionEditHoliday,
  IActionGetAutomatedResponse,
  IActionLoadingHoliday,
} from 'pages/setting/interfaces';
import { call, put, takeLatest } from 'redux-saga/effects';
import { OK, WORKING_TIME_INVALID } from 'utils';

function* loadingAutomated({ payload }: IActionGetAutomatedResponse) {
  try {
    const get = yield call(getAutomatedResponse, payload.organization_id);

    if (get?.status === OK) {
      const { data } = get;
      yield put({ type: LOADING_AUTOMATED_RESPONSE_SUCCESS });
      if (payload?.onSuccess) yield payload.onSuccess(data);
    } else {
      yield put({ type: LOADING_AUTOMATED_RESPONSE_FAILED });
      if (payload?.onError) yield payload.onError(get?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: LOADING_AUTOMATED_RESPONSE_FAILED });
    if (payload?.onError) yield payload.onError('');
  }
}

function* changeAutomated({ payload }: IActionChangeAutomatedResponse) {
  try {
    const change = yield call(postChangeAutomatedResponse, payload.values);

    if (change?.status === OK) {
      yield put({ type: CHANGE_AUTOMATED_RESPONSE_SUCCESS });
      if (payload?.onSuccess) yield payload.onSuccess();
    } else {
      yield put({ type: CHANGE_AUTOMATED_RESPONSE_FAILED });
      if (payload?.onError) yield payload.onError(change?.error_code);

      let message: string = i18n.t('error.common');

      switch (change?.error_code) {
        case WORKING_TIME_INVALID:
          message = i18n.t('settingPage.automatedResponse.workingTimeInvalid');
          break;
        default:
          break;
      }

      yield notification.error({ message });
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: CHANGE_AUTOMATED_RESPONSE_FAILED });
    if (payload?.onError) yield payload.onError('');
  }
}

function* changeOnOffAutomated({ payload }: IActionChangeAutomatedResponse) {
  try {
    const change = yield call(postOnOffAutomatedResponse, payload.values);

    if (change?.status === OK) {
      yield put({ type: CHANGE_ON_OFF_RESPONSE });
      if (payload?.onSuccess) yield payload.onSuccess();
    } else {
      yield put({ type: CHANGE_AUTOMATED_RESPONSE_FAILED });
      if (payload?.onError) yield payload.onError(change?.error_code);

      let message: string = i18n.t('error.common');

      switch (change?.error_code) {
        case WORKING_TIME_INVALID:
          message = i18n.t('settingPage.automatedResponse.workingTimeInvalid');
          break;
        default:
          break;
      }

      yield notification.error({ message });
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: CHANGE_AUTOMATED_RESPONSE_FAILED });
    if (payload?.onError) yield payload.onError('');
  }
}

function* loadingHoliday({ payload }: IActionLoadingHoliday) {
  try {
    const get = yield call(getHoliday, payload.organization_id);

    if (get?.status === OK) {
      const { data } = get;
      yield put({ type: LOADING_HOLIDAY_SUCCESS, payload: data });
      if (payload?.onSuccess) yield payload.onSuccess();
    } else {
      yield put({ type: LOADING_HOLIDAY_FAILED });
      if (payload?.onError) yield payload.onError(get?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: LOADING_HOLIDAY_FAILED });
    if (payload?.onError) yield payload.onError('');
  }
}

function* createHoliday({ payload }: IActionCreateHoliday) {
  try {
    const create = yield call(postCreateHoliday, payload.values);

    if (create?.status === OK) {
      yield put({ type: CREATE_HOLIDAY_SUCCESS });
      if (payload?.onSuccess) yield payload.onSuccess();
    } else {
      yield put({ type: CREATE_HOLIDAY_FAILED });
      if (payload?.onError) yield payload.onError(create?.error_code);
      let message: string = i18n.t('error.common');
      yield notification.error({ message });
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: CREATE_HOLIDAY_FAILED });
    if (payload?.onError) yield payload.onError('');
  }
}

function* editHoliday({ payload }: IActionEditHoliday) {
  try {
    const edit = yield call(putUpdateHoliday, payload.holiday_id, payload.values);

    if (edit?.status === OK) {
      yield put({ type: EDIT_HOLIDAY_SUCCESS });
      if (payload?.onSuccess) yield payload.onSuccess();
    } else {
      yield put({ type: EDIT_HOLIDAY_FAILED });
      if (payload?.onError) yield payload.onError(edit?.error_code);
      let message: string = i18n.t('error.common');
      yield notification.error({ message });
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: EDIT_HOLIDAY_FAILED });
    if (payload?.onError) yield payload.onError('');
  }
}

function* delHoliday({ payload }: IActionDeleteHoliday) {
  try {
    const del = yield call(deleteHoliday, payload.holiday_id);

    if (del?.status === OK) {
      yield put({ type: DELETE_HOLIDAY_SUCCESS });
      if (payload?.onSuccess) yield payload.onSuccess();
    } else {
      yield put({ type: DELETE_HOLIDAY_FAILED });
      if (payload?.onError) yield payload.onError(del?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: DELETE_HOLIDAY_FAILED });
    if (payload?.onError) yield payload.onError('');
  }
}

export default function* automatedResponseSaga() {
  yield takeLatest(LOADING_AUTOMATED_RESPONSE, loadingAutomated);
  yield takeLatest(CHANGE_AUTOMATED_RESPONSE, changeAutomated);
  yield takeLatest(LOADING_HOLIDAY, loadingHoliday);
  yield takeLatest(CREATE_HOLIDAY, createHoliday);
  yield takeLatest(EDIT_HOLIDAY, editHoliday);
  yield takeLatest(DELETE_HOLIDAY, delHoliday);
  yield takeLatest(CHANGE_ON_OFF_RESPONSE, changeOnOffAutomated);
}

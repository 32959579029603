/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, Space, Table, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import FormHoliday from './FormHoliday';
import { useAppDispatch, useAppSelector, useMyOrganizationID, useReadOnly } from 'app/hooks';
import {
  ERepeatHoliday,
  ICreateHolidayFormValues,
  IPayloadCreateHoliday,
  IPayloadDeleteHoliday,
  IPayloadEditHoliday,
  IPayloadLoadingHoliday,
} from 'pages/setting/interfaces';
import {
  createHolidayAction,
  deleteHolidayAction,
  editHolidayAction,
  loadingHolidayAction,
} from 'actions/automated-response';
import moment from 'moment';
import { showSuccess } from 'components/standby-notice';
import trim from 'lodash/trim';
import { AA, OS, RO, RM } from 'utils';
import ConfirmDeleteModal from 'components/common/ConfirmDeleteModal';

const Holiday = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isJustView: boolean = useReadOnly();
  const myOrgId: string = useMyOrganizationID();

  const isLoading: boolean = useAppSelector((state) => state.automatedResponses.isLoading);
  const holidays: any[] = useAppSelector((state) => state.automatedResponses.holidays);

  const [isVisibleForm, setIsVisibleForm] = React.useState<boolean>(false);
  const [isVisibleDelete, setIsVisibleDelete] = React.useState<boolean>(false);
  const [selectedItem, setSelectedItem] = React.useState<any>(null);
  const userRole: string = useAppSelector((state) => state.auth.role);
  const isAAOS: boolean = React.useMemo(() => [AA, OS].includes(userRole), [userRole]);

  const loadingHolidays = React.useCallback(() => {
    const payload: IPayloadLoadingHoliday = {
      organization_id: myOrgId,
    };

    return dispatch(loadingHolidayAction(payload));
  }, [myOrgId]);

  React.useEffect(() => {
    loadingHolidays();
  }, [myOrgId]);

  const handleVisibleDelete = (item: any) => {
    setSelectedItem(item);
    setIsVisibleDelete(true);
  };

  const handleCancelDelete = () => {
    setSelectedItem(null);
    setIsVisibleDelete(false);
  };

  const onDeleteHoliday = () => {
    const payload: IPayloadDeleteHoliday = {
      holiday_id: selectedItem?._id,
      onSuccess: () => {
        loadingHolidays();
        handleCancelDelete();
        showSuccess(t('settingPage.automatedResponse.holidayDeleteSuccess'));
      },
    };

    return dispatch(deleteHolidayAction(payload));
  };

  const onClickAddHoliday = () => {
    setIsVisibleForm(true);
  };

  const handleVisibleForm = (item: any) => {
    setSelectedItem(item);
    setIsVisibleForm(true);
  };

  const handleCancelForm = () => {
    setSelectedItem(null);
    setIsVisibleForm(false);
  };

  const isAllowEdit = (holiday) => {
    if (isAAOS && !myOrgId) return true;
    if (userRole !== RO && userRole !== RM && holiday?.org_id === myOrgId) return true;
    return false;
  };

  const onFinish = ({ type, start_date, end_date, title }: ICreateHolidayFormValues) => {
    const isEdited: boolean = !!selectedItem;

    if (isEdited) {
      const payload: IPayloadEditHoliday = {
        values: {
          type,
          title: trim(title),
          start_date: moment(start_date).format(),
          end_date: moment(end_date).format(),
        },
        holiday_id: selectedItem?._id,
        onSuccess: () => {
          loadingHolidays();
          handleCancelForm();
          showSuccess(t('settingPage.automatedResponse.holidayEditSuccess'));
        },
      };

      return dispatch(editHolidayAction(payload));
    }

    const payload: IPayloadCreateHoliday = {
      values: {
        type,
        title: trim(title),
        start_date: moment(start_date).format(),
        end_date: moment(end_date).format(),
        org_id: myOrgId,
      },
      onSuccess: () => {
        loadingHolidays();
        handleCancelForm();
        showSuccess(t('settingPage.automatedResponse.holidayCreateSuccess'));
      },
    };

    return dispatch(createHolidayAction(payload));
  };

  const columns: any = [
    {
      title: t('settingPage.automatedResponse.newHoliday.title'),
      dataIndex: 'title',
      key: 'title',
      // width: 326,
      ellipsis: {
        showTitle: false,
      },
      render: (title: string) => {
        return (
          <Tooltip placement="bottomLeft" title={title}>
            <Typography.Text>{title}</Typography.Text>
          </Tooltip>
        );
      },
    },
    {
      title: t('settingPage.automatedResponse.validFrom'),
      dataIndex: 'start_date',
      key: 'start_date',
      // width: 234,
      render: (record: string) => moment(record).format('YYYY/MM/DD HH:mm'),
    },
    {
      title: t('settingPage.automatedResponse.validTo'),
      dataIndex: 'end_date',
      key: 'end_date',
      // width: 234,
      render: (record: string) => moment(record).format('YYYY/MM/DD HH:mm'),
    },
    {
      title: t('settingPage.automatedResponse.repeat'),
      dataIndex: 'type',
      key: 'type',
      // width: 100,
      render: (record: string) =>
        t(`settingPage.automatedResponse.${record === ERepeatHoliday.YEARLY ? 'yearly' : 'none'}`),
    },
    {
      title: () => {
        if (isJustView) return null;

        return (
          <Button
            icon={<PlusOutlined />}
            type="primary"
            className="btn-add-holiday"
            onClick={onClickAddHoliday}
          >
            {t('settingPage.automatedResponse.addHoliday')}
          </Button>
        );
      },
      dataIndex: 'delete',
      width: 257,
      align: 'right',
      render: (_: any, record: any) => {
        if (isJustView || !isAllowEdit(record)) return null;

        return (
          <Space size={24}>
            <Tooltip placement="topLeft" title={t('setting.tooltip.editIcon')}>
              <img
                alt=""
                src="/images/ic-edit.png"
                className="ic-edit"
                onClick={() => handleVisibleForm(record)}
              />
            </Tooltip>
            <Tooltip placement="topLeft" title={t('setting.tooltip.deleteIcon')}>
              <img
                alt=""
                src="/images/ic-delete.png"
                className="ic-delete"
                onClick={() => handleVisibleDelete(record)}
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];
  return (
    <div className="holiday">
      <Table
        dataSource={holidays}
        columns={columns}
        pagination={false}
        className="table-holiday"
        loading={isLoading}
        scroll={{ y: 470 }}
        rowKey="_id"
        locale={{ emptyText: t('no_data', { name: t('settingPage.automatedResponse.holiday') }) }}
      />

      {/* Modal Form Holiday */}
      <FormHoliday
        isVisible={isVisibleForm}
        onCancel={handleCancelForm}
        isLoading={isLoading}
        selectedItem={selectedItem}
        onFinish={onFinish}
      />
      <ConfirmDeleteModal
        isVisible={isVisibleDelete}
        handleDelete={onDeleteHoliday}
        handleCancel={handleCancelDelete}
        modalTitle={t('settingPage.automatedResponse.deleteHoliday')}
        confirmText={t('settingPage.automatedResponse.deleteHoliday.confirmation')}
        deleteButtonTitle={t('settingPage.automatedResponse.deleteHoliday.delete')}
        isLoading={isLoading}
      />
    </div>
  );
};

export default React.memo(Holiday);

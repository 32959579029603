/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, Tabs, Typography } from 'antd';
import { useAppSelector } from 'app/hooks';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OS } from 'utils';
import MyInformation from './components/MyInformation';
import MyOptIns from './components/MyOptIns';
import MyRole from './components/MyRole';
import { useHistory, useLocation } from 'react-router-dom';
import './profile.scss';

const { TabPane } = Tabs;

const Profile = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const qs = new URLSearchParams(location.search);
  const auth = useAppSelector((state) => state.auth);
  const role: string = useAppSelector((state) => state.auth.role);
  const isOS: boolean = React.useMemo(() => role === OS, [role]);
  const [key, setKey] = useState(qs.get('tab') || '1');

  const handleChangeTabs = (tab) => {
    setKey(tab);
    return history.replace(history.location.pathname + '?tab=' + tab);
  };
  if (auth.isLoadingPermission) {
    return null;
  }

  return (
    <div className="profile">
      <div className="title">
        <Typography.Text className="txt">{t('settingPage.profile')}</Typography.Text>
      </div>

      <Divider className="divider-profile" />

      <div className="block-content">
        <Tabs defaultActiveKey={key} className="setting-tab" onChange={handleChangeTabs}>
          <TabPane tab={t('settingPage.myInfo')} key="1">
            <MyInformation />
          </TabPane>
          {!isOS && (
            <TabPane tab={t('settingPage.adminUser.optInPre')} key="2">
              <MyOptIns />
            </TabPane>
          )}
          <TabPane tab={t('settingPage.myRole')} key="3">
            <MyRole />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default React.memo(Profile);

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import pAuthReducer from './auth';
import { OrganizationReducer } from './organization';
import { IncidentAttrReducer } from './incidentAttr';
import { UsersReducer } from './users';
import { AutomatedResponseReducer } from './automated-response';
import { SecurityReducer } from './security';
import { LimitMessageReducer } from './limit-message';
import { IncidentQuestionReducer } from './incident-question';
import { SosReducer } from './sos';
import { IncidentsReducer } from './incidents';
import { BroadcastReducer } from './broadcast';
import { ReportsReducer } from './reports';
import { NotificationsReducer } from './notifications';

const rootReducer: any = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    auth: pAuthReducer,
    organizations: OrganizationReducer,
    incidentAttr: IncidentAttrReducer,
    securities: SecurityReducer,
    users: UsersReducer,
    limitMessages: LimitMessageReducer,
    questions: IncidentQuestionReducer,
    automatedResponses: AutomatedResponseReducer,
    sos: SosReducer,
    incidents: IncidentsReducer,
    broadcasts: BroadcastReducer,
    reports: ReportsReducer,
    notifications: NotificationsReducer,
  });

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
export type ActionProps = { type: string; payload: any };

import {
  LOADING_ACTIVE_TIMELINE,
  LOADING_ACTIVE_TIMELINE_FAILED,
  LOADING_ACTIVE_TIMELINE_SUCCESS,
  LOADING_ACTIVE_USER,
  LOADING_ACTIVE_USER_FAILED,
  LOADING_ACTIVE_USER_SUCCESS,
} from 'actions/reports';
import { getActiveTimeline, getActiveUser } from 'api/reports';
import {
  IActionLoadingReportActiveTimeline,
  IActionLoadingReportActiveUsers,
} from 'pages/reports/interfaces';
import { call, put, takeLatest } from 'redux-saga/effects';
import { OK } from 'utils';

function* loadingActiveUsers({ payload }: IActionLoadingReportActiveUsers) {
  try {
    const get = yield call(getActiveUser, payload.query);

    if (get?.status === OK) {
      const { data } = get;

      const newData = Array.isArray(data) ? data : [data];

      const newActiveUsers: any[] = newData.map((organization: any) => {
        let newSchools: any = [];

        if (organization.childrens?.length) {
          newSchools = organization.childrens.map((school: any) => {
            let newGrades: any = [];

            if (school.childrens?.length) {
              newGrades = school.childrens.map((grade: any) => {
                return {
                  ...grade,
                  isGrade: true,
                };
              });
            }

            return {
              ...school,
              isSchool: true,
              children: newGrades,
            };
          });
        }

        return {
          ...organization,
          isOrg: true,
          children: newSchools,
        };
      });

      yield put({ type: LOADING_ACTIVE_USER_SUCCESS });

      if (payload?.onSuccess) yield payload.onSuccess(newActiveUsers);
    } else {
      yield put({ type: LOADING_ACTIVE_USER_FAILED });
      if (payload?.onError) yield payload.onError();
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: LOADING_ACTIVE_USER_FAILED });
    if (payload?.onError) yield payload.onError();
  }
}

function* loadingActiveTimeline({ payload }: IActionLoadingReportActiveTimeline) {
  try {
    const get = yield call(getActiveTimeline, payload.query);

    if (get?.status === OK) {
      yield put({ type: LOADING_ACTIVE_TIMELINE_SUCCESS });

      if (payload?.onSuccess) yield payload.onSuccess(get?.data);
    } else {
      yield put({ type: LOADING_ACTIVE_TIMELINE_FAILED });
      if (payload?.onError) yield payload.onError(get?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: LOADING_ACTIVE_TIMELINE_FAILED });
    if (payload?.onError) yield payload.onError('');
  }
}

export default function* reportsSaga() {
  yield takeLatest(LOADING_ACTIVE_USER, loadingActiveUsers);
  yield takeLatest(LOADING_ACTIVE_TIMELINE, loadingActiveTimeline);
}

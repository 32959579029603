/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, Input, Space, Form, Select, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import {
  EFilterResponseStatusIncidents,
  IFiltersIncidentsFormValues,
  IFiltersIncidentsProps,
} from 'pages/incidents/interfaces';
import { useAppSelector } from 'app/hooks';
import { RO } from 'utils';
import debounce from 'lodash/debounce';
import { renderImageResponseStatus } from 'pages/incidents/helpers';

const { Option } = Select;

const Filters = React.forwardRef(
  ({ onFilter, handleVisibleCreate }: IFiltersIncidentsProps, ref: any) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const role: string = useAppSelector((state) => state.auth.role);

    const [optionsStatus, setOptionsStatus] = React.useState<any[]>([]);
    const listFilterStatus = useAppSelector((state) => state.incidents.listFilterStatus);
    const isReadOnly: boolean = role === RO;

    React.useEffect(() => {
      form.setFieldsValue({ status: undefined });
      const newOptionsStatus: any[] = listFilterStatus
        .map((item: any) => item?.name)
        .filter((e: string | null) => !!e);

      newOptionsStatus.unshift(t('incidents.list.all'));
      setOptionsStatus(newOptionsStatus);
      form.setFieldsValue({
        status: t('incidents.list.all'),
      });
    }, [listFilterStatus]);

    const getFilterQuery = (values: IFiltersIncidentsFormValues) => {
      const newQuery: IFiltersIncidentsFormValues = { ...values };

      const valuesAll: string[] = ['すべて', 'ALL', 'INCIDENTS.LIST.ALL'];
      if (valuesAll.includes(newQuery.status?.toUpperCase?.())) {
        newQuery.status = '';
      }
      if (valuesAll.includes(newQuery.responseStatus?.toUpperCase?.())) {
        newQuery.responseStatus = '';
      }

      return newQuery;
    };

    const onFinishFilter = (values: IFiltersIncidentsFormValues) => {
      const query: IFiltersIncidentsFormValues = getFilterQuery(values);

      onFilter(query);
    };

    const submitFormFilter = () => {
      return form.submit();
    };

    const debounceInputChange = debounce(submitFormFilter, 800);

    React.useImperativeHandle(ref, () => ({
      getFilterValues: () => {
        const formValues: IFiltersIncidentsFormValues = form.getFieldsValue();

        const query: IFiltersIncidentsFormValues = getFilterQuery(formValues);

        return query;
      },
    }));

    const responseStatus = [
      { value: EFilterResponseStatusIncidents.ALL, label: t('incidents.list.all') },
      { value: EFilterResponseStatusIncidents.UNREAD, label: t('incidents.list.unread') },
      {
        value: EFilterResponseStatusIncidents.READ_UNANSWERED,
        label: t('incidents.list.readAndUnanwered'),
      },
      {
        value: EFilterResponseStatusIncidents.READ_ANSWERED,
        label: t('incidents.list.readAndAnwered'),
      },
    ];

    return (
      <div className="filters-incidents">
        <Form
          form={form}
          onFinish={onFinishFilter}
          initialValues={{
            assigned: false,
            status: t('incidents.list.all'),
            responseStatus: EFilterResponseStatusIncidents.ALL,
          }}
        >
          <Space size={12}>
            <Form.Item name="search" noStyle>
              <Input
                placeholder={t('incidents.list.searchPlaceholder')}
                prefix={<SearchOutlined />}
                className="search-input"
                size="middle"
                allowClear
                onChange={debounceInputChange}
                maxLength={256}
                style={{ height: 32, marginTop: 1 }}
              />
            </Form.Item>
            <Form.Item noStyle name="status">
              <Select
                className={`select-status-test`}
                size="middle"
                onChange={submitFormFilter}
                dropdownClassName="dropdown-select-status"
                placeholder={t('incidents.list.status')}
                optionLabelProp="label"
                style={{ maxWidth: 300, borderRadius: '4 !important' }}
              >
                {optionsStatus.map((status, index: number) => (
                  <Option
                    value={status}
                    key={`${status}-${index}`}
                    label={`${t('incidents.list.status')}: ${status}`}
                  >
                    {status}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item noStyle name="responseStatus">
              <Select
                className={`select-response-status`}
                size="middle"
                onChange={submitFormFilter}
                dropdownClassName="dropdown-select-response-status"
                placeholder={t('incidents.list.responseStatus')}
                optionLabelProp="label"
                style={{ marginTop: 0, height: 32 }}
              >
                {responseStatus.map(({ value, label }, index: number) => {
                  const { src } = renderImageResponseStatus(value, 1);
                  return (
                    <Option
                      value={value}
                      key={`${value}-${index}`}
                      label={`${t('incidents.list.responseStatus')}: ${label}`}
                    >
                      <React.Fragment>
                        {value === EFilterResponseStatusIncidents.ALL ? null : (
                          <img src={src} alt="" />
                        )}
                        &nbsp;
                        {label}
                      </React.Fragment>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item noStyle name="assigned" valuePropName="checked">
              <Checkbox className="cb-assigned" onChange={submitFormFilter}>
                {t('incidents.list.assignedToMe')}
              </Checkbox>
            </Form.Item>
          </Space>
        </Form>

        {!isReadOnly && (
          <Button
            size="large"
            type="primary"
            className="btn-add-incident"
            icon={<PlusOutlined />}
            onClick={handleVisibleCreate}
          >
            {t('incidents.list.addIncidentBtn')}
          </Button>
        )}
      </div>
    );
  }
);

export default React.memo(Filters);

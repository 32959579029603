/* eslint-disable react-hooks/exhaustive-deps */
import { Typography, Space, Form, Checkbox, Tooltip, Spin } from 'antd';
import React, { useRef } from 'react';
import { apiDownloadImage, apiPreview, postUrlUpload } from 'api/incidents';
import axios from 'axios';
import {
  IPayloadActionUpdateDetailIncident,
  IQueryDownloadImage,
  IResponseIncidentDetail,
} from 'pages/incidents/interfaces';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { showError, showSuccess } from 'components/standby-notice';
import { updateDetailIncidentAction } from 'actions/incidents';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import OtherIncident from './OtherIncident';
import { EStatus } from './Notes';
import { getFileType } from 'utils/common';

interface IProps {
  data: IResponseIncidentDetail | null;
  getActivityLog: () => void;
  getIncidentDetail: () => void;
  handleCancelChat: () => void;
  currentRole: string;
}

const ListIncidentDetail = ({
  data,
  getActivityLog,
  getIncidentDetail,
  handleCancelChat,
  currentRole,
}: IProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const uploadRef: any = useRef();
  const dropRef: any = useRef();
  const [indexFile, setIndexFile] = useState(0);
  const [dragging, setDragging] = React.useState<boolean>(false);
  const [fileUpload, setFileUpload] = React.useState<any>();
  const [isVisibleIncidentUser, setIsVisibleIncidentUser] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleCancelIncidentUser = () => {
    setIsVisibleIncidentUser(false);
  };

  const showModalOtherIncident = () => {
    handleCancelChat();
    setIsVisibleIncidentUser(true);
  };

  const incident_id = data?._id;

  const loadingUpdateDetailIncident = (data) => {
    const payload: IPayloadActionUpdateDetailIncident = {
      data: data,
      onSuccess: () => {
        showSuccess(t(`incidents.detail.uploadFile.success`));
        getActivityLog();
        getIncidentDetail();
      },
      onError: () => {
        showError(t(`error.common`));
        getIncidentDetail();
      },
    };
    dispatch(updateDetailIncidentAction(payload));
  };

  const handleUploadFile = (fileList) => {
    if (fileList.length === 0) return;
    let fileMapping: any = [];

    Object.keys(fileList).forEach(function (key) {
      fileMapping = fileMapping.concat(fileList[key]);
    });
    fileMapping = fileMapping.map((e) => {
      return {
        name: encodeURIComponent(e.name),
        type: getFileType(e.name) === 'heic' || getFileType(e.name) === 'HEIC' ? 'heic' : e.type,
        size: e.size,
        file: e,
      };
    });

    handleUpload(fileMapping);
  };

  const handleUpload = async (fileList) => {
    const isAllowed = [
      'image/jpeg',
      'image/png',
      'audio/mpeg',
      'video/mp4',
      'application/pdf',
      'MOV',
      'mov',
      'image/heic',
      'heic',
      'video/quicktime',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];
    const fileType = fileList?.map((e) => isAllowed.includes(e?.type.toLowerCase()));
    const isFileTobig = fileList?.find((e) => e.size > 100 * 1024 * 1024);
    if (fileList.length > 10) {
      uploadRef.current.value = '';
      return showError(t('fileToMany'));
    }

    if (fileType.includes(false)) {
      uploadRef.current.value = '';
      return showError(t('fileToSmall'));
    }

    if (isFileTobig) {
      uploadRef.current.value = '';
      return showError(t('fileToLarge', { size: 100 }));
    }
    const isFileToSmall = fileList?.find((e) => e.size <= 0);

    if (isFileToSmall) {
      uploadRef.current.value = '';
      return showError(t('fileToSmall', { size: 0 }));
    }

    try {
      let jsonKey: any = {};
      for (let i = 0; i < fileList.length; i++) {
        jsonKey[fileList[i].name] = {
          file: fileList[i].file,
          type:
            getFileType(fileList[i].name) === 'heic' || getFileType(fileList[i].name) === 'HEIC'
              ? 'heic'
              : fileList[i].type,
        };
      }

      const payload = {
        organization_id: data?.org_id?._id || '',
        files: [...fileList].map((eel) => eel.name),
      };

      setLoading(true);
      const Urls = await postUrlUpload(payload);

      // re-map file with url upload
      const dataRemap = (Urls?.data || []).map((ele) => {
        return {
          url: ele.url,
          ...jsonKey[ele.original_name],
          fileKey: ele.fileKey,
          original_name: ele.original_name,
        };
      });
      // nProgress.start();

      Promise.all(
        dataRemap.map((item: any) => {
          const config = {
            headers: {
              'Content-Type': item.type,
              'Access-Control-Allow-Origin': '*',
            },
          };

          return axios.put(item.url, item.file, config);
        })
      )
        .then(() => {
          uploadRef.current.value = '';
          const dataUpload = {
            incident_id: incident_id,
            files: dataRemap.map((e) => {
              return {
                fileKey: e.fileKey,
                original_name: e.original_name,
              };
            }),
          };
          return loadingUpdateDetailIncident(dataUpload);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };
  const onPreview = (file: any) => {
    // const fileType = ['ptx', 'doc', 'docx', 'lsx', 'xlsx', 'eic', 'mov'];

    // const isHavePreview: boolean = fileType.includes(file?.keyImage?.toLocaleLowerCase());
    // if (isHavePreview) return;
    if (checkFileView(file)) return;
    const data = { files: [file?.fileKey], isDownload: false };
    apiPreview(data)
      .then((res) => {
        window.open(res.data[0].url);
      })
      .catch((err) => {
        showError('Error');
      });
  };

  const onDownload = (file: any) => {
    const data: IQueryDownloadImage = {
      files: [file?.fileKey],
      is_download: true,
    };
    apiDownloadImage(data)
      .then((res) => {
        window.open(res.data[0].url);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let newFiles: any[] = [];
  let dragCounter = 0;

  React.useEffect(() => {
    if (Array.isArray(data?.files)) {
      data?.files.forEach((file: any) => {
        const nameFile = file?.original_name;
        const keyImage = nameFile.slice(nameFile.length - 3, nameFile.length);
        newFiles.push({
          ...file,
          uid: file?._id,
          name: file?.original_name,
          status: 'done',
          keyImage: keyImage,
        });
      });
      setFileUpload(newFiles);
      setIndexFile(newFiles.length);
    }

    if (dropRef?.current) {
      dropRef.current.addEventListener('dragenter', handleDragIn);
      dropRef.current.addEventListener('dragleave', handleDragOut);
      dropRef.current.addEventListener('dragover', handleDrag);
      dropRef.current.addEventListener('drop', handleDrop);
      dropRef.current.addEventListener('dragleave', handleDragOut);
    }

    return () => {
      if (dropRef?.current) {
        dropRef.current.removeEventListener('dragenter', handleDragIn);
        dropRef.current.removeEventListener('dragleave', handleDragOut);
        dropRef.current.removeEventListener('dragover', handleDrag);
        dropRef.current.removeEventListener('drop', handleDrop);
      }
    };
  }, [data]);

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };

  const handleDragOut = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter--;
    if (dragCounter === 0) {
      setDragging(false);
    }
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    let arrayKey: any = [];
    Object.keys(e.dataTransfer.files).forEach(function (key) {
      arrayKey = arrayKey.concat(e.dataTransfer.files[key]);
    });
    handleUploadFile(arrayKey);
    e.dataTransfer.clearData();
    dragCounter = 0;
  };
  const handleClickUpload = React.useCallback(() => uploadRef.current?.click?.(), []);

  const checkFileView = (file: any) => {
    const fileType = ['ptx', 'doc', 'docx', 'lsx', 'xlsx', 'eic', 'mov'];

    const isHavePreview: boolean = fileType.includes(file?.keyImage?.toLocaleLowerCase());
    return isHavePreview;
  };
  const renderIconPreView = (file: any) => {
    if (checkFileView(file)) return;

    return (
      <img
        alt=""
        src="/images/ic-open-in-new.png"
        className="ic-cancel"
        onClick={() => onPreview(file)}
      />
    );
  };

  const renderAnswer = (answer: any) => {
    if (Array.isArray(answer)) {
      return (
        <>
          {answer.map((a) => (
            <>
              <Typography.Text className="question-text">{a}</Typography.Text>
              <br />
            </>
          ))}
        </>
      );
    }
    return (
      <Typography.Text className="question-text">
        {answer || t('incident.export.there_is_not_answer')}
      </Typography.Text>
    );
  };

  const renderIconFileType = (fileKey) => {
    let text = '';
    text = fileKey?.split('.').pop().toUpperCase();

    return (
      <div className="wrap-fileType">
        <span>{text}</span>
      </div>
    );
  };

  return (
    <Spin spinning={loading} size="small">
      <div className={`files-drag `} ref={dropRef} draggable={false}>
        <Form className="forms" initialValues={{ files: [] }} form={form}>
          <Form.Item className="item-list-question">
            <div className="detail-list">
              <div className="top-list">
                <Typography.Title className="list-title">
                  {t(`incidents.detail.listDetail.header.title`)}
                </Typography.Title>
                {data?.active_user_id?.device_user_id && (
                  <Space
                    align="center"
                    onClick={showModalOtherIncident}
                    className="space-other-incident"
                    size={0}
                  >
                    <img alt="" src="/images/ic-open.png" />
                    <Typography.Text className="list-text">
                      {t(`incidents.detail.listDetail.header.text`)}
                    </Typography.Text>
                  </Space>
                )}
              </div>
              <div className="detail-question-list">
                <Typography.Title className="detail-question-title">
                  {t(`incidents.detail.listDetail.Question.title`)}
                </Typography.Title>
                <div className="container-question">
                  <Space size={8} className="space" direction="vertical">
                    <Typography.Text
                      className="detail-question-text"
                      style={{ whiteSpace: 'break-spaces', width: '100%' }}
                    >
                      {data?.description}
                    </Typography.Text>
                    {Array.isArray(data?.questions) &&
                      data?.questions.map((item: any, index: number) => {
                        return (
                          <div className="list-question" key={index}>
                            {item?.type !== 'CHECKBOX' && (
                              <Typography.Title className="question-title">
                                {`${item?.name} ( ${t(
                                  `default.question.type.${item?.type?.toLocaleLowerCase?.()}`
                                )} )`}
                              </Typography.Title>
                            )}
                            {item?.type === 'CHECKBOX' ? (
                              <Checkbox
                                defaultChecked={item?.answer ? true : false}
                                disabled
                                className="checkbox-view"
                              >
                                {' '}
                                {`${item?.name} ( ${t(
                                  `default.question.type.${item?.type?.toLocaleLowerCase?.()}`
                                )} )`}
                              </Checkbox>
                            ) : (
                              renderAnswer(item?.answer)
                            )}
                          </div>
                        );
                      })}
                  </Space>
                  {indexFile > 0 && (
                    <div className="space-file-upload">
                      <Typography.Title className="upload-attachment-title">
                        {t('incidents.detail.attachment.title', { number: indexFile })}
                      </Typography.Title>

                      <div className="container-file">
                        {fileUpload?.map((file: any, index: number) => {
                          return (
                            <Space className="space-file" key={index}>
                              {renderIconFileType(file.fileKey)}
                              <div className="upload-info">
                                <Space className="space-upload__detail">
                                  <Tooltip
                                    placement="topLeft"
                                    title={decodeURIComponent(file.name)}
                                  >
                                    <Typography.Text
                                      key={file.uid}
                                      className={`file-name ${file.status}`}
                                      onClick={() => onPreview(file)}
                                      ellipsis
                                    >
                                      {decodeURIComponent(file.name)}
                                    </Typography.Text>
                                  </Tooltip>

                                  {renderIconPreView(file)}
                                </Space>
                                <Typography.Text key={file.uid} className="time-upload">
                                  {moment(file?.createdAt).format('YYYY/MM/DD')}
                                </Typography.Text>
                              </div>
                              <img
                                alt=""
                                src="/images/ic-cloud-download.png"
                                className="ic-download"
                                onClick={() => onDownload(file)}
                              />
                            </Space>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Form.Item>

          {data?.status?.code !== EStatus.CLOSED && currentRole !== 'RO' && (
            <div className={`${dragging ? 'files-placeholder' : ''} upload-incidents`}>
              <img id="img" alt="" src="/images/ic-upload.png" className="img-upload" />
              <Trans
                i18nKey="common.browse2"
                components={{
                  blue: (
                    <span
                      onClick={handleClickUpload}
                      style={{
                        display: 'inline-block',
                        color: 'blue',
                        textDecoration: 'underline',
                      }}
                    />
                  ),
                }}
              />

              <input
                onChange={(event: any) => handleUploadFile(event.target.files)}
                multiple
                type="file"
                style={{ display: 'none' }}
                ref={uploadRef}
              />
            </div>
          )}

          <OtherIncident
            isVisible={isVisibleIncidentUser}
            onCancel={handleCancelIncidentUser}
            deviceUserId={data?.active_user_id?.device_user_id}
          />
        </Form>
      </div>
    </Spin>
  );
};

export default React.memo(ListIncidentDetail);

export interface IFormIncidentQsProps {
  isVisible: boolean;
  onCancel: () => void;
  isLoading: boolean;
  isDisabled: boolean;
  onFinish: (values: any) => void;
  formRef: any;
  selectedItem: any;
  selectedOrg: any;
  loadingQuestions: () => void;
}

export interface IDeleteIncidentQsProps {
  isVisible: boolean;
  onCancel: () => void;
  isLoading: boolean;
  onDelete: () => void;
}

export interface IIncidentQuestionSelector {
  isLoading: boolean;
  questions: any[];
}

// List incident question api
export interface IQueryLoadingIncidentQs {
  organization_id?: string;
}
export interface IPayloadLoadingIncidentQs {
  query: IQueryLoadingIncidentQs;
  onSuccess?: (data: any) => void;
  onError?: (errorCode: string) => void;
}
export interface IActionLoadingIncidentQs {
  type: string;
  payload: IPayloadLoadingIncidentQs;
}

// Drag incident question api
export interface IDragIncidentQuestionFormValues {
  organization_id: string;
  orders: string[];
}
export interface IPayloadDragIncidentQuestion {
  values: IDragIncidentQuestionFormValues;
  onSuccess?: () => void;
  onError?: (errorCode: string) => void;
}
export interface IActionDragIncidentQuestion {
  type: string;
  payload: IPayloadDragIncidentQuestion;
}

// CURD Question api
export enum EQuestionType {
  TEXTBOX = 'TEXTBOX',
  DROPDOWN = 'DROPDOWN',
  CHECKBOX = 'CHECKBOX',
  CHECKBOXLIST = 'CHECKBOXLIST',
  DATE = 'DATE',
  EMAIL = 'EMAIL',
  RATING = 'RATING',
}
export enum EActionIncidentQuestion {
  ADD = 'ADD',
  UPDATE = 'UPDATE',
  REMOVE = 'REMOVE',
}
export interface ICreateIncidentQuestionFormValues {
  organization_id: string;
  action: EActionIncidentQuestion.ADD;
  question_name: string;
  answer_value: string | string[];
  question_type: EQuestionType;
  visibility: boolean;
  is_required: boolean;
  editable: boolean;
}
export interface IEditIncidentQuestionFormValues {
  organization_id: string;
  action: EActionIncidentQuestion.UPDATE;
  id: string;
  question_name: string;
  answer_value: string | string[];
  question_type: EQuestionType;
  visibility: boolean;
  is_required: boolean;
  editable: boolean;
}
export interface IDeleteIncidentQuestionFormValues {
  organization_id: string;
  action: EActionIncidentQuestion.REMOVE;
  id: string;
  question_name: string;
  answer_value: string | string[];
  question_type: EQuestionType;
  visibility: boolean;
  is_required: boolean;
  editable: boolean;
  question_detail: any;
}

export interface IPayloadCURDIncidentQuestion {
  onSuccess?: () => void;
  onError?: (errorCode: string) => void;
}

// Create
export interface IPayloadCreateIncidentQuestion extends IPayloadCURDIncidentQuestion {
  values: ICreateIncidentQuestionFormValues;
}
export interface IActionCreateIncidentQuestion {
  type: string;
  payload: IPayloadCreateIncidentQuestion;
}

// Edit
export interface IPayloadEditIncidentQuestion extends IPayloadCURDIncidentQuestion {
  values: IEditIncidentQuestionFormValues;
}
export interface IActionEditIncidentQuestion {
  type: string;
  payload: IPayloadEditIncidentQuestion;
}

// Delete
export interface IPayloadDeleteIncidentQuestion extends IPayloadCURDIncidentQuestion {
  values: IDeleteIncidentQuestionFormValues;
}
export interface IActionDeleteIncidentQuestion {
  type: string;
  payload: IPayloadDeleteIncidentQuestion;
}

// change visibility
export interface IChangeVisibilityFormValues {
  question_id: string;
  status: boolean;
}
export interface IPayloadChangeVisibilityIncidentQuestion {
  values: IChangeVisibilityFormValues;
  onSuccess?: () => void;
  onError?: (errorCode: string) => void;
}
export interface IActionChangeVisibilityIncidentQuestion {
  type: string;
  payload: IPayloadChangeVisibilityIncidentQuestion;
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { AA, OS, OA } from 'utils';
import { useAppSelector } from 'app/hooks';
import InputName from './InputName';
import CopyAccessCode from './CopyAccessCode';
import { ETypeOrganization } from 'pages/setting/interfaces';
import { get } from 'lodash';
import ModalConfirm from 'components/ModalConfirmV2';
import './tree-component.scss';

function makeid(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

interface IProps {
  isExpand: boolean;
  rootOrgData: any;
  showDetail: (item: any) => void;
  handleVisibleFormCreate: (item: any) => void;
  handleClickSwap: (item: any) => void;
  selectedTree: (listSelected: any[]) => void;
  handleChangeNameOG: (value: any) => void;
  handleStructure: (ids: any, parent_id: string) => void;
  handleVisibleUnHide: (item: any) => void;
}

let targetDrag: any = undefined;

const TreeComponent = ({
  isExpand,
  rootOrgData,
  showDetail,
  handleVisibleFormCreate,
  handleClickSwap,
  selectedTree,
  handleChangeNameOG,
  handleStructure,
  handleVisibleUnHide,
}: IProps) => {
  const { t } = useTranslation();

  const [rootOrg, setRootOrg] = useState(rootOrgData);
  const userRole: string = useAppSelector((state) => state.auth.role);
  const isAAOS: boolean = React.useMemo(() => [AA, OS].includes(userRole), [userRole]);
  const isOA: boolean = React.useMemo(() => userRole === OA, [userRole]);
  const [event, setEvent] = useState<string>('');
  const [org, setOrg] = useState<any>(null);
  const [toIndex, setToIndex] = useState(null);
  const [openModal, setVisible] = useState<boolean>(false);

  const remapOpenOrg = (org, orgSelected?: any, isOpen?: boolean) => {
    if (orgSelected) {
      if (org._id === orgSelected._id) {
        org.isOpen = !org.isOpen;
      }
    } else {
      org.isOpen = isOpen;
    }

    if (org.childrens && org.childrens.length > 0) {
      org.childrens.forEach((ele) => {
        if (orgSelected) {
          if (ele._id === orgSelected._id) {
            ele.isOpen = !ele.isOpen;
          } else {
            remapOpenOrg(ele, orgSelected);
          }
        } else {
          ele.isOpen = isOpen;
        }
      });
    }
    setRootOrg({ ...org });
  };

  useEffect(() => {
    remapOpenOrg(rootOrg, null, isExpand);
  }, [isExpand]);

  useEffect(() => {
    setRootOrg(rootOrgData);
    setTimeout(() => {
      remapOpenOrg(rootOrgData, null, isExpand);
    });
  }, [rootOrgData]);

  const renderChild = (org) => {
    return (
      org.isOpen &&
      (org.childrens || []).map((ele, index) => {
        return (
          <div key={`${ele._id}-${makeid(10)}`}>
            {renderInfoOrg(ele, index)}
            {renderChild(ele)}
          </div>
        );
      })
    );
  };

  const buildKeyOrg = (org) => {
    if (!org.path) return { ...org, key: org._id, title: org.name };
    const key = org.path.split('/').filter((ele) => ele);
    key.push(org._id);
    return { ...org, key: key.join('-'), title: org.name };
  };

  const handleCheckBoxTree = (orgCheckbox, level) => {
    // level 2 or 3
    if (level === 2) {
      rootOrg.childrens.forEach((school) => {
        if (school._id === orgCheckbox._id) {
          school.checked = !school.checked;
          if (school.childrens && school.childrens.length > 0) {
            school.childrens.forEach((grade) => {
              if (grade.is_active) {
                grade.checked = school.checked;
              }
            });
          }
        }
      });
      setRootOrg({ ...rootOrg });
      selectedTree(getTreeSelected({ ...rootOrg }));
    }

    if (level === 3) {
      rootOrg.childrens.forEach((school) => {
        if (school.childrens && school.childrens.length > 0) {
          school.childrens.forEach((grade) => {
            if (grade._id === orgCheckbox._id) {
              grade.checked = !grade.checked;
            }
          });
        }
      });
      setRootOrg({ ...rootOrg });
      selectedTree(getTreeSelected({ ...rootOrg }));
    }
  };

  const getTreeSelected = (orgRoot) => {
    const selected: any[] = [];
    const loop = (childrens) => {
      childrens.forEach((e) => {
        if (e.checked) {
          selected.push(e);
        }
        if (e.childrens) {
          loop(e.childrens);
        }
      });
    };

    if (orgRoot.childrens) {
      loop(orgRoot.childrens);
    }
    return selected;
  };

  const showCheckbox = (org) => {
    const isRoot = !org.parent_id;
    if (isRoot || !org.is_active || isOA) return false;
    return true;
  };

  const renderLeftOrg = (org) => {
    const isRoot = !org.parent_id;
    const paths = org.path ? org.path.split('/') : [];
    if (org.is_active) {
      return (
        <React.Fragment>
          <div className="item">
            {org.childrens && org.childrens.length > 0 ? (
              org.isOpen ? (
                <i
                  className={`fa fa-chevron-down icon-open-${isRoot ? 'root' : 'child'}`}
                  onClick={() => remapOpenOrg(rootOrg, org)}
                />
              ) : (
                <i
                  className={`fa fa-chevron-right icon-open-${isRoot ? 'root' : 'child'}`}
                  onClick={() => remapOpenOrg(rootOrg, org)}
                />
              )
            ) : paths.length < 3 ? (
              <div style={{ width: 15 }} />
            ) : null}
          </div>
          {showCheckbox(org) && (
            <div className="item">
              <Checkbox
                checked={org.checked}
                onClick={() => handleCheckBoxTree(org, paths.length)}
              />
            </div>
          )}
          {isDraggable(org) ? (
            <div className="item draggable">
              <i className="fa fa-bars"></i>
            </div>
          ) : null}
          <InputName org={org} callback={handleChangeNameOG} />
        </React.Fragment>
      );
    }

    return (
      <div className="org-hidden">
        <div className="item">
          {org.childrens && org.childrens.length > 0 ? (
            org.isOpen ? (
              <i
                className={`fa fa-chevron-down icon-open-${isRoot ? 'root' : 'child'}`}
                onClick={() => remapOpenOrg(rootOrg, org)}
              />
            ) : (
              <i
                className={`fa fa-chevron-right icon-open-${isRoot ? 'root' : 'child'}`}
                onClick={() => remapOpenOrg(rootOrg, org)}
              />
            )
          ) : null}
        </div>
        <div>
          <div className={`${isRoot ? 'text-org-root' : 'text-org-name-trancate'}`}>
            {org?.name}
          </div>
          <div>
            <Typography.Text className="txt-hidden">
              {t('settingPage.ogSetup.hiddenOg')}
            </Typography.Text>
          </div>
        </div>
      </div>
    );
  };

  const handleOnDropV2 = () => {
    try {
      if (toIndex === null) return;
      if (!org || !org.is_active) return;
      const orgMove: any = JSON.parse(event);
      const pathOrgTargets = org.path.split('/') || [];
      const pathOrgMove = orgMove.path.split('/') || [];

      if (pathOrgMove.length === 3 && pathOrgTargets.length === 2) {
        if (org.type === 0) return;
        if (!orgMove.is_active) return;
        const children_ids = (org.childrens || []).map((ele) => ele._id);
        children_ids.unshift(orgMove._id);
        return handleStructure(children_ids, org._id);
      }
      if (pathOrgMove.length === 3 && pathOrgTargets.length === 3) {
        if (org.parent_id === orgMove.parent_id) {
          let shooleMoved: any = {};
          rootOrg?.childrens.forEach((shool) => {
            if (shool._id === orgMove.parent_id) {
              shooleMoved = shool;
            }
          });
          const ids = shooleMoved.childrens.map((ele) => ele._id);
          ids.splice(orgMove.from_index, 1);
          ids.splice(toIndex, 0, orgMove._id);
          return handleStructure(ids, orgMove.parent_id);
        }
        if (org.parent_id !== orgMove.parent_id) {
          let shooleMoved: any = {};
          rootOrg?.childrens.forEach((shool) => {
            if (shool._id === org.parent_id) {
              shooleMoved = shool;
            }
          });
          const ids = shooleMoved.childrens.map((ele) => ele._id);
          ids.splice(toIndex, 0, orgMove._id);
          return handleStructure(ids, org.parent_id);
        }
      }
      const children_ids = (rootOrg.childrens || []).map((ele) => ele._id);
      children_ids.splice(orgMove.from_index, 1);
      children_ids.splice(toIndex, 0, orgMove._id);
      return handleStructure(children_ids, rootOrg._id);
    } catch (error) {
      console.error('error move org in catch exception');
      console.error(error);
    }
  };

  const isDraggable = (org) => {
    const isRoot = !org.parent_id;
    if (isRoot || isOA) return false;
    if (!org.is_active) return false;
    return true;
  };

  const renderIconArchive = (org) => {
    return (
      <Tooltip title={t('settingPage.ogSetup.unhideOg.tooltip')}>
        <img
          onClick={() => handleVisibleUnHide(buildKeyOrg(org))}
          src="/images/ic-archive.png"
          className="ic-archive"
          alt=""
        />
      </Tooltip>
    );
  };

  const renderIconAdd = (org) => {
    // const isRoot = !org.parent_id;
    const isSingleLayer: boolean = org?.type === ETypeOrganization.SINGLE;

    if (isSingleLayer) return null;

    return (
      <Tooltip title={t('settingPage.ogSetup.createOg.tooltip')}>
        <img
          onClick={() => handleVisibleFormCreate(buildKeyOrg(org))}
          src={'/images/ic-add.png'}
          className="ic-create"
          alt=""
        />
      </Tooltip>
    );
  };

  const renderIconCreateAndArchive = (org) => {
    if (!org) return;
    const haveRole = org?.role?.length > 0;

    // lv1
    if (!org.parent_id) {
      if (isAAOS) {
        return renderIconAdd(org);
      }
    }
    const paths = org.path ? org.path.split('/') : [];

    // lv2
    if (paths.length === 2) {
      if (org?.is_active) {
        if ((isAAOS || haveRole) && org.type === ETypeOrganization.MULTIPLE) {
          return renderIconAdd(org);
        }
        return null;
      } else {
        if (isAAOS) {
          return renderIconArchive(org);
        }
      }
    }

    // lv3
    if (paths.length === 3) {
      if (!org?.is_active) {
        const idOrgParent = org.parent_id;
        const orgLv2 = get(rootOrgData, 'childrens', []).find((ele) => ele._id === idOrgParent);
        if (orgLv2) {
          if (orgLv2.is_active) {
            if (isAAOS) {
              return renderIconArchive(org);
            }
          }
        }
      }
    }
  };

  const openModelClick = useCallback((jsonStr, org, index) => {
    setEvent(jsonStr);
    setOrg(org);
    setToIndex(index);
    setVisible(true);
  }, []);

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, []);

  const renderInfoOrg = (org, index) => {
    if (!org) return null;

    const isRoot = !org.parent_id;
    const paths = org.path ? org.path.split('/') : [];
    const isGrade = paths.length === 3;
    const isActive: boolean = org?.is_active;
    const accessCode: string =
      Array.isArray(org?.access_codes) &&
      org.access_codes
        .filter((e: any) => e?.is_active)
        .map((e: any) => e?.code)
        .join(', ');

    const haveRole = org?.role?.length > 0;

    return (
      <div>
        <div
          className={`${
            isRoot ? 'tree-root' : `${org.is_active ? 'tree' : 'tree-hidden'} lv${paths.length}`
          }`}
        >
          <div
            id={org._id}
            className="wrap-tree"
            draggable={!(org?.is_editing === true) && isDraggable(org)}
            onDragStart={(event) => {
              targetDrag = event.currentTarget;
              event.dataTransfer.setData('itemDrag', JSON.stringify({ ...org, from_index: index }));
            }}
            onDragExit={() => {
              targetDrag = undefined;
            }}
            onDrop={(event) => {
              event.preventDefault();
              event.stopPropagation();
              // handleOnDropV2(event, org, index);
              const jsonString = event.dataTransfer.getData('itemDrag');
              openModelClick(jsonString, org, index);
              event.currentTarget.style.border = '';
            }}
            onDragOver={(event: any) => {
              event.preventDefault();
              event.stopPropagation();
              if (event.currentTarget.contains(targetDrag)) return;
              event.currentTarget.style.border = '2px dotted #002e5c';
            }}
            onDragLeave={(event) => {
              event.preventDefault();
              event.stopPropagation();
              event.currentTarget.style.border = '';
            }}
          >
            <div className="wrap-tree-left">{renderLeftOrg(org)}</div>
            <div className="wrap-tree-right">
              <div className="wrap-access-code">
                <Tooltip title={<CopyAccessCode text={accessCode} />}>
                  <Typography.Text className="txt" ellipsis={true}>
                    {accessCode}
                  </Typography.Text>
                </Tooltip>
              </div>
              <div className="wrap-action">
                <div>
                  {isGrade && isActive && isAAOS && (
                    <Tooltip title={t('settingPage.ogSetup.moveOg.tooltip')}>
                      <img
                        src="/images/ic-swap.png"
                        alt=""
                        className="ic-swap"
                        onClick={() => handleClickSwap(buildKeyOrg(org))}
                      />
                    </Tooltip>
                  )}
                </div>
                <div>
                  {(isAAOS || isGrade || (haveRole && !isRoot)) && (
                    <Tooltip title={t('settingPage.ogSetup.detailOg.tooltip')}>
                      <img
                        onClick={() => showDetail(buildKeyOrg(org))}
                        src={'/images/ic-file.png'}
                        className="ic-file"
                        alt=""
                      />
                    </Tooltip>
                  )}
                </div>

                <div className="wrap-add">{renderIconCreateAndArchive(org)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <ModalConfirm
        textConfirmBtn={t('settingPage.ogSetup.modal.title.button.move')}
        visible={!!openModal}
        handleCancel={handleCancel}
        textConfirm={t('settingPage.ogSetup.modal.content.move')}
        title={t('settingPage.ogSetup.modal.title.move')}
        handleOk={() => {
          setVisible(false);
          handleOnDropV2();
        }}
      />
      {renderInfoOrg(rootOrg, null)}
      {renderChild(rootOrg)}
    </div>
  );
};

export default TreeComponent;

/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Tooltip, Typography, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import DetailBroadcast from './DetailBroadcast';
import { useAppSelector, useMyOrganizationID } from 'app/hooks';
import { getBroadcastByOrgAction, updatePublicBroadcast } from 'actions/broadcast';
import ModalCreate from './ModalCreate';
import { formatSchedule, IBroadcastListProps } from '../interfaces';
import moment from 'moment';
import InfinityLoadTable from 'components/common/InfinityLoadTable';
import { useDispatch } from 'react-redux';
import { showError, showSuccess } from 'components/standby-notice';

const format = formatSchedule.FORMAT;

const BroadcastList = ({ isVisibleCreate, onCancelCreate }: IBroadcastListProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
  const [selectedItem, setSelectedItem] = React.useState<any>(null);
  const list: any[] = useAppSelector((state) => state.broadcasts.list);
  const [query, setQuery] = React.useState<any>(null);
  const [trigger, setTrigger] = React.useState(0);
  const myOrgId: string = useMyOrganizationID();

  React.useEffect(() => {
    const query = {
      organization_id: myOrgId,
    };
    setQuery(query);
  }, [myOrgId, trigger]);

  const triggerReloadList = () => {
    setTrigger((pre) => pre + 1);
  };

  const handleClickRow = (item: any) => {
    handleVisibleEdit();
    setSelectedItem(item);
  };

  const handleVisibleEdit = () => {
    setIsModalVisible(true);
  };

  const handleSave = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedItem(null);
  };

  const handlePublic = (status: boolean, broadcastId: string) => {
    const payload = {
      id: broadcastId,
      is_public: status,
      onError: (code) => {
        showError(t(`error.common.${code}`));
      },
      onSuccess: () => {
        showSuccess(
          t(`content.management.broadcast.modal.${status ? 'public' : 'unpublic'}_success`)
        );
      },
    };
    dispatch(updatePublicBroadcast(payload));
  };

  const columns: any[] = [
    {
      title: t('broadcast.list.subject'),
      dataIndex: 'subject',
      key: 'subject',
      width: 350,
      render: (subject: string, item: object) => {
        return (
          <Tooltip placement="bottomLeft" title={subject}>
            <div onClick={() => onClickCell(item)}>
              <Typography.Text ellipsis={{ tooltip: false, symbol: '...' }}>
                {subject}
              </Typography.Text>
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: t('broadcast.list.message'),
      dataIndex: 'message',
      key: 'message',
      width: 410,
      ellipsis: {
        showTitle: false,
      },
      render: (message: string) => {
        return (
          <Tooltip placement="bottomLeft" title={message}>
            <Typography.Text>{message}</Typography.Text>
          </Tooltip>
        );
      },
    },
    {
      title: t('broadcast.list.recreptients'),
      dataIndex: 'org_id',
      key: 'org_id',
      width: 190,
      ellipsis: {
        showTitle: false,
      },
      render: (org_id: any) => {
        return (
          <Tooltip placement="bottomLeft" title={org_id?.name}>
            <Typography.Text>{org_id?.name}</Typography.Text>
          </Tooltip>
        );
      },
    },
    {
      title: t('broadcast.list.public'),
      dataIndex: 'is_public',
      width: 190,
      align: 'center',
      render: (_: any, record: any) => {
        let disableSwitch = false;
        const now = moment(new Date(), 'YYYY/MM/DD HH:mm')
          .utc()
          .add(9, 'hours')
          .format('YYYY/MM/DD HH:mm');
        const scheduleMoment = moment(record.schedule, 'YYYY/MM/DD HH:mm');
        const nowMoment = moment(now, 'YYYY/MM/DD HH:mm');

        if (
          !record.is_public &&
          scheduleMoment.isBefore(nowMoment) &&
          record.status === 'SCHEDULED'
        ) {
          disableSwitch = true;
        }
        console.log('disableSwitch', disableSwitch);

        return (
          <Switch
            disabled={disableSwitch}
            checked={record?.is_public}
            size="small"
            className="public"
            onChange={(status: boolean) => handlePublic(status, record?._id)}
            onClick={(status, e) => {
              e.stopPropagation();
            }}
          />
        );
      },
    },
    {
      title: t('broadcast.list.createdDate'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 184,
      ellipsis: {
        showTitle: false,
      },
      render: (record: string) => {
        const text: string = moment(record).format(format);
        return (
          <Tooltip placement="bottomLeft" title={text}>
            {text}
          </Tooltip>
        );
      },
    },
    {
      title: t('broadcast.list.actionStatus'),
      dataIndex: 'schedule',
      key: 'schedule',
      width: 150,
      render: (schedule: string, record: any) => {
        if (record.status === 'SENT') return t('broadcast.list.status.sent');
        if (schedule) {
          return (
            <div className="schedule">
              <Typography.Text className="title-schedule">
                {t('broadcast.list.status.schedule')}
              </Typography.Text>
              <Typography.Text className="txt-schedule">{schedule}</Typography.Text>
            </div>
          );
        }
        return t('broadcast.list.status.sent');
      },
    },
  ];

  const onClickCell = (item: any) => {
    handleClickRow(item);
  };

  return (
    <div className="broadcast">
      <InfinityLoadTable
        dataSource={list}
        columns={columns}
        className="table-broadcast"
        // onRow={onRow}
        scroll={{ y: 'calc(100vh - 260px)' }}
        loadDataAction={getBroadcastByOrgAction}
        query={query}
        dataType={t('broadcast.title')}
      />

      <ModalCreate
        isVisible={isVisibleCreate}
        onCancel={onCancelCreate}
        loadingListBroadcast={triggerReloadList}
      />

      {/* Modal Detail Broadcast */}

      <DetailBroadcast
        isVisible={isModalVisible}
        handleSave={handleSave}
        handleCancel={handleCancel}
        selectedItem={selectedItem}
        loadingListBroadcast={triggerReloadList}
      />
    </div>
  );
};

export default React.memo(BroadcastList);

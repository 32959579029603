import React, { useState } from 'react';
import { Button, FormInstance } from 'antd';
import { ModalConfirm } from 'components/modal-confirm';
import { useAppSelector } from 'app/hooks';
import { useTranslation } from 'react-i18next';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

interface ButtonProps {
  className?: string;
  buttonTitle: string;
  keyName?: string;
  size?: SizeType;
  confirmText?: string;
  onClick: () => void;
  onValidate?: () => boolean;
  form?: FormInstance<any>;
  disabled?: boolean;
  isLoading?: boolean;
}

const ConfirmButton = (props: ButtonProps) => {
  const { className, buttonTitle, onClick, form, size, keyName, disabled, isLoading, onValidate } =
    props;
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const myOrg = useAppSelector((state) => state.users.selectedOrg);

  const handleClick = async () => {
    try {
      form && (await form.validateFields());
      if (onValidate && !onValidate()) {
        return;
      }
      if (!myOrg.childrens || !myOrg.childrens.length) {
        onClick && onClick();
        return;
      }
      setIsModalOpen(true);
    } catch (e) {
      onValidate && onValidate();
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleConfirm = () => {
    onClick && onClick();
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        type="primary"
        key={keyName}
        onClick={handleClick}
        size={size}
        className={className}
        disabled={disabled}
        loading={isLoading}
      >
        {buttonTitle}
      </Button>
      <ModalConfirm
        textConfirmBtn={buttonTitle}
        visible={isModalOpen}
        handleCancel={handleCancel}
        textConfirm={t('modal.confirmEdit.confirmText')}
        title={t('modal.confirmEdit.title')}
        handleOk={handleConfirm}
      />
    </>
  );
};

export default ConfirmButton;

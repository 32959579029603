import API from 'libs/axios';
import {
  ICheckAccessCodeValues,
  ICreateOrganizationFormValues,
  IMultiActionFormValues,
  IQueryListOrganization,
  IStructureFormValues,
  IUpdateOrganizationFormValues,
} from 'pages/setting/interfaces';
import { queryString } from 'utils';

const PATH: string = 'admin/organizations';

export const getOrganization = (query?: IQueryListOrganization) => {
  const params: IQueryListOrganization = {
    show_hidden: !!query?.show_hidden,
  };
  if (query?.name) params.name = query?.name;

  const qs: string = queryString(params);

  return API.get(`admin/users/organizations/list?${qs}`);
};
export const postCreateOrganization = (payload: ICreateOrganizationFormValues) =>
  API.post(`${PATH}/create`, payload);
export const postCheckAccessCode = (payload: ICheckAccessCodeValues) =>
  API.post(`${PATH}/access_code/action`, payload);
export const getDetailOrganization = (organization_id: string) =>
  API.get(`${PATH}/detail/${organization_id}`);
export const putUpdateOrganization = (payload: IUpdateOrganizationFormValues) =>
  API.put(`${PATH}/settings`, payload);
export const multiActionOrganization = (payload: IMultiActionFormValues) =>
  API.post(`${PATH}/action`, payload);
export const hiddenMultiOrganization = (payload: string[]) =>
  API.put(`${PATH}/hidden`, { orgIds: payload });
export const structureOrganization = (payload: IStructureFormValues) =>
  API.post(`${PATH}/structure`, payload);

/* eslint-disable react-hooks/exhaustive-deps */
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { SHOW_HIDDEN_PERMISSION_ORG } from 'actions/auth';
import { Card, Input, Switch, Typography, Tree, Space, Button, Form } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ETypeOrganization } from 'pages/setting/interfaces';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AA, OS, OA } from 'utils';
import trim from 'lodash/trim';
import { filterOrgsByName } from './helpers';

interface IOrganizationsProps {
  onSelected: (selectecItem: any) => void;
  isVisibleShowHidden?: boolean;
  isHideAmInCharge?: boolean;
  isDisabledAmInCharge?: boolean;
  isManage?: boolean;
  selectGradeOnly?: boolean;
}

const Organizations = ({
  onSelected,
  isVisibleShowHidden = true,
  isHideAmInCharge = false,
  isDisabledAmInCharge = false,
  isManage = false,
  selectGradeOnly = false,
}: IOrganizationsProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const role: string = useAppSelector((state) => state.auth.role);
  const showHidden: boolean = useAppSelector((state) => state.auth.showHidden);
  const permissionOrganizations: any[] = useAppSelector(
    (state) => state.auth.permissionOrganizations
  );

  const isAAOS: boolean = React.useMemo(() => [AA, OS].includes(role), [role]);
  const isOA: boolean = React.useMemo(() => role === OA, [role]);
  const [searchString, setSearchString] = React.useState<string>('');
  const [expandedKeys, setExpandedKeys] = React.useState<React.Key[]>([]);

  React.useEffect(() => {
    if (Array.isArray(permissionOrganizations)) {
      handleSetExpandKeys();
    }
  }, [permissionOrganizations]);

  const handleShowTreeData = () => {
    const newTreeData: any[] = permissionOrganizations.map((organization: any) => {
      let newSchools: any[] = [];
      const isRootRole = !!organization?.role?.[0]?.role_name;
      if (organization?.children?.length) {
        newSchools =
          showHidden && isVisibleShowHidden
            ? organization.children
            : organization.children.filter((school: any) => school?.is_active);

        if (isManage && isOA) {
          newSchools = newSchools.filter((school: any) => {
            const schoolRole: string = school?.role?.[0]?.role_name;
            const isGradeOA: boolean = school.children.some(
              (grade: any) => grade?.role?.[0]?.role_name === OA
            );
            return isGradeOA || schoolRole === OA;
          });
        }

        newSchools = newSchools.map((school: any) => {
          let newGrades: any = [];
          const isHiddenSchool: boolean = !school?.is_active;

          /**
           * School has role OA, will be show all grade of this school @isSchoolOA
           */

          const isSchoolOA: boolean = school?.role?.[0]?.role_name === OA;
          const isSchoolActive = isRootRole || school?.role?.[0]?.role_name;

          if (school.children?.length) {
            newGrades =
              showHidden && isVisibleShowHidden
                ? school.children
                : school.children.filter((grade: any) => grade?.is_active);

            if (isManage && isOA) {
              newGrades = newGrades.filter((grade: any) => {
                const gradeRole: string = grade?.role?.[0]?.role_name;
                return isSchoolOA || gradeRole === OA;
              });
            }

            newGrades = newGrades.map((grade: any) => {
              const isHiddenGrade: boolean = !grade?.is_active;

              return {
                ...grade,
                isGrade: true,
                title: (
                  <div className={`tree-header-grade ${isHiddenGrade && 'hidden'}`}>
                    <Space direction="vertical" size={0}>
                      <Typography.Text className="txt-grade">{grade?.title}</Typography.Text>
                      {isHiddenGrade && (
                        <Typography.Text className="txt-hidden">
                          {t('settingPage.ogSetup.hiddenOg')}
                        </Typography.Text>
                      )}
                    </Space>
                  </div>
                ),
              };
            });
          }

          const isSingleLayer: boolean = school?.type === ETypeOrganization.SINGLE;

          return {
            ...school,
            isSchool: true,
            title: (
              <div
                className={`tree-header-school ${isHiddenSchool && 'hidden'} ${
                  (!isSchoolActive || selectGradeOnly) && !isSingleLayer && 'is-role-grade'
                }`}
              >
                <Space direction="vertical" size={0}>
                  <Typography.Text className="txt-school">{school?.title}</Typography.Text>
                  {isHiddenSchool && (
                    <Typography.Text className="txt-hidden">
                      {t('settingPage.ogSetup.hiddenOg')}
                    </Typography.Text>
                  )}
                </Space>
              </div>
            ),
            children: newGrades,
            disabled: (!isSchoolActive || selectGradeOnly) && !isSingleLayer,
          };
        });
      }

      return {
        ...organization,
        isOrganization: true,
        title: (
          <div
            className={`tree-header-og ${!isRootRole && 'is-oa'} ${
              (selectGradeOnly || !isRootRole) && 'is-role-grade'
            }`}
          >
            <Typography.Text className="txt-og">{organization?.name}</Typography.Text>
          </div>
        ),
        children: newSchools,
        disabled: !isRootRole || selectGradeOnly,
      };
    });

    return newTreeData
      .map((tree) => filterOrgsByName(role, tree, trim(searchString.toLowerCase())))
      .filter(Boolean);
  };

  const memoTreeData: any[] = React.useMemo(
    () => handleShowTreeData(),
    [permissionOrganizations, showHidden, searchString]
  );
  const onSelect = (selectedKeys: React.Key[], info: any) => {
    onSelected(info.node);
  };

  const handleImCharge = () => {
    onSelected(null);
  };

  const handleSetExpandKeys = () => {
    let newExpandKeys: string[] = [];
    [...permissionOrganizations].forEach((item: any) => {
      newExpandKeys.push(item?.key);

      if (Array.isArray(item?.children)) {
        item.children.forEach((school: any) => {
          newExpandKeys.push(school?.key);

          if (Array.isArray(school?.children)) {
            school.children.forEach((grade: any) => {
              newExpandKeys.push(grade?.key);
            });
          }
        });
      }
    });

    setExpandedKeys(newExpandKeys);
  };

  const onExpand = (expandedKeysValue: React.Key[]) => {
    setExpandedKeys(expandedKeysValue);
  };

  const handleChangeSearch = (event: any) => {
    const { value } = event.target;
    setSearchString(value);
  };

  const handleShowHiddenOrg = (isShow: boolean) => {
    dispatch({ type: SHOW_HIDDEN_PERMISSION_ORG, payload: isShow });
  };

  return (
    <Card className="organizations">
      <Form form={form} initialValues={{ show_hidden: showHidden, search: '' }}>
        {isVisibleShowHidden && (
          <div className="show-hidden">
            <Typography.Text className="txt">{t('settingPage.ogSetup.showHidden')}</Typography.Text>
            <Form.Item name="show_hidden" noStyle valuePropName="checked">
              <Switch
                onChange={(value: boolean) => {
                  handleShowHiddenOrg(value);
                }}
                className="switch-hidden"
              />
            </Form.Item>
          </div>
        )}

        <div className="wrap-input">
          <Form.Item name="search" noStyle>
            <Input
              placeholder={t('settingPage.ogSetup.searchPlaceholder')}
              prefix={<SearchOutlined />}
              className="search-input"
              size="large"
              allowClear
              maxLength={256}
              onChange={handleChangeSearch}
            />
          </Form.Item>
        </div>
      </Form>
      {!isAAOS && !isHideAmInCharge && (
        <Button
          type="link"
          className="txt-oa"
          onClick={handleImCharge}
          disabled={isDisabledAmInCharge}
        >
          {t('settingPage.adminUser.allCharge')}
        </Button>
      )}

      {memoTreeData.length > 0 ? (
        <Tree
          switcherIcon={<DownOutlined />}
          className="tree-organizations"
          treeData={memoTreeData}
          onSelect={onSelect}
          expandedKeys={expandedKeys}
          onExpand={onExpand}
        />
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}>
          {t('no_data', { name: t('list.empty.organization') })}
        </div>
      )}
    </Card>
  );
};

export default React.memo(Organizations);

import API from 'libs/axios';
import {
  IChangeStatusUserFormValues,
  ICreateUserFormValues,
  IOptInsFormValues,
  IQueryGetDetailUser,
  IQueryGetOptInsUser,
  IQueryLoadingDeactiveUsers,
  IQueryLoadingUsers,
  IQueryUserRole,
  IUnlockUserFormValues,
  IUpdateUserFormValues,
} from 'pages/setting/interfaces';
import { queryString } from 'utils';

const PATH: string = 'admin/users';

export const getListUsers = (query: IQueryLoadingUsers) => {
  const params: IQueryLoadingUsers = {
    page_number: query?.page_number || 1,
  };

  if (query?.organization_id) {
    params.organization_id = query.organization_id;
  }

  if (query?.search) {
    params.search = query.search;
  }

  if (query?.show_hidden) {
    params.show_hidden = query.show_hidden;
  }
  const qs: string = queryString(params);

  return API.get(`${PATH}/list?${qs}`);
};

export const getUserRole = (query: IQueryUserRole) => {
  const params: any = {};

  Object.entries(query).forEach(([key, value]: any) => {
    if (value) params[key] = value;
  });

  const qs: string = queryString(params);

  return API.get(`${PATH}/permission_infor?${qs}`);
};

export const postCreateUser = (payload: ICreateUserFormValues) => API.post(PATH, payload);

export const postUpdateUser = (payload: IUpdateUserFormValues) =>
  API.post(`${PATH}/other_profile`, payload);

export const getDetailUser = (query: IQueryGetDetailUser) => {
  const qs: string = queryString(query);

  return API.get(`${PATH}/profile?${qs}`);
};

export const postChangeStatusUser = (payload: IChangeStatusUserFormValues) =>
  API.post(`${PATH}/change_status`, payload);

export const getOptInsUser = (query: IQueryGetOptInsUser) => {
  const qs: string = queryString(query);
  return API.get(`${PATH}/optin_infor?${qs}`);
};

export const postUpdateOptInsUser = (payload: IOptInsFormValues) =>
  API.post(`${PATH}/optin_infor`, payload);

export const postUnlockUser = (payload: IUnlockUserFormValues) =>
  API.post(`${PATH}/unblock`, payload);

/**
 * Deactive users
 */

export const getDeactiverUsers = (query: IQueryLoadingDeactiveUsers) => {
  const params: any = {};

  Object.entries(query).forEach(([key, value]: any) => {
    if (value) params[key] = value;
  });

  const qs: string = queryString(params);

  return API.get(`${PATH}/user-deactive?${qs}`);
};

export const putReactiveUser = (userIds: string[]) => API.put(`${PATH}/user-reactive`, { userIds });

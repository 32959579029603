import {
  FG_PASSWORD,
  FG_PASSWORD_ERROR,
  FG_PASSWORD_SUCCESS,
  LOADING_PROFILE,
  LOADING_PROFILE_SUCCESS,
  LOADING_USER_OPT_INS,
  LOADING_MY_ROLE,
  LOGGING_ERROR,
  LOGGING_IN,
  LOGGING_OUT,
  LOGGING_SUCCESS,
  LOGOUT_SUCCESS,
  NEW_PASSWORD,
  NEW_PASSWORD_ERROR,
  NEW_PASSWORD_SUCCESS,
  REFRESH_TOKEN,
  RESEND_CODE_TFA,
  UPDATE_MY_OPT_INS,
  UPDATE_PROFILE,
  VERIFY_TFA,
} from 'actions/auth';
import {
  LOADING_USER_ROLE,
  UPDATE_OPT_INS_USER_FAILED,
  UPDATE_OPT_INS_USER_SUCCESS,
  GET_CURRENT_USER_OPTIN_SUCCESS,
} from 'actions/users';
import { notification } from 'antd';
import {
  getUserOptIns,
  getUserProfile,
  getUserRole,
  postForgotPassword,
  postLogin,
  postNewPassword,
  postRefreshToken,
  postResendCodeTfa,
  postUpdateMyOptIns,
  putUpdateUserProfile,
} from 'api/auth';
import i18n from 'config/i18n';
import { history } from 'libs';
import NProgress from 'nprogress';
import {
  IActionForgotPassword,
  IActionGetOptIns,
  IActionGetProfile,
  IActionGetUserRole,
  IActionLogin,
  IActionRefreshToken,
  IActionResendCodeTfa,
  IActionUpdateMyOptIns,
  IActionUpdateUserProfile,
} from 'pages/auth/interfaces';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ADMIN_NOT_SAME_ORG_USER,
  OK,
  OS,
  storage,
  TARGET_USER_NOT_PERMISS,
  TFA,
  USER_NOT_SAME_ORG,
} from 'utils';
import { loadUserRole } from './users';

function* login({ payload }: IActionLogin) {
  try {
    const log = yield call(postLogin, payload.values);

    if (log?.status === OK) {
      const { data } = log;

      /**
       * @challenger_name is @TFA
       * go to screen Two Factor Authentication
       */

      if (data?.challenger_name === TFA) {
        yield put({ type: VERIFY_TFA });
        return history.push('/auth/two-factor', {
          ...data,
          ...payload.values,
        });
      }

      /**
       * For 1 way login
       */
      yield storage.setToken(data?.access_token);
      yield storage.setRefreshToken(data?.refresh_token);
      yield payload?.onSuccess?.();
      yield put({ type: LOGGING_SUCCESS });

      const role: string = yield loadUserRole({ type: LOADING_USER_ROLE, payload: { query: {} } });

      if (role === OS) {
        yield history.push('/admin/setting', { isFirstLogin: true });
      } else {
        yield history.push('/', { isFirstLogin: true });
      }
    } else {
      /**
       * Throw errow
       */
      yield put({ type: LOGGING_ERROR });
      if (payload?.onError) yield payload.onError(log?.error_code, log?.data);
    }
  } catch (error) {
    yield put({ type: LOGGING_ERROR });
  }
}

function* logout() {
  NProgress.start();
  yield put({ type: LOGOUT_SUCCESS });
  yield history.push('/auth/login');
  yield storage.clearToken();
  //  yield storage.clearRefreshToken();
  NProgress.done();
}

function* forgotPassword({ payload }: IActionForgotPassword) {
  try {
    const forgot = yield call(postForgotPassword, payload.values);

    if (forgot?.status === OK) {
      yield put({ type: FG_PASSWORD_SUCCESS });
      if (payload?.onSuccess) yield payload.onSuccess();
    } else {
      yield put({ type: FG_PASSWORD_ERROR });
      if (payload?.onError) yield payload.onError(forgot?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: FG_PASSWORD_ERROR });
  }
}

function* newPassword({ payload }: IActionForgotPassword) {
  try {
    const newPw = yield call(postNewPassword, payload.values);

    if (newPw?.status === OK) {
      yield put({ type: NEW_PASSWORD_SUCCESS });
      if (payload?.onSuccess) yield payload.onSuccess();
    } else {
      yield put({ type: NEW_PASSWORD_ERROR });
      if (payload?.onError) yield payload.onError(newPw?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: NEW_PASSWORD_ERROR });
  }
}

function* resendCodeTfa({ payload }: IActionResendCodeTfa) {
  try {
    const resend = yield call(postResendCodeTfa, payload);

    if (resend?.status === OK) {
      if (payload?.onSuccess) yield payload.onSuccess();
    } else {
      if (payload?.onError) yield payload.onError(resend?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
  }
}

function* refreshToken({ payload }: IActionRefreshToken) {
  try {
    yield storage.clearToken();

    const refresh = yield call(postRefreshToken, payload);

    if (refresh?.status === OK) {
      const { data } = refresh;
      yield storage.setToken(data?.access_token);
      //      yield storage.clearRefreshToken();
      yield window.location.reload();
    } else {
      return yield logout();
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    return yield logout();
  }
}

// PROFILE

function* getProfile({ payload }: IActionGetProfile) {
  try {
    const profile = yield call(getUserProfile);
    if (profile?.status === OK) {
      const { data: info } = profile;

      yield put({ type: LOADING_PROFILE_SUCCESS, payload: info });
      if (payload?.onSuccess) yield payload.onSuccess();
    } else {
      if (payload?.onError) yield payload.onError(profile?.error_code);
    }
  } catch (error) {
    console.log(`error`, error);
  }
}

function* getOtpIns({ payload }: IActionGetOptIns) {
  try {
    const optIns = yield call(getUserOptIns);
    if (optIns?.status === OK) {
      const { data } = optIns;
      // console.log(data);
      yield put({ type: GET_CURRENT_USER_OPTIN_SUCCESS });
      if (payload?.onSuccess) yield payload.onSuccess(data);
    } else {
      if (payload?.onError) yield payload.onError(optIns?.error_code);
    }
  } catch (error) {
    console.log(`error`, error);
  }
}

function* updateMyOptIns({ payload }: IActionUpdateMyOptIns) {
  try {
    const update = yield call(postUpdateMyOptIns, payload.values);

    if (update?.status === OK) {
      yield put({ type: UPDATE_OPT_INS_USER_SUCCESS });
      if (payload?.onSuccess) yield payload.onSuccess();
    } else {
      yield put({ type: UPDATE_OPT_INS_USER_FAILED });
      if (payload?.onError) yield payload.onError(update?.error_code);

      let message: string = i18n.t('error.common');

      switch (update?.error_code) {
        case USER_NOT_SAME_ORG:
          message = i18n.t('settingPage.adminUser.userNotSameOrg');
          break;
        case ADMIN_NOT_SAME_ORG_USER:
          message = i18n.t('settingPage.adminUser.adminNotSameOrg');
          break;
        case TARGET_USER_NOT_PERMISS:
          message = i18n.t('settingPage.adminUser.targetUserNotPermiss');
          break;
        default:
          break;
      }

      yield notification.error({ message });
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: UPDATE_OPT_INS_USER_FAILED });
    if (payload?.onError) yield payload.onError('');
  }
}

function* updateProfile({ payload }: IActionUpdateUserProfile) {
  try {
    const update = yield call(putUpdateUserProfile, payload.values);
    if (update?.status === OK) {
      if (payload?.onSuccess) yield payload.onSuccess();
    } else {
      if (payload?.onError) yield payload.onError(update?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
  }
}

function* getRole({ payload }: IActionGetUserRole) {
  try {
    const role = yield call(getUserRole);

    if (role?.status === OK) {
      if (payload?.onSuccess) yield payload.onSuccess(role?.data?.organizations);
    } else {
      if (payload?.onError) yield payload.onError(role?.error_code);
    }
  } catch (error) {
    console.log(`error`, error);
  }
}

export default function* loginSaga() {
  yield takeLatest(LOGGING_IN, login);
  yield takeLatest(LOGGING_OUT, logout);
  yield takeLatest(FG_PASSWORD, forgotPassword);
  yield takeLatest(NEW_PASSWORD, newPassword);
  yield takeLatest(RESEND_CODE_TFA, resendCodeTfa);
  yield takeLatest(REFRESH_TOKEN, refreshToken);
  yield takeLatest(LOADING_PROFILE, getProfile);
  yield takeLatest(UPDATE_PROFILE, updateProfile);
  yield takeLatest(LOADING_MY_ROLE, getRole);
  yield takeLatest(LOADING_USER_OPT_INS, getOtpIns);
  yield takeLatest(UPDATE_MY_OPT_INS, updateMyOptIns);
}

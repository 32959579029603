/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import {
  Layout,
  Typography,
  Image,
  Row,
  Col,
  Tabs,
  Menu,
  Dropdown,
  Spin,
  Space,
  Popover,
  Empty,
} from 'antd';
import './styles/detail-incidents.scss';
import ListIncidentDetail from './components/detail-incidents/ListDetail';
import FormIncidentDetail from './components/detail-incidents/FormDetail';
import Notes, { EStatus } from './components/detail-incidents/Notes';
import ShareIncidentDetail from './components/detail-incidents/ShareIncident';
import {
  EExportTypeIncident,
  EFilterResponseStatusIncidents,
  IPayloadActionUpdateDetailIncident,
  IPayLoadDeactiveUser,
  IPayloadGetActivityLog,
  IPayloadGetDetail,
  IResponseIncidentDetail,
  IPayloadLoadingChatIncidents,
} from './interfaces';
import { useDispatch } from 'react-redux';
import {
  loadingIncidentsDetailAction,
  updateDetailIncidentAction,
  postDeactiveUserAction,
  loadingActivityLogIncidentAction,
  loadingChatIncidentsAction,
} from 'actions/incidents';
import moment from 'moment';
import { useParams } from 'react-router';
import { FlagFilled, FlagOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { INCIDENT_ATTR_TYPES, IPayloadGetListIncidentAttrByOrg } from 'pages/setting/interfaces';
import { apiGetListIncidentAttrByOrg } from 'api/incident-attr';
import { showError, showSuccess } from 'components/standby-notice';
import Chat from './components/detail-incidents/Chat';
import { ModalConfirm } from 'components/modal-confirm';
import { useAppSelector } from 'app/hooks';
import ActivityLog from './components/detail-incidents/Log';
import { storage } from 'utils';
import { API_URL } from 'config';
import { useLocation } from 'react-router-dom';
import { get } from 'lodash';

const { Header, Content } = Layout;
// const initialBoundsChat: IBoundsChat = {
//   left: 0,
//   top: 0,
//   bottom: 0,
//   right: 0,
// };

interface IModal {
  typeModal: string;
  contentText: string;
  textConfirm: string;
  titleModal: string;
  isDeleted: boolean;
}

let refFormDetail: any = () => null;

const { TabPane } = Tabs;
const DetailIncidents = () => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const { id }: any = useParams();
  const { state }: any = useLocation();
  const ref: any = useRef(null);

  const defaultVisibleChat: boolean = !!state?.defaultVisibleChat;
  const indexMessageChat: number = state?.indexMessageChat;

  const currentLanguageCode: string = i18n.language;

  const [loading, setLoading] = useState<boolean>(false);
  const [infoModal, setInfoModal] = useState<IModal>();
  const [idChangeStatus, setIdChangeStatus] = useState<string>('');
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const [data, setData] = useState<IResponseIncidentDetail | null>(null);

  const [isVisibleShare, setIsVisibleShare] = useState(false);
  const [isVisiableConfirm, setIsVisiableConfirm] = useState<boolean>(false);
  const [optionsPriority, setOptionsPriority] = useState<any[]>();
  const [activeKeyTab, setActiveKeyTab] = useState<string>('1');
  const [listActiviLog, setListActiviLog] = useState<any[]>([]);

  /**
   * State for Chat
   */
  const isLoading: boolean = useAppSelector((state) => state.incidents.isLoading);
  const statusChats: string = useAppSelector((state) => state.incidents.statusChats);
  const chats: any[] = useAppSelector((state) => state.incidents.chats);
  const isVisibleNoticeDetail: boolean = useAppSelector(
    (state) => state.notifications.isVisibleNoticeDetail
  );
  const isVisibleSystemAlert: boolean = useAppSelector(
    (state) => state.notifications.isVisibleSystemAlert
  );

  const [isVisibleChat, setIsVisibleChat] = useState<boolean>(defaultVisibleChat);
  // const [isDiabledMoveChat, setIsDiabledMoveChat] = useState<boolean>(true);
  // const [boundsChat, setBoundsChat] = useState<IBoundsChat>(initialBoundsChat);
  const [currentRole, setCurrentRole] = React.useState('');
  const roles: any[] = useAppSelector((state) => state.auth.roles);

  // const draggleChatRef: any = React.createRef();

  React.useEffect(() => {
    if (isVisibleNoticeDetail || isVisibleSystemAlert) {
      /**
       * cancel chat when modal notice show
       */
      handleCancelChat();
    }
  }, [isVisibleNoticeDetail, isVisibleSystemAlert]);

  React.useEffect(() => {
    if (defaultVisibleChat && indexMessageChat && !data?.admin_id) {
      handleVisibleChat();
    }
  }, [defaultVisibleChat, indexMessageChat, id]);

  React.useEffect(() => {
    let currentIncidentRole = '';
    for (const role of roles) {
      if (
        get(data, 'org_id._id') === role.org_id ||
        get(data, 'org_id.path', '').includes(role.org_id)
      ) {
        currentIncidentRole = role.role_name;
        break;
      }
    }
    setCurrentRole(currentIncidentRole);
  }, [data?._id, roles]);

  const listShare: any[] = [
    {
      type: EExportTypeIncident.PDF_INCIDENT,
      title: t('incidents.detail.header.menuDownload.incidentPDF'),
    },
    {
      type: EExportTypeIncident.EXCEL_INCIDENT,
      title: t('incidents.detail.header.menuDownload.incidentExcel'),
    },
    {
      type: EExportTypeIncident.PDF_ACTIVITY_LOG,
      title: t('incidents.detail.header.menuDownload.activityLogPDF'),
    },
    {
      type: EExportTypeIncident.EXCEL_ACTIVITY_LOG,
      title: t('incidents.detail.header.menuDownload.activityLogExcel'),
    },
  ];

  const menuShare = (
    <Menu className="menu_list">
      {listShare.map((item: any, index: number) => {
        return (
          <Menu.Item className="menu_item" key={index} onClick={() => handelExportFile(item.type)}>
            {item.title}
          </Menu.Item>
        );
      })}
    </Menu>
  );
  const defaultItem = {
    code: null,
    color: '',
    name: t('incidents.detail.header.menuFlag.None'),
    _id: null,
  };

  const menuFlag = (
    <Menu className="menu_list flag">
      {optionsPriority && optionsPriority.length > 0 ? (
        [defaultItem].concat(optionsPriority).map((item, index) => {
          return (
            <Menu.Item className="menu_item" key={index} onClick={() => prorityChange(item)}>
              <Space size={12}>
                {item?.color ? <FlagFilled style={{ color: item?.color }} /> : <FlagOutlined />}
                {item?.name}
              </Space>
            </Menu.Item>
          );
        })
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('list.empty.flag')} />
      )}
    </Menu>
  );

  const handelExportFile = (type: EExportTypeIncident) => {
    switch (type) {
      case EExportTypeIncident.PDF_INCIDENT:
        getExportFilePDFIncidentDetail();
        break;
      case EExportTypeIncident.EXCEL_INCIDENT:
        getExportFileExcelIncidentDetail();
        break;
      case EExportTypeIncident.PDF_ACTIVITY_LOG:
        getExportFilePDFActivityLog();
        break;
      case EExportTypeIncident.EXCEL_ACTIVITY_LOG:
        getExportFileExcelActivityLog();
        break;
      default:
        break;
    }
  };

  const getExportFilePDFIncidentDetail = () => {
    const data = {
      incident_id: id,
      lang: currentLanguageCode,
      standby_token: storage.getToken(),
    };
    const query: string = new URLSearchParams(data).toString();
    const urlDownload: string = `${API_URL}/incident-export/pdf?${query}`;
    window.open(urlDownload);
  };

  const getExportFileExcelIncidentDetail = () => {
    const data = {
      incident_id: id,
      lang: currentLanguageCode,
      standby_token: storage.getToken(),
    };
    const query: string = new URLSearchParams(data).toString();
    const urlDownload: string = `${API_URL}/incident-export/excel?${query}`;
    window.open(urlDownload);
  };

  const getExportFilePDFActivityLog = () => {
    const data = {
      incident_id: id,
      lang: currentLanguageCode,
      standby_token: storage.getToken(),
    };
    const query: string = new URLSearchParams(data).toString();
    const urlDownload: string = `${API_URL}/incident-export/activity-log/pdf?${query}`;
    window.open(urlDownload);
  };

  const getExportFileExcelActivityLog = () => {
    const data = {
      incident_id: id,
      lang: currentLanguageCode,
      standby_token: storage.getToken(),
    };
    const query: string = new URLSearchParams(data).toString();
    const urlDownload: string = `${API_URL}/incident-export/activity-log/excel?${query}`;
    window.open(urlDownload);
  };

  const getActivityLog = () => {
    const payload: IPayloadGetActivityLog = {
      query: {
        incident_id: id,
      },
      onSuccess: (data) => {
        if (Array.isArray(data)) {
          setListActiviLog(data);
        }
      },
      onError: () => {},
    };

    return dispatch(loadingActivityLogIncidentAction(payload));
  };

  const getIncidentDetail = () => {
    setLoading(true);
    const payload: IPayloadGetDetail = {
      query: {
        incident_id: id,
      },
      onSuccess: (data) => {
        setLoading(false);
        setData(data);
      },
      onError: () => {
        setLoading(false);
      },
    };

    return dispatch(loadingIncidentsDetailAction(payload));
  };

  React.useEffect(() => {
    if (!data?.org_id?._id) return;
    loadingPriorityByOrg(data?.org_id?._id);
  }, [data?._id]);

  const loadingPriorityByOrg = React.useCallback(async (id) => {
    const query: IPayloadGetListIncidentAttrByOrg['query'] = {
      organization_id: id,
      type: INCIDENT_ATTR_TYPES.PRIORITY,
    };

    const getListType = await apiGetListIncidentAttrByOrg(query);

    setOptionsPriority(getListType?.data?.values || []);
  }, []);

  const loadingChats = (id) => {
    const payload: IPayloadLoadingChatIncidents = {
      incident_id: id,
    };

    return dispatch(loadingChatIncidentsAction(payload));
  };

  useEffect(() => {
    getIncidentDetail();
    loadingChats(id);
  }, [id]);

  const loadingUpdateDetailIncident = (data, key) => {
    if (data?.happened_at) {
      data = { ...data, happened_at: moment(data.happened_at).format('YYYY/MM/DD') };
    }
    const payload: IPayloadActionUpdateDetailIncident = {
      data,
      onSuccess: () => {
        showSuccess(t(`incidents.detail.update.${key.toLowerCase()}.success`));
        getIncidentDetail();
        getActivityLog();
        setIsVisiableConfirm(false);
      },
      onError: () => {
        showError(t(`error.common`));
        setIsVisiableConfirm(false);
      },
    };
    dispatch(updateDetailIncidentAction(payload));
  };

  const postDeactiveUser = React.useCallback(
    async (id: string) => {
      const payload: IPayLoadDeactiveUser = {
        active_user_id: data?.active_user_id?._id,
        incident_id: id,
        onSuccess: () => {
          showSuccess(t('incidents.detail.blockUser.success'));
          getIncidentDetail();
          getActivityLog();
          setIsVisiableConfirm(false);
        },
        onError: (errorCode: string) => {
          showError(t(`error`));
          console.log('🚀 ~ errorCode', errorCode);
        },
      };

      return dispatch(postDeactiveUserAction(payload));
    },
    [data]
  );

  const prorityChange = (e) => {
    const prorityUpdate = {
      incident_id: id,
      priority: e._id ? e._id : null,
    };
    loadingUpdateDetailIncident(prorityUpdate, 'PRIORITY');
  };

  const handleVisibleShare = () => {
    setIsVisibleShare(true);
    handleCancelChat();
  };

  const onCancelShareIncident = () => {
    setIsVisibleShare(false);
    ref.current!?.resetFields();
  };

  const handleChangeTab = (activeKey: string) => {
    if (activeKey === 'Chat') {
      return handleVisibleChat();
    }
    setActiveKeyTab(activeKey);
  };

  /**
   * Function for Chat
   */

  const handleVisibleChat = () => {
    setIsVisibleChat(true);
  };

  const handleCancelChat = () => {
    setIsVisibleChat(false);
  };

  // const handleDisabledMoveChat = (value: boolean) => {
  //   setIsDiabledMoveChat(value);
  // };

  // const onStartDragChat = (event: any, uiData: any) => {
  //   const { clientWidth, clientHeight } = window?.document?.documentElement;
  //   const targetRect = draggleChatRef?.current?.getBoundingClientRect();

  //   const newBoundsChat: IBoundsChat = {
  //     left: -targetRect?.left + uiData?.x,
  //     right: clientWidth - (targetRect?.right - uiData?.x),
  //     top: -targetRect?.top + uiData?.y,
  //     bottom: clientHeight - (targetRect?.bottom - uiData?.y),
  //   };

  //   setBoundsChat(newBoundsChat);
  // };

  const renderIconChat = () => {
    let src: string = '';
    switch (statusChats || data?.response_status) {
      case EFilterResponseStatusIncidents.READ_ANSWERED:
        src = '/images/ic-read-anwered.png';
        break;
      case EFilterResponseStatusIncidents.READ_UNANSWERED:
        src = '/images/ic-read-unanwered.png';
        break;
      default:
        src = '/images/ic-unread.png';
        break;
    }

    return <img alt="" src={src} />;
  };

  const onClickChangeStatusChat = () => {
    if (data?.status?.code === EStatus.CLOSED) {
      setIsDisable(true);
    }
  };
  const renderTabChat = () => {
    return (
      <Space size={10} onClick={onClickChangeStatusChat}>
        {renderIconChat()}
        <Typography.Text>{`${t('incidents.detail.chat')} (${chats.length})`}</Typography.Text>
      </Space>
    );
  };

  // Deactive user
  const handleShowDeactive = () => {
    handleCancelChat();
    setInfoModal({
      typeModal: 'deactive',
      titleModal: t('incidents.detail.header.deactive.title'),
      contentText: t('incidents.detail.header.deactive.content.text'),
      textConfirm: t('incidents.detail.header.deactive.button.deactive'),
      isDeleted: true,
    });
    setIsVisiableConfirm(true);
  };

  // Closed Status
  const handleShowCloseStatus = (key: number, id: string) => {
    if (key === EStatus.CLOSED) {
      setIdChangeStatus(id);
      setInfoModal({
        typeModal: 'closed',
        titleModal: t('incidents.detail.formDetail.Modal.closed.title'),
        contentText: t('incidents.detail.formDetail.Modal.closed.text'),
        textConfirm: t('incidents.detail.formDetail.Modal.closed.closed'),
        isDeleted: true,
      });
    } else {
      setIdChangeStatus(id);
      setInfoModal({
        typeModal: 'change',
        titleModal: t('incidents.detail.formDetail.Modal.change.title'),
        contentText: t('incidents.detail.formDetail.Modal.change.conttext'),
        textConfirm: t('incidents.detail.formDetail.Modal.change.changeStatus'),
        isDeleted: false,
      });
    }
    setIsVisiableConfirm(true);
  };

  const handleUpdate = () => {
    if (infoModal?.typeModal !== 'deactive') {
      const statusUpdate = {
        incident_id: id,
        status: idChangeStatus,
      };
      loadingUpdateDetailIncident(statusUpdate, 'STATUS');
    } else {
      if (data?.active_user_id?._id) {
        postDeactiveUser(id);
      }
    }
  };

  const handleCancel = () => {
    refFormDetail?.();
    setIsVisiableConfirm(false);
  };

  const renderFlag = () => {
    const color: string | undefined =
      optionsPriority &&
      data?.priority?.name !== 'NORMAL' &&
      optionsPriority.find((e) => e.name === data?.priority?.name)?.color;
    if (!color) return <FlagOutlined title={t('incident.pdf.question.admin.flag')}> </FlagOutlined>;
    return <FlagFilled title={t('incident.pdf.question.admin.flag')} style={{ color }} />;
  };
  return (
    <Spin spinning={loading} size="small">
      <div className="detail-incidents">
        <Header className="header-incidents">
          <div className="header-info">
            <div className="info">
              <Typography.Title className="info-title title-left">
                {t('incidents.detail.header.infoTitleRight', { number: data?.sequence })}
              </Typography.Title>
              <Typography.Title
                className="info-title info-org-name "
                ellipsis={{ tooltip: true, rows: 1, expandable: false, symbol: '...' }}
              >
                {data?.org_id?.name}
              </Typography.Title>
            </div>
            <div className="info">
              <Typography.Text className="info-text text-left _bottom">
                {t('incidents.detail.header.viaPlatform', {
                  platform: t(
                    `platform.type${
                      data?.active_user_id?.platform ? data?.active_user_id?.platform : ''
                    }`
                  ),
                })}
              </Typography.Text>
              <Typography.Text className="info-text text-left _bottom">
                {data && moment(data.createdAt).format('YYYY/MM/DD HH:mm')}
              </Typography.Text>
            </div>
          </div>
          <div
            className="header-box-center"
            // style={{
            //   lineHeight: 'initial',
            //   display: 'flex',
            //   flexDirection: 'column',
            //   width: '100%',
            //   fontSize: 12,
            //   color: '#ff9500'
            // }}
          >
            {(data?.status?.code === EStatus.CLOSED ||
              (data?.active_user_id && !data?.active_user_id?.is_active) ||
              (data?.active_user_id && data?.active_user_id?.is_logged_out)) && (
              <>
                {data?.status?.code === EStatus.CLOSED && (
                  <Typography.Text className="text-close">
                    {t(`incidents.detail.listDetail.header.text.closed`)}
                  </Typography.Text>
                )}
                {data?.active_user_id && !data?.active_user_id?.is_active && (
                  <Typography.Text className="text-close">
                    {t(`incidents.detail.listDetail.header.text.deactived_user`)}
                  </Typography.Text>
                )}
                {data?.active_user_id && data?.active_user_id?.is_logged_out && (
                  <Typography.Text className="text-close">
                    {t(`incidents.detail.listDetail.header.text.logout_user`)}
                  </Typography.Text>
                )}
                {/* <Typography.Text className="text-closed">
                  {data?.status?.code === EStatus.CLOSED &&
                    t(`incidents.detail.listDetail.header.text.closed`)}
                  {data?.active_user_id &&
                    !data?.active_user_id?.is_active &&
                    t(`incidents.detail.listDetail.header.text.deactived_user`)}
                  {data?.active_user_id &&
                    data?.active_user_id?.is_logged_out &&
                    t(`incidents.detail.listDetail.header.text.logout_user`)}
                </Typography.Text> */}
              </>
            )}
          </div>
          <div className="header-list">
            {data?.active_user_id && data?.active_user_id?.is_active && currentRole !== 'RO' && (
              <Image
                preview={false}
                src="/images/ic-stop.png"
                title={t('common.blockUser')}
                onClick={handleShowDeactive}
              />
            )}
            <Dropdown
              overlay={menuShare}
              trigger={['click']}
              overlayClassName="dropdown-menu-export"
            >
              <Image title={t('common.export')} preview={false} src="/images/ic-download.png" />
            </Dropdown>
            {currentRole !== 'RO' && (
              <Image
                preview={false}
                src="/images/ic-share.png"
                title={t('incidents.detail.share.title')}
                onClick={handleVisibleShare}
              />
            )}
            <Dropdown
              disabled={currentRole === 'RO' ? true : false}
              overlay={menuFlag}
              trigger={['click']}
              overlayClassName="dropdown-menu-priority"
            >
              {renderFlag()}
            </Dropdown>
          </div>
        </Header>
        <Content className="container">
          <Row className="row">
            <Col span={12} className="col-left">
              <Tabs activeKey={activeKeyTab} className="tabs" onChange={handleChangeTab}>
                <TabPane
                  className="tab-pane"
                  tab={
                    <Space size={12}>
                      {t('incidents.detail.tabs.DETAIL')}
                      <Popover
                        placement="bottomRight"
                        content={
                          data?.active_user_id &&
                          !data?.active_user_id?.is_active &&
                          t('incidents.detail.formDetail.Modal.Block.deactiveuser')
                        }
                        trigger="hover"
                        arrowPointAtCenter
                        className="popover-deactive"
                        color="#525673"
                      >
                        {data?.active_user_id && !data?.active_user_id?.is_active && (
                          <img alt="" src="/images/ic-disabled-user.png" />
                        )}
                      </Popover>
                      <Popover
                        placement="bottomRight"
                        content={
                          data?.active_user_id &&
                          data?.active_user_id?.is_logged_out &&
                          t('incidents.detail.formDetail.Modal.Block.logoutUser')
                        }
                        trigger="hover"
                        arrowPointAtCenter
                        className="popover-deactive"
                        color="#525673"
                      >
                        {data?.active_user_id && data?.active_user_id?.is_logged_out && (
                          <img alt="" src="/images/people.png" width="16" height="16" />
                        )}
                      </Popover>
                    </Space>
                  }
                  key="1"
                >
                  <ListIncidentDetail
                    data={data}
                    getActivityLog={getActivityLog}
                    getIncidentDetail={getIncidentDetail}
                    handleCancelChat={handleCancelChat}
                    currentRole={currentRole}
                  />
                </TabPane>
                <TabPane className="tab-pane" tab={t('incidents.detail.tabs.NOTES')} key="2">
                  <Notes data={data} currentRole={currentRole} />
                </TabPane>
                <TabPane className="tab-pane" tab={t('incidents.detail.tabs.ACTIVITY_LOG')} key="3">
                  <ActivityLog
                    isLoading={isLoading}
                    listActiviLog={listActiviLog}
                    getActivityLog={getActivityLog}
                  />
                </TabPane>
                {!data?.admin_id && (
                  <TabPane tab={renderTabChat()} key="Chat">
                    <div>{t('incidents.detail.chat')}</div>
                  </TabPane>
                )}
              </Tabs>
            </Col>
            <Col span={12} className="col-right">
              <FormIncidentDetail
                onRef={(ref) => (refFormDetail = ref)}
                data={data}
                id={id}
                handleShowCloseStatus={handleShowCloseStatus}
                getActivityLog={getActivityLog}
                getIncidentDetail={getIncidentDetail}
                currentRole={currentRole}
              />
            </Col>
          </Row>
        </Content>
      </div>

      {/* Modal Share */}
      <ShareIncidentDetail ref={ref} isVisible={isVisibleShare} onCancel={onCancelShareIncident} />

      {/* Modal Chat */}
      <Chat
        isDisable={isDisable}
        isVisible={isVisibleChat}
        onCancel={handleCancelChat}
        data={data}
        defaultVisibleChat={defaultVisibleChat}
        currentRole={currentRole}
      />

      <ModalConfirm
        isDeleted={infoModal?.isDeleted}
        title={infoModal?.titleModal}
        visible={isVisiableConfirm}
        handleOk={handleUpdate}
        handleCancel={handleCancel}
        contentText={infoModal?.contentText}
        textConfirmBtn={infoModal?.textConfirm}
      />
    </Spin>
  );
};

export default React.memo(DetailIncidents);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Table } from 'antd';
import { useAppDispatch } from 'app/hooks';
import { useTranslation } from 'react-i18next';

interface TableProps {
  columns: any[];
  dataSource: any[];
  query: Record<string, any>;
  loadDataAction: any;
  onRow?: (item, index) => any;
  className?: string;
  scroll: any;
  loadDatacallback?: () => void;
  isLoading?: boolean;
  expandable?: any;
  rowSelection?: any;
  dataType: string;
  onchangeSorter?: (sorter: any) => void;
}

const InfinityLoadTable = (props: TableProps) => {
  const {
    columns,
    dataSource,
    query,
    loadDataAction,
    onRow,
    className,
    scroll,
    loadDatacallback,
    expandable,
    rowSelection,
    dataType,
    // isLoading,
    onchangeSorter,
  } = props;

  const { t } = useTranslation();
  const [page, setPage] = useState<number>(1);
  const hasNextPage = useRef<boolean>(true);
  const currentPage = useRef<number>(0);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const onLoadScroll = useCallback(() => {
    const tableContent: any = document.querySelector('.ant-table-body');
    if (tableContent) {
      tableContent.addEventListener('scroll', (event: any) => {
        const maxScroll: number = event.target.scrollHeight - event.target.clientHeight;
        const currentScroll: number = event.target.scrollTop;
        if (maxScroll - currentScroll <= 20 && maxScroll !== 0 && !loading && hasNextPage.current) {
          setPage(currentPage.current + 1);
        }
      });
    }
  }, [loading]);

  const onLoadSuccess = useCallback(() => {
    if (!loading) {
      const tableContent: any = document.querySelector('.ant-table-body');
      if (
        tableContent &&
        tableContent.scrollHeight === tableContent.clientHeight &&
        hasNextPage.current
      ) {
        setPage(currentPage.current + 1);
      }
    }
  }, [loading]);

  useEffect(() => {
    if (page > 1) {
      loadData(page);
    }
  }, [page]);

  useEffect(() => {
    onLoadScroll();
  }, []);

  useEffect(() => {
    if (query) {
      loadData(1);
      setPage(1);
    }
  }, [query]);

  const loadData = useCallback(
    (pageNumber = 1) => {
      const payload = {
        query: {
          page_number: pageNumber,
          page_size: 20,
          ...query,
        },
        onSuccess: (data: any) => {
          if (currentPage.current !== data?.pagination.page) {
            currentPage.current = data?.pagination.page;
          }
          hasNextPage.current = data?.pagination.hasNextPage;
          onLoadSuccess();
          setLoading(false);
          loadDatacallback && loadDatacallback();
        },
        onError: (errorCode: string) => {
          setLoading(false);
        },
      };
      setLoading(true);
      return dispatch(loadDataAction(payload));
    },
    [query]
  );

  const onChange = (pagination, filters, sorter, extra) => {
    onchangeSorter && onchangeSorter(sorter);
  };

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      expandable={expandable}
      rowSelection={rowSelection}
      className={className}
      onRow={onRow}
      scroll={scroll}
      loading={loading && (!dataSource.length || page === 1)}
      pagination={false}
      rowKey="_id"
      locale={{ emptyText: t('no_data', { name: dataType }) }}
      onChange={onChange}
    />
  );
};

export default React.memo(InfinityLoadTable);

/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Breadcrumb, Button, Dropdown, Layout, Typography } from 'antd';
import './broadcasts.scss';
import { useTranslation } from 'react-i18next';
import BroadcastList from './components/BroadcastList';
import { Organizations } from 'components/common';
import {
  ESelectOrganizationValues,
  IPayloadSelectedOrganizationID,
} from 'pages/setting/interfaces';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectedOrgAction, selectedOrgIDAction } from 'actions/users';
import { AA, OS, RO } from 'utils';
import { isEmpty } from 'lodash';

const { Header, Content } = Layout;

const Broadcasts = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [isVisibleCreate, setIsVisibleCreate] = React.useState(false);
  const [selectedOrg, setSelectedOrg] = React.useState<any>(null);
  const [isVisibleSelectOrg, setIsVisibleSelectOrg] = React.useState<boolean>(false);
  const role: string = useAppSelector((state) => state.auth.role);
  const permissionOrganizations: any[] = useAppSelector(
    (state) => state.auth.permissionOrganizations
  );
  const isAAOS: boolean = React.useMemo(() => [AA, OS].includes(role), [role]);
  const isRO: boolean = React.useMemo(() => role === RO, [role]);
  const myOrg = useAppSelector((state) => state.users.selectedOrg);

  React.useEffect(() => {
    initSelectOrganization();
  }, [permissionOrganizations]);

  const initSelectOrganization = React.useCallback(() => {
    /**
     * Loading Org first
     * check @isOA - loading first org of OA
     */
    if (!isEmpty(myOrg.name)) {
      onSelectedOrganization(myOrg);
      return;
    }
    if (isEmpty(myOrg.name)) {
      onSelectedOrganization('');
      return;
    }
  }, [permissionOrganizations]);

  const handleVisibleCreate = () => {
    setIsVisibleCreate(true);
  };

  const handleCancelCreate = () => {
    setIsVisibleCreate(false);
  };

  const handleVisibleOrg = (visible: boolean) => {
    setIsVisibleSelectOrg(visible);
  };

  const onSelectedOrganization = React.useCallback((selectedItem: any) => {
    handleVisibleOrg(false);
    const payload: IPayloadSelectedOrganizationID = {
      selectedOrgId: selectedItem?._id || ESelectOrganizationValues.ALL_IN_CHARGE,
    };
    if (isEmpty(selectedItem)) {
      setSelectedOrg(selectedItem);
      dispatch(selectedOrgIDAction(payload));
      dispatch(
        selectedOrgAction({
          selectedOrg: { name: '' },
        })
      );
      return;
    } else {
      const { props, title, access_codes, ...newOptionOrg } = selectedItem;
      setSelectedOrg(newOptionOrg);
      const payloadOrgAction: any = {
        selectedOrg: newOptionOrg || ESelectOrganizationValues.ALL_IN_CHARGE,
      };
      dispatch(selectedOrgAction(payloadOrgAction));
      dispatch(selectedOrgIDAction(payload));
    }
    return;
  }, []);

  return (
    <div className="broadcast">
      <Layout className="layout-broadcast">
        <Header className="header-broadcast">
          <div className="header-item">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <Typography.Text className="txt-broadcast">{''}</Typography.Text>
              </Breadcrumb.Item>
            </Breadcrumb>
            <div className="broadcast-action">
              {!isRO && (
                <Button
                  className="btn-add-broadcast"
                  type="primary"
                  size="large"
                  onClick={handleVisibleCreate}
                >
                  {t('broadcast.list.newBroadcast')}
                </Button>
              )}

              <Dropdown
                overlay={
                  <Organizations onSelected={onSelectedOrganization} isVisibleShowHidden={true} />
                }
                trigger={['click']}
                overlayClassName="dropdown-organizations"
                onVisibleChange={handleVisibleOrg}
                visible={isVisibleSelectOrg}
              >
                <Button
                  size="large"
                  className={`btn-account-og ${!isAAOS && 'is-oa'}`}
                  icon={
                    <span className={`icon-right ${!isAAOS && 'is-oa'}`}>
                      <i className="fa fa-chevron-down" />
                    </span>
                  }
                >
                  {selectedOrg
                    ? selectedOrg?.name
                    : !isAAOS
                    ? t('settingPage.adminUser.allCharge')
                    : permissionOrganizations?.[0]?.name}
                </Button>
              </Dropdown>
            </div>
          </div>
        </Header>
        <Content className="content-broadcast">
          <BroadcastList isVisibleCreate={isVisibleCreate} onCancelCreate={handleCancelCreate} />
        </Content>
      </Layout>
    </div>
  );
};

export default React.memo(Broadcasts);

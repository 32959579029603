/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo } from 'react';
import { DownOutlined, PlusOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import {
  changeSearchUsersAction,
  createUserAction,
  loadingUsersAction,
  selectedOrgIDAction,
  unlockUserAction,
  updateOptInsUserAction,
  updateUserAction,
} from 'actions/users';
import { Button, Divider, Input, Space, Tag, Tooltip, Typography } from 'antd';
import { useAppDispatch, useAppSelector, useReadOnly } from 'app/hooks';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';
import groupBy from 'lodash/groupBy';
import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';
import trim from 'lodash/trim';
import { useTranslation } from 'react-i18next';
import {
  EOptIns,
  ICallbackInitCheckRole,
  ICheckOptIns,
  ICheckRoles,
  ICreateUserFormValues,
  IHandleIdOrg,
  IPayloadCreateUser,
  IPayloadUpdateOptInsUser,
  IPayloadSearchUsers,
  IPayloadUpdateUser,
  IRolesFormUser,
  IAllSchool,
  IListUsersResponse,
  IPayloadUnlockUser,
  ESelectOrganizationValues,
} from '../../interfaces';
import FormUser from './components/FormUser';
import { AA, OA, OS, RO } from 'utils';
import OptIns from './components/OptIns';
import UnLockUser from './components/UnLockUser';
import { showSuccess } from 'components/standby-notice';
import { shallowEqual } from 'react-redux';
import InfinityLoadTable from 'components/common/InfinityLoadTable';

const AdminUserSetup = () => {
  const { t } = useTranslation();
  const childRef: any = React.useRef();

  const users: IListUsersResponse = useAppSelector((state) => state.users.users, shallowEqual);
  const search: string = useAppSelector((state) => state.users.search);
  const selectedOrgId: string = useAppSelector((state) => state.users.selectedOrgId);
  const role: string = useAppSelector((state) => state.auth.role);
  const showHidden: boolean = useAppSelector((state) => state.auth.showHidden);
  const permissionOrganizations: any[] = useAppSelector(
    (state) => state.auth.permissionOrganizations
  );

  const isOA: boolean = React.useMemo(() => role === OA, [role]);
  const isJustView: boolean = useReadOnly();

  const [isVisibleForm, setIsVisibleForm] = React.useState<boolean>(false);
  const [isVisibleOptIns, setIsVisibleOptIns] = React.useState<boolean>(false);
  const [checkRoles, setCheckRoles] = React.useState<ICheckRoles[]>([]);
  const [checkOptIns, setCheckOptIns] = React.useState<ICheckOptIns[]>([]);
  const [organizations, setOrganizations] = React.useState<any[]>([]);
  const [selectedItem, setSelectedItem] = React.useState<any>(null);
  const [query, setQuery] = React.useState<any>(null);
  const [trigger, setTrigger] = React.useState(0);
  const [isVisibleUnLock, setIsVisibleUnLock] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const formUserRef: any = React.createRef();
  const rootOrgId: string = React.useMemo(() => organizations?.[0]?._id, [organizations]);

  React.useEffect(() => {
    const query = {
      organization_id:
        selectedOrgId && selectedOrgId !== ESelectOrganizationValues.ALL_IN_CHARGE
          ? selectedOrgId
          : '',
      search,
    };
    setQuery(query);
  }, [selectedOrgId, search, showHidden, trigger]);

  const triggerReloadList = () => {
    setTrigger((pre) => pre + 1);
  };

  const columns: any[] = [
    {
      title: t('settingPage.adminUser.username'),
      dataIndex: 'user_name',
      key: 'user_name',
      ellipsis: {
        showTitle: false,
      },
      render: (_: any, record: any) => {
        const fullName: string = `${record?.last_name || ''} ${record?.first_name || ''}`;
        return (
          <Tooltip placement="bottomLeft" title={fullName}>
            <Space>
              <Typography.Text className={!record?.is_active ? 'txt-inactive' : ''}>
                {fullName.length > 20 ? fullName.slice(0, 20) + '...' : fullName}
              </Typography.Text>
              {record?.isBlock && (
                <img
                  src="/images/ic-lock-user.png"
                  alt=""
                  width="18"
                  height="18"
                  onClick={() => handleVisibleUnlock(record)}
                  className="ic-lock"
                />
              )}
            </Space>
          </Tooltip>
        );
      },
    },
    {
      title: t('settingPage.adminUser.email'),
      dataIndex: 'email',
      key: 'email',
      ellipsis: {
        showTitle: false,
      },
      render: (_: any, record: any) => (
        <Tooltip placement="bottomLeft" title={record?.email}>
          <Typography.Text className={!record?.is_active ? 'txt-inactive' : ''}>
            {record?.email}
          </Typography.Text>
        </Tooltip>
      ),
    },
    {
      title: t('settingPage.adminUser.roleAttributes'),
      dataIndex: 'role',
      key: 'role',
      render: (_: any, record: any) => {
        if (!record?.is_active) return t('settingPage.adminUser.status.deactive');

        if (Array.isArray(record?.role)) {
          return record.role.map((role: any) => (
            <Tooltip title={role?.org_id?.name} key={role?._id} placement="bottomLeft">
              <Tag className="role-tag">{role?.role_name}</Tag>
            </Tooltip>
          ));
        }

        return null;
      },
    },
    {
      title: () => {
        if (isJustView) return null;

        return (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            className="btn-new-user"
            onClick={handleVisibleForm}
          >
            {t('settingPage.adminUser.newUser')}
          </Button>
        );
      },
      dataIndex: 'action',
      key: 'action',
      align: 'right',
      render: (_: any, record: any) => {
        if (isJustView) return null;

        const isUserOS: boolean =
          Array.isArray(record?.role) && record.role.some((role: any) => role?.role_name === OS);

        const isUserAA: boolean =
          Array.isArray(record?.role) && record.role.some((role: any) => role?.role_name === AA);

        const isRootRO: boolean =
          record?.role?.[0]?.role_name === RO && record?.role?.[0]?.org_id?._id === rootOrgId;

        if (isOA && (isUserAA || isUserOS)) return null;

        if (isOA && isRootRO) return null;

        return (
          <Space split={<Divider type="vertical" />}>
            {!isUserOS && (
              <Typography.Link
                className="txt-action"
                disabled={!record?.is_active || !record?.role?.length}
                onClick={() => handleClickOptIns(record)}
              >
                {t('settingPage.adminUser.optIn')}
              </Typography.Link>
            )}

            <Typography.Link className="txt-action" onClick={() => handleClickEdit(record)}>
              {t('settingPage.adminUser.edit')}
            </Typography.Link>
          </Space>
        );
      },
      width: 175,
    },
  ];

  React.useEffect(() => {
    const newTreeData: any[] = permissionOrganizations.map((organization: any) => {
      let newSchools: any[] = [];
      if (organization?.children?.length) {
        newSchools = showHidden
          ? organization.children
          : organization.children.filter((school: any) => school?.is_active);

        if (isOA) {
          newSchools = newSchools.filter((school: any) => {
            const schoolRole: string = school?.role?.[0]?.role_name;
            const isGradeOA: boolean = school.children.some(
              (grade: any) => grade?.role?.[0]?.role_name === OA
            );
            return isGradeOA || schoolRole === OA;
          });
        }

        newSchools = newSchools.map((school: any) => {
          let newGrades: any = [];
          const isSchoolOA: boolean = school?.role?.[0]?.role_name === OA;
          if (school.children?.length) {
            newGrades = showHidden
              ? school.children
              : school.children.filter((grade: any) => grade?.is_active);
            if (isOA) {
              newGrades = newGrades.filter((grade: any) => {
                const gradeRole: string = grade?.role?.[0]?.role_name;
                return isSchoolOA || gradeRole === OA;
              });
            }
          }
          return {
            ...school,
            children: newGrades,
          };
        });
      }
      return {
        ...organization,
        children: newSchools,
      };
    });
    setOrganizations(newTreeData);
  }, [permissionOrganizations, showHidden]);

  React.useEffect(() => {
    return () => {
      dispatch(selectedOrgIDAction({ selectedOrgId: '' }));
      dispatch(changeSearchUsersAction({ keyword: '' }));
    };
  }, []);

  const handleVisibleUnlock = (item: any) => {
    setIsVisibleUnLock(true);
    setSelectedItem(item);
  };

  const handleCancelUnlock = () => {
    setIsVisibleUnLock(false);
    setSelectedItem(null);
  };

  const onUnLock = () => {
    const payload: IPayloadUnlockUser = {
      values: {
        user_id: selectedItem?._id,
      },
      onSuccess: () => {
        // loadingUsers(page, selectedOrgId, search);
        handleCancelUnlock();
        showSuccess(t('settingPage.adminUser.unlockSuccess'));
      },
      onError: (errorCode: string) => {
        console.log('🚀 ~ errorCode', errorCode);
      },
    };

    return dispatch(unlockUserAction(payload));
  };

  const handleChangeSeachUsers = (event: any) => {
    const { value } = event.target;
    const valueSearch: string = trim(value) || '';
    const payload: IPayloadSearchUsers = {
      keyword: valueSearch,
      onSuccess: () => {},
    };

    return dispatch(changeSearchUsersAction(payload));
  };

  const debounceSearchUser = debounce(handleChangeSeachUsers, 800);

  const handleVisibleForm = () => {
    setIsVisibleForm(true);
  };

  const handleCancelFormUser = () => {
    setIsVisibleForm(false);
    setSelectedItem(null);
    setCheckRoles([]);
  };

  const handleVisibleOptIns = () => {
    setIsVisibleOptIns(true);
  };

  const handleCancelOptIns = () => {
    setIsVisibleOptIns(false);
    setSelectedItem(null);
    setCheckOptIns([]);
  };

  const onFinishOptIns = () => {
    const { allSchool }: IHandleIdOrg = handleIdOrg;
    const newCheckOptIns: ICheckOptIns[] = [...checkOptIns];

    const idsIM: string[] = [];
    const idsIS: string[] = [];
    const idsMM: string[] = [];
    const idsMS: string[] = [];

    const groupByType: any = groupBy(newCheckOptIns, 'type');

    const pushToCase = (type: EOptIns, id: string) => {
      switch (type) {
        case EOptIns.INCIDENT_MAIL:
          idsIM.push(id);
          break;
        case EOptIns.INCIDENT_SMS:
          idsIS.push(id);
          break;
        case EOptIns.MESSAGE_MAIL:
          idsMM.push(id);
          break;
        case EOptIns.MESSAGE_SMS:
          idsMS.push(id);
          break;
        default:
          break;
      }
    };

    if (Object.keys(groupByType).length > 0) {
      Object.entries(groupByType).forEach((keyValue: any) => {
        const type: EOptIns = keyValue[0];
        const checkOptInsOfType: ICheckOptIns[] = keyValue[1];

        /**
         * Check root to be check
         */

        const isCheckRoot: boolean = checkOptInsOfType.some(
          (item: ICheckOptIns) => item.organization_id === rootOrgId && item.type === type
        );

        if (isCheckRoot) {
          pushToCase(type, rootOrgId);
        } else {
          /**
           * School to be checked
           */

          allSchool.forEach((school: IAllSchool) => {
            const indexSchool: number = checkOptInsOfType.findIndex(
              (item: ICheckOptIns) =>
                school?._id === item.organization_id && item.type === type && !item.disabled
            );

            if (indexSchool >= 0) {
              pushToCase(type, school._id);
            } else {
              /**
               * Grade to be checked
               */
              if (Array.isArray(school?.children)) {
                school.children.forEach((gradeId: string) => {
                  const indexGrade: number = checkOptInsOfType.findIndex(
                    (item: ICheckOptIns) =>
                      gradeId === item.organization_id && item.type === type && !item.disabled
                  );

                  if (indexGrade >= 0) {
                    pushToCase(type, gradeId);
                  }
                });
              }
            }
          });
        }
      });
    }

    const payload: IPayloadUpdateOptInsUser = {
      values: {
        admin_user_id: selectedItem?._id,
        optin_data: [
          {
            type: EOptIns.INCIDENT_MAIL,
            organization_ids: idsIM,
          },
          {
            type: EOptIns.INCIDENT_SMS,
            organization_ids: idsIS,
          },
          {
            type: EOptIns.MESSAGE_MAIL,
            organization_ids: idsMM,
          },
          {
            type: EOptIns.MESSAGE_SMS,
            organization_ids: idsMS,
          },
        ],
      },
      onSuccess: () => handleCancelOptIns(),
    };

    return dispatch(updateOptInsUserAction(payload));
  };

  const onFinish = ({
    role,
    is_active,
    first_name,
    last_name,
    role_oa,
    ...values
  }: ICreateUserFormValues) => {
    const { allSchool }: IHandleIdOrg = handleIdOrg;
    const isEdited: boolean = !!selectedItem;

    const newCheckRoles: ICheckRoles[] = [...checkRoles];

    const isRoleAA: boolean = role === AA;
    const isRoleOS: boolean = role === OS;

    const isCheckRootRO: boolean = newCheckRoles.some(
      (item: ICheckRoles) => item.organization_id === rootOrgId && item.role_name === RO
    );
    const newRoles: IRolesFormUser[] = [];

    if (isCheckRootRO && !isRoleOS && !isRoleAA) {
      /**
       * Check root RO
       * Check limit true or false
       */

      const findItemRootRO: ICheckRoles | undefined = newCheckRoles.find(
        (item: ICheckRoles) => item?.organization_id === rootOrgId && item?.role_name === RO
      );

      newRoles.push({
        organization_id: rootOrgId,
        role_name: RO,
        limited: !!findItemRootRO?.limited,
      });
    }

    if (isRoleOS) {
      /**
       * Select Assign Organization Setting
       */
      newRoles.push({ organization_id: rootOrgId, role_name: OS, limited: false });
    }

    if (!isRoleOS && !isCheckRootRO) {
      if (isRoleAA) {
        newRoles.push({ organization_id: rootOrgId, role_name: AA, limited: false });
      } else {
        /**
         * Not role AA and check free role in organization
         * Check case select only school
         */
        allSchool.forEach((school: IAllSchool) => {
          const indexSchool: number = newCheckRoles.findIndex(
            (item: ICheckRoles) => school?._id === item.organization_id
          );

          if (indexSchool >= 0) {
            // Item school has been exist in @newCheckRoles
            const { disabled, ...itemSchool }: ICheckRoles = newCheckRoles[indexSchool];

            newRoles.push(itemSchool);
          } else {
            /**
             * School is not check, and now check grade to be selected
             */

            if (Array.isArray(school?.children)) {
              school.children.forEach((gradeId: string) => {
                const indexGrade: number = newCheckRoles.findIndex(
                  (item: ICheckRoles) => gradeId === item.organization_id
                );

                if (indexGrade >= 0) {
                  // Item grade has been exist in @newCheckRoles
                  const { disabled, ...itemGrade }: ICheckRoles = newCheckRoles[indexGrade];

                  newRoles.push(itemGrade);
                }
              });
            }
          }
        });
      }
    }

    const uniqRole: IRolesFormUser[] = uniqBy(newRoles, 'organization_id');

    const payload: IPayloadCreateUser | IPayloadUpdateUser = {
      values: {
        ...values,
        first_name: trim(first_name),
        last_name: trim(last_name),
        roles: uniqRole,
      },
      onSuccess: () => {
        handleCancelFormUser();
        triggerReloadList();
      },
      onError: (errorCode: string) => {
        childRef?.current?.onFormError?.(errorCode);
      },
    };

    if (isEdited) {
      payload.values.user_id = selectedItem?._id || '';
      payload.values.show_hidden = showHidden;
      return dispatch(updateUserAction(payload));
    }

    return dispatch(createUserAction(payload));
  };

  /**
   * Handle Check Logic Role Of Form User
   */

  const handleInitCheckRoles = (
    roleUserData: any[],
    cb: (cbValue: ICallbackInitCheckRole) => void
  ) => {
    const initChecked: ICheckRoles[] = [];
    let isSelectRoleOS: boolean = false;
    let isSelectRoleAA: boolean = false;

    if (Array.isArray(roleUserData)) {
      roleUserData.forEach((organization: any) => {
        if (Array.isArray(organization?.role)) {
          organization.role.forEach((roleOrg: any) => {
            // check role name is OS
            if (roleOrg?.role_name === AA) {
              isSelectRoleAA = true;
            }
            if (roleOrg?.role_name === OS) {
              isSelectRoleOS = true;
            }
            // Init Role at root
            initChecked.push({
              organization_id: roleOrg?.org_id,
              role_name: roleOrg?.role_name,
              limited: !!roleOrg?.limited,
              disabled: false,
            });
          });
        }

        if (isSelectRoleAA) {
          // Check all school and grade
          if (Array.isArray(organization?.childrens)) {
            organization.childrens.forEach((school: any) => {
              initChecked.push({
                organization_id: school?._id,
                role_name: OA,
                disabled: true,
                limited: false,
              });

              if (Array.isArray(school?.childrens)) {
                school.childrens.forEach((grade: any) => {
                  initChecked.push({
                    organization_id: grade?._id,
                    role_name: OA,
                    disabled: true,
                    limited: false,
                  });
                });
              }
            });
          }
        }

        if (!isSelectRoleOS) {
          // Check role in school
          if (Array.isArray(organization?.childrens)) {
            organization.childrens.forEach((school: any) => {
              if (Array.isArray(school?.role)) {
                school.role.forEach((roleOrg: any) => {
                  // Init Role at school
                  initChecked.push({
                    organization_id: roleOrg?.org_id,
                    role_name: roleOrg?.role_name,
                    limited: !!roleOrg?.limited,
                    disabled: false,
                  });
                });
              }

              // Check role in grade
              if (Array.isArray(school?.childrens)) {
                school.childrens.forEach((grade: any) => {
                  if (Array.isArray(grade?.role)) {
                    grade.role.forEach((roleOrg: any) => {
                      // Init Role at school
                      initChecked.push({
                        organization_id: roleOrg?.org_id,
                        role_name: roleOrg?.role_name,
                        limited: !!roleOrg?.limited,
                        disabled: false,
                      });
                    });
                  }
                });
              }
            });
          }
        } else {
          /**
           * checked root with role OS
           * Board role to be disabled
           */
        }
      });
    }
    const uniqInitRoles: ICheckRoles[] = uniqWith(initChecked, isEqual);

    /**
     * Check grade if school to be checked
     * Check all RO if root RO to be checked
     */
    const newCheckRoles: ICheckRoles[] = [...uniqInitRoles];

    const { allIdInOrg, allSchool }: IHandleIdOrg = handleIdOrg;

    uniqInitRoles.forEach((role: ICheckRoles) => {
      const isCheckSchool: boolean = allSchool.some(
        (item: IAllSchool) => item?._id === role.organization_id
      );

      const isCheckRootRO: boolean = role.organization_id === rootOrgId && role.role_name === RO;

      if (isCheckSchool && !isCheckRootRO) {
        const findSchool: IAllSchool | undefined = allSchool.find(
          (school: IAllSchool) => school?._id === role.organization_id
        );

        if (findSchool) {
          findSchool.children.forEach((gradeId: string) => {
            newCheckRoles.push({
              organization_id: gradeId,
              role_name: role.role_name,
              disabled: true,
              limited: role.limited,
            });
          });
        }
      }

      if (isCheckRootRO) {
        allIdInOrg.forEach((id: string) => {
          newCheckRoles.push({
            organization_id: id,
            role_name: RO,
            disabled: true,
            limited: role.limited,
          });
        });
      }
    });

    const uniqRoles: ICheckRoles[] = uniqWith(newCheckRoles, isEqual);

    cb({ isSelectRoleOS, isSelectRoleAA }); // callback to detail for user to set select role

    setCheckRoles(uniqRoles);
  };

  const handleIdOrg: IHandleIdOrg = React.useMemo(() => {
    const newOrganizations = [...organizations];

    const allIdInOrg: string[] = []; // except root org id
    const allSchool: IAllSchool[] = [];

    newOrganizations.forEach((org: any) => {
      if (org?._id === rootOrgId && Array.isArray(org?.children)) {
        org.children.forEach((school: any) => {
          allIdInOrg.push(school?._id);

          const gradeIdsInSchool: string[] = [];

          if (Array.isArray(school?.children)) {
            school.children.forEach((grade: any) => {
              allIdInOrg.push(grade?._id);
              gradeIdsInSchool.push(grade?._id);
            });
          }
          allSchool.push({ _id: school?._id, children: gradeIdsInSchool });
        });
      }
    });

    return {
      allIdInOrg,
      allSchool,
    };
  }, [organizations]);

  const handleCheckRoles = (
    checked: boolean,
    key: string,
    orgId: string,
    isCheckROParent = false
  ) => {
    const newCheckRoles: ICheckRoles[] = [...checkRoles];

    const index: number = newCheckRoles.findIndex(
      (item: ICheckRoles) => item.organization_id === orgId
    );
    const { allIdInOrg, allSchool }: IHandleIdOrg = handleIdOrg;
    const isCheckSchool: boolean = allSchool.some((item: IAllSchool) => item?._id === orgId);

    if (checked) {
      if (index < 0) {
        newCheckRoles.push({
          organization_id: orgId,
          role_name: key,
          disabled: false,
          limited: false,
        });
      } else {
        newCheckRoles.splice(index, 1, {
          ...newCheckRoles[index],
          role_name: key,
          limited: false,
        });
      }

      if (isCheckSchool) {
        const findSchool: IAllSchool | undefined = allSchool.find(
          (school: IAllSchool) => school?._id === orgId
        );

        if (findSchool) {
          findSchool.children.forEach((gradeId: string) => {
            const findIndexGrade: number = newCheckRoles.findIndex(
              (item: ICheckRoles) => item.organization_id === gradeId
            );
            if (findIndexGrade >= 0) {
              // exist
              const gradeItem = newCheckRoles[findIndexGrade];

              newCheckRoles.splice(findIndexGrade, 1, {
                ...gradeItem,
                role_name: key,
                disabled: true,
                limited: false,
              });
            } else {
              newCheckRoles.push({
                organization_id: gradeId,
                role_name: key,
                disabled: true,
                limited: false,
              });
            }
          });
        }
      }

      if (isCheckROParent) {
        // checked true and check root RO true

        allIdInOrg.forEach((id: string) => {
          const indexCheck: number = newCheckRoles.findIndex(
            (item: ICheckRoles) => item.organization_id === id
          );
          if (indexCheck >= 0) {
            // exist
            const gradeItem = newCheckRoles[indexCheck];

            newCheckRoles.splice(indexCheck, 1, {
              ...gradeItem,
              role_name: key,
              disabled: true,
            });
          } else {
            newCheckRoles.push({
              organization_id: id,
              role_name: key,
              disabled: true,
              limited: false,
            });
          }
        });
      }
    } else {
      if (index >= 0) {
        newCheckRoles.splice(index, 1);
      }

      if (isCheckSchool) {
        const findSchool: IAllSchool | undefined = allSchool.find(
          (school: IAllSchool) => school?._id === orgId
        );
        if (findSchool) {
          findSchool.children.forEach((gradeId: string) => {
            const findIndexGrade: number = newCheckRoles.findIndex(
              (item: ICheckRoles) => item.organization_id === gradeId && item.role_name === key
            );

            newCheckRoles.splice(findIndexGrade, 1);
          });
        }
      }

      if (isCheckROParent) {
        /**
         * Uncheck root and enable checkbox school
         */

        allIdInOrg.forEach((id: string) => {
          const indexCheck: number = newCheckRoles.findIndex(
            (item: ICheckRoles) => item.organization_id === id && item.role_name === RO
          );

          newCheckRoles.splice(indexCheck, 1);
        });
      }
    }

    const uniqRoles: ICheckRoles[] = uniqWith(newCheckRoles, isEqual);

    setCheckRoles(uniqRoles);
  };

  const handleCheckLimiter = (check: boolean, orgId: string) => {
    const newCheckRoles: ICheckRoles[] = [...checkRoles];

    const index: number = newCheckRoles.findIndex(
      (item: ICheckRoles) => item.organization_id === orgId
    );
    const { allSchool }: IHandleIdOrg = handleIdOrg;

    if (index >= 0) {
      newCheckRoles.splice(index, 1, { ...newCheckRoles[index], limited: check });

      const isCheckSchool: boolean = allSchool.some((item: IAllSchool) => item?._id === orgId);

      if (isCheckSchool) {
        const findSchool: IAllSchool | undefined = allSchool.find(
          (school: IAllSchool) => school?._id === orgId
        );
        if (findSchool) {
          findSchool.children.forEach((gradeId: string) => {
            const findIndexGrade: number = newCheckRoles.findIndex(
              (item: ICheckRoles) => item.organization_id === gradeId
            );
            if (findIndexGrade >= 0) {
              // exist
              const gradeItem = newCheckRoles[findIndexGrade];

              newCheckRoles.splice(findIndexGrade, 1, {
                ...gradeItem,
                limited: check,
              });
            }
          });
        }
      }
    }

    setCheckRoles(newCheckRoles);
  };

  const handleChangeTypeRole = (roleValue: string) => {
    const newOrganizations = [...organizations];

    switch (roleValue) {
      case AA: {
        const allSchoolAndGrade: ICheckRoles[] = [];

        newOrganizations.forEach((org: any) => {
          if (org?._id === rootOrgId && Array.isArray(org?.children)) {
            org.children.forEach((school: any) => {
              allSchoolAndGrade.push({
                organization_id: school?._id,
                role_name: OA,
                disabled: true,
                limited: false,
              });

              if (Array.isArray(school?.children)) {
                school.children.forEach((grade: any) => {
                  allSchoolAndGrade.push({
                    organization_id: grade?._id,
                    role_name: OA,
                    disabled: true,
                    limited: false,
                  });
                });
              }
            });
          }
        });
        setCheckRoles(allSchoolAndGrade);

        break;
      }
      case OS:
        setCheckRoles([]);
        break;
      default:
        break;
    }
  };

  const handleClickOptIns = (item: any) => {
    setSelectedItem(item);
    handleVisibleOptIns();
  };

  const handleClickEdit = (item: any) => {
    setSelectedItem(item);
    handleVisibleForm();
  };

  /**
   * Handle Check Logic Opt Ins
   */

  const handleInitOptIns = (optInsUserData: any[]) => {
    const initChecked: ICheckOptIns[] = [];
    const { allIdInOrg, allSchool }: IHandleIdOrg = handleIdOrg;

    if (Array.isArray(optInsUserData)) {
      optInsUserData.forEach((organization: any) => {
        if (Array.isArray(organization?.opt_in)) {
          // Check Opt Ins in root folder
          organization.opt_in.forEach((orgOpt: any) => {
            // Exist Opt Ins in root folder
            const orgType: EOptIns = orgOpt?.type;

            initChecked.push({
              organization_id: orgOpt?.org_id,
              type: orgType,
              disabled: false,
            });

            /**
             * Check school and grade with same type
             * type @EOptIns
             */
            allIdInOrg.forEach((id) => {
              initChecked.push({
                organization_id: id,
                type: orgType,
                disabled: true,
              });
            });
          });
        }

        /**
         * Check Opt Ins in School folder
         */

        if (Array.isArray(organization?.childrens)) {
          organization.childrens.forEach((school: any) => {
            if (Array.isArray(school?.opt_in)) {
              school.opt_in.forEach((schoolOpt: any) => {
                // Exist Opt Ins School folder
                const schoolType: EOptIns = schoolOpt?.type;

                initChecked.push({
                  organization_id: schoolOpt?.org_id,
                  type: schoolType,
                  disabled: false,
                });

                /**
                 * Check grade with same type
                 * type @EOptIns
                 */
                const findSchool: IAllSchool | undefined = allSchool.find(
                  (s: IAllSchool) => s?._id === school?._id
                );

                if (findSchool && Array.isArray(findSchool?.children)) {
                  findSchool.children.forEach((gradeId: string) => {
                    initChecked.push({
                      organization_id: gradeId,
                      type: schoolType,
                      disabled: true,
                    });
                  });
                }
              });
            }

            /**
             * Check Opt Ins Grade folder
             */
            if (Array.isArray(school?.childrens)) {
              school.childrens.forEach((grade: any) => {
                if (Array.isArray(grade?.opt_in)) {
                  grade.opt_in.forEach((gradeOpt: any) => {
                    // Exist Opt Ins grade folder
                    const gradeType: EOptIns = gradeOpt?.type;

                    initChecked.push({
                      organization_id: gradeOpt?.org_id,
                      type: gradeType,
                      disabled: false,
                    });
                  });
                }
              });
            }
          });
        }
      });
    }

    setCheckOptIns(initChecked);
  };

  const handleCheckOptIns = (
    checked: boolean,
    type: EOptIns,
    orgId: string,
    isCheckParent = false
  ) => {
    const newCheckOptIns: ICheckOptIns[] = [...checkOptIns];

    const index: number = newCheckOptIns.findIndex(
      (item: ICheckOptIns) => item.type === type && item.organization_id === orgId
    );

    const { allIdInOrg, allSchool }: IHandleIdOrg = handleIdOrg;

    const isCheckSchool: boolean = allSchool.some((item: IAllSchool) => item?._id === orgId);

    if (checked) {
      if (index < 0) {
        newCheckOptIns.push({ organization_id: orgId, type, disabled: false });
      }

      if (isCheckSchool) {
        const findSchool: IAllSchool | undefined = allSchool.find(
          (school: IAllSchool) => school?._id === orgId
        );
        if (findSchool) {
          findSchool.children.forEach((gradeId: string) => {
            const findIndexGrade: number = newCheckOptIns.findIndex(
              (item: ICheckOptIns) => item.organization_id === gradeId && item.type === type
            );
            if (findIndexGrade >= 0) {
              // exist
              const gradeItem = newCheckOptIns[findIndexGrade];

              newCheckOptIns.splice(findIndexGrade, 1, {
                ...gradeItem,
                type,
                disabled: true,
              });
            } else {
              newCheckOptIns.push({
                organization_id: gradeId,
                type,
                disabled: true,
              });
            }
          });
        }
      }

      if (isCheckParent) {
        // checked true and check root true
        allIdInOrg.forEach((id: string) => {
          const indexCheck: number = newCheckOptIns.findIndex(
            (item: ICheckOptIns) => item.organization_id === id && item.type === type
          );

          if (indexCheck < 0) {
            newCheckOptIns.push({ organization_id: id, type, disabled: true });
          }
        });
      }
    } else {
      if (index >= 0) {
        newCheckOptIns.splice(index, 1);
      }

      if (isCheckSchool) {
        const findSchool: IAllSchool | undefined = allSchool.find(
          (school: IAllSchool) => school?._id === orgId
        );
        if (findSchool) {
          findSchool.children.forEach((gradeId: string) => {
            const findIndexGrade: number = newCheckOptIns.findIndex(
              (item: ICheckOptIns) => item.organization_id === gradeId && item.type === type
            );

            newCheckOptIns.splice(findIndexGrade, 1);
          });
        }
      }

      if (isCheckParent) {
        /**
         * Uncheck root and enable checkbox school
         */

        allIdInOrg.forEach((id: string) => {
          const indexCheck: number = newCheckOptIns.findIndex(
            (item: ICheckOptIns) => item.organization_id === id && item.type === type
          );

          if (indexCheck >= 0) {
            newCheckOptIns.splice(indexCheck, 1);
          }
        });
      }
    }

    setCheckOptIns(newCheckOptIns);
  };

  return (
    <div className="admin-user-setup">
      <Input
        placeholder={t('settingPage.adminUser.username.placeholder')}
        prefix={<SearchOutlined />}
        className="search-input"
        size="large"
        allowClear
        onChange={debounceSearchUser}
        maxLength={256}
      />
      <InfinityLoadTable
        dataSource={users.list}
        columns={columns}
        expandable={{
          expandedRowRender: (record: any) => {
            if (!record?.is_active) return null;

            if (Array.isArray(record?.role) && record.role.length > 0) {
              return (
                <Space direction="vertical" size={16} className="space-role-tag">
                  {record.role.map((role: any) => {
                    return (
                      <Space key={role?._id} size={8}>
                        <Tag className="role-tag">{role?.role_name}</Tag>
                        {role?.org_id?.name}
                      </Space>
                    );
                  })}
                </Space>
              );
            }

            return null;
          },
          expandIcon: ({ expanded, onExpand, record }) => {
            if (!record?.is_active || record?.role?.length === 0) return null;

            if (expanded) {
              return <DownOutlined onClick={(e) => onExpand(record, e)} />;
            }

            return <RightOutlined onClick={(e) => onExpand(record, e)} />;
          },
        }}
        className="table-users"
        scroll={{ y: 'calc(100vh - 300px)' }}
        loadDataAction={loadingUsersAction}
        query={query}
        dataType={t('list.empty.users')}
      />

      {/* Modal Form User */}
      <FormUser
        isVisible={isVisibleForm}
        onCancel={handleCancelFormUser}
        onFinish={onFinish}
        handleCheckRoles={handleCheckRoles}
        handleCheckLimiter={handleCheckLimiter}
        checkRoles={checkRoles}
        formRef={formUserRef}
        handleChangeTypeRole={handleChangeTypeRole}
        organizations={organizations}
        selectedItem={selectedItem}
        handleInitCheckRoles={handleInitCheckRoles}
        ref={childRef}
      />

      {/* Modal Opt Ins */}
      <OptIns
        isVisible={isVisibleOptIns}
        onCancel={handleCancelOptIns}
        selectedItem={selectedItem}
        organizations={organizations}
        onFinish={onFinishOptIns}
        handleCheckOptIns={handleCheckOptIns}
        checkOptIns={checkOptIns}
        handleInitOptIns={handleInitOptIns}
      />

      {/* Modal Unlock user */}
      <UnLockUser
        isVisible={isVisibleUnLock}
        onCancel={handleCancelUnlock}
        isLoading={false}
        onUnLock={onUnLock}
      />
    </div>
  );
};

export default memo(AdminUserSetup);

import { Button, Typography } from 'antd';
import Wrapper from './Wrapper';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'app/hooks';
import { logoutAction } from 'actions/auth';

const NoPermisisonPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleOk = () => dispatch(logoutAction());

  return (
    <Wrapper>
      <div className="lock-block">
        <div>
          <div>
            <div className="block-ip-icon">
              <img src="/images/ic-no-permission.png" alt="No Permission" />
            </div>
            <div>
              <Typography.Text className="txt-title">
                {t('loginPage.noPermissAlert')}
              </Typography.Text>
            </div>
            <div className="description">
              <Typography.Text className="txt-description">
                {t('loginPage.noPermissDescription')}
              </Typography.Text>
            </div>
            <Button type="primary" block size="large" className="btn-ok" onClick={handleOk}>
              {t('common.accept')}
            </Button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default NoPermisisonPage;

import { loginAction } from 'actions/auth';
import { Button, Form, Input, Space, Typography } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { showError } from 'components/standby-notice';
import { memo, useEffect, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import {
  ACCOUNT_DEACTIVE,
  BLOCK_60MINS,
  EMAIL_NOT_EXIST,
  EMAIL_PASSWORD_WRONG,
  IP_NOT_ALLOWED,
  isEmail,
  PASSWORD_WRONG,
  INACTIVE_ORG,
} from 'utils';
import Lock60Mins from './components/Lock60Mins';
import PopupOk from './components/PopupOk';
import Wrapper from './components/Wrapper';
import { ILoginFormValues, IPayloadLogin, EPopUpTypeLogin } from './interfaces';

const LoginPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const [isShowLocked, setIsShowLocked] = useState<boolean>(false);
  const [popUpType, setPopUpType] = useState<EPopUpTypeLogin | string>('');
  const [timeLocked, setTimeLocked] = useState<number>(0);

  const isLoggedIn: boolean = useAppSelector((state) => state.auth.isLoggedIn);
  const isLoading: boolean = useAppSelector((state) => state.auth.isLoading);
  const role: string = useAppSelector((state) => state.auth.role);

  const returnToLogin = () => {
    setPopUpType('');
    setIsShowLocked(false);
    form.resetFields();
  };

  const onLoginError = (errorCode: string, data: any) => {
    switch (errorCode) {
      case PASSWORD_WRONG:
      case EMAIL_PASSWORD_WRONG:
        form.setFields([
          {
            name: 'password',
            errors: [t('loginPage.incorrectPw')],
          },
        ]);
        if (data?.org_setting_login_lockout) {
          setIsShowLocked(true);
        } else {
          setIsShowLocked(false);
        }
        break;
      case EMAIL_NOT_EXIST:
        form.setFields([
          {
            name: 'email',
            errors: [t('loginPage.emailNotExist')],
          },
        ]);
        break;
      case IP_NOT_ALLOWED:
        setPopUpType(EPopUpTypeLogin.BLOCK_IP);
        break;
      case ACCOUNT_DEACTIVE:
        setPopUpType(EPopUpTypeLogin.DEACTIVE);
        break;
      case BLOCK_60MINS:
        setPopUpType(EPopUpTypeLogin.LOCK);
        setTimeLocked(data.time);
        break;
      default:
        if (errorCode !== INACTIVE_ORG) {
          showError(t(`errors.code.${errorCode}`));
        }
        break;
    }
  };

  const onFinish = (values: ILoginFormValues) => {
    const params: IPayloadLogin = {
      values,
      onSuccess: () => {},
      onError: (errorCode: string, data: any) => onLoginError(errorCode, data),
    };

    return dispatch(loginAction(params));
  };

  useEffect(() => {
    if (isLoggedIn && role) {
      /**
       * Go to home if user logged in
       */
      return history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  const renderPopUp = () => {
    switch (popUpType) {
      case EPopUpTypeLogin.LOCK: {
        return <Lock60Mins returnToLogin={returnToLogin} mins={timeLocked} />;
      }
      case EPopUpTypeLogin.BLOCK_IP: {
        return (
          <PopupOk
            icon="/images/ic-no-permission.png"
            title={t('loginPage.ipNotAcceptTitle')}
            description={t('loginPage.ipNotAcceptDescription')}
            handleOk={returnToLogin}
          />
        );
      }
      case EPopUpTypeLogin.DEACTIVE: {
        return (
          <PopupOk
            icon="/images/ic-lock.png"
            title={t('loginPage.accountDeactive')}
            handleOk={returnToLogin}
            isDeactiveForm
            isReturnLogin
          />
        );
      }
      default:
        break;
    }
  };

  return (
    <Wrapper>
      {!popUpType ? (
        <Fragment>
          <div className="logo">
            <img src="/images/logo.png" alt="Stand By" />
          </div>

          <Form onFinish={onFinish} layout="vertical" form={form} scrollToFirstError>
            <Space direction="vertical" className="space-login">
              <Form.Item
                label={t('loginPage.login.email')}
                name="email"
                rules={[
                  { required: true, message: t('loginPage.emailRequired') },
                  () => ({
                    validator(_rule, value) {
                      if (!value) return Promise.reject();

                      const checkEmail: boolean = isEmail(value);

                      if (checkEmail) {
                        return Promise.resolve();
                      }

                      return Promise.reject(new Error(t('loginPage.emailFormat')));
                    },
                  }),
                ]}
              >
                <Input
                  size="large"
                  placeholder={t('loginPage.emailPlaceholder')}
                  allowClear
                  className="input-email"
                  maxLength={256}
                  autoComplete="off"
                />
              </Form.Item>

              <div>
                <Form.Item>
                  <Form.Item
                    label={t('loginPage.password')}
                    name="password"
                    rules={[{ required: true, message: t('loginPage.passwordRequired') }]}
                  >
                    <Input.Password
                      size="large"
                      placeholder={t('loginPage.passwordPlaceholder')}
                      className="input-password"
                      allowClear
                      maxLength={16}
                      autoComplete="off"
                    />
                  </Form.Item>
                  <Link to="/auth/forgot-password">
                    <Button className="login-form-forgot" type="link">
                      {t('loginPage.forgotPassword')}
                    </Button>
                  </Link>
                </Form.Item>

                {isShowLocked && (
                  <div className="txt-locked">
                    <Typography.Text type="danger">{t('loginPage.lock60mins')}</Typography.Text>
                  </div>
                )}

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    size="large"
                    className={EPopUpTypeLogin.LOCK ? 'btn-lock' : 'btn-login'}
                    loading={isLoading}
                  >
                    {t('loginPage.login')}
                  </Button>
                </Form.Item>
              </div>
            </Space>
          </Form>
        </Fragment>
      ) : (
        renderPopUp()
      )}
    </Wrapper>
  );
};

export default memo(LoginPage);

import React from 'react';
import { message } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

const CopyAccessCode = ({ text }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <CopyToClipboard
        style={{ cursor: 'pointer' }}
        text={text}
        onCopy={() => message.success(`${t('settingPage.ogSetup.copy.accessCode.success')}`)}
      >
        <button className="copy-access-code-btn">{t('settingPage.ogSetup.copy.accessCode')}</button>
      </CopyToClipboard>
    </React.Fragment>
  );
};

export default CopyAccessCode;

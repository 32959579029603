export interface IUsersSelector {
  users: IListUsersResponse;
  deactivatedUsers: IListUsersResponse;
  isLoading: boolean;
  selectedOrgId: string;
  search: string;
  selectedOrg?: any;
}

// loading users
export interface IQueryLoadingUsers {
  organization_id?: string;
  page_number: number;
  search?: string;
  show_hidden?: boolean;
}

export interface IPayloadLoadingUsers {
  query: IQueryLoadingUsers;
  onSuccess?: (data: any) => void;
  onError?: (errorCode: string) => void;
}

export interface IActionLoadingUsers {
  type: string;
  payload: IPayloadLoadingUsers;
}

// selected organization

export enum ESelectOrganizationValues {
  ALL_IN_CHARGE = 'ALL_IN_CHARGE',
}

export interface IActionSelectedOrganization {
  type: string;
  payload: IPayloadSelectedOrganization;
}

export interface IPayloadSelectedOrganization {
  selectedOrg: ESelectOrganizationValues.ALL_IN_CHARGE | Record<string, any>;
  onSuccess?: () => void;
}

export interface IActionSelectedOrganizationID {
  type: string;
  payload: IPayloadSelectedOrganizationID;
}

export interface IPayloadSelectedOrganizationID {
  selectedOrgId: ESelectOrganizationValues.ALL_IN_CHARGE | string;
  onSuccess?: () => void;
}

// change search keyword users

export interface IPayloadSearchUsers {
  keyword: string;
  onSuccess?: () => void;
}

export interface IActionSearchUsers {
  type: string;
  payload: IPayloadSearchUsers;
}

// Get User Role
export interface IQueryUserRole {
  user_id?: string;
  name?: string;
  show_hidden?: boolean;
}
export interface IPayloadLoadingUserRole {
  query: IQueryUserRole;
  onSuccess?: (data: any) => void;
  onError?: (errorCode: string) => void;
  ignoreDispatchReducer?: boolean;
}
export interface IActionLoadingUserRole {
  type: string;
  payload: IPayloadLoadingUserRole;
}

// Form User Props
export interface IRolesFormUser {
  organization_id: string;
  role_name: string;
  limited: boolean;
}
export interface ICheckRoles extends IRolesFormUser {
  disabled: boolean;
}

export interface ICallbackInitCheckRole {
  isSelectRoleOS: boolean;
  isSelectRoleAA: boolean;
}
export interface IFormUserProps {
  formRef: any;
  isVisible: boolean;
  onCancel: () => void;
  onFinish: (values: any) => void;
  handleCheckRoles: (
    checked: boolean,
    key: string,
    orgId: string,
    isCheckROParent: boolean
  ) => void;
  handleInitCheckRoles: (
    roleUserData: any[],
    cb: (cbValue: ICallbackInitCheckRole) => void
  ) => void;
  checkRoles: ICheckRoles[];
  handleCheckLimiter: (checked: boolean, orgId: string) => void;
  handleChangeTypeRole: (value: string) => void;
  organizations: any[];
  selectedItem: any;
}

export interface IDeactiveUserProps {
  isVisible: boolean;
  onCancel: () => void;
  onDeactive: () => void;
  isLoading: boolean;
  onActive?: any;
  actionHandleChange?: string;
}
export interface IUnLockUserProps {
  isVisible: boolean;
  onCancel: () => void;
  onUnLock: () => void;
  isLoading: boolean;
}

// Create User action api

export interface ICreateUserFormValues {
  first_name: string;
  last_name: string;
  phone: string;
  country_code: string;
  email: string;
  roles: IRolesFormUser[];

  // for optional handle form
  role?: string;
  is_active?: boolean;

  // user_id optional for create
  user_id?: string;
  role_oa?: any;
  show_hidden?: boolean;
}
export interface IPayloadCreateUser {
  values: ICreateUserFormValues;
  onSuccess?: () => void;
  onError?: (errorCode: string) => void;
}
export interface IActionCreateUser {
  type: string;
  payload: IPayloadCreateUser;
}

// Update User api
export interface IUpdateUserFormValues extends ICreateUserFormValues {}
export interface IPayloadUpdateUser {
  values: IUpdateUserFormValues;
  onSuccess?: () => void;
  onError?: (errorCode: string) => void;
}
export interface IActionUpdateUser {
  type: string;
  payload: IPayloadUpdateUser;
}

// Opt Ins Props screen
export enum EOptIns {
  INCIDENT_MAIL = 'INCIDENT_MAIL',
  INCIDENT_SMS = 'INCIDENT_SMS',
  MESSAGE_MAIL = 'MESSAGE_MAIL',
  MESSAGE_SMS = 'MESSAGE_SMS',
}
export interface IOptInsProps {
  isVisible: boolean;
  onCancel: () => void;
  onFinish: () => void;
  organizations: any[];
  selectedItem: any;
  isLoadingTable?: boolean;
  handleLoadingTable?: any;
  handleCheckOptIns: (
    checked: boolean,
    key: EOptIns,
    orgId: string,
    isCheckParent: boolean
  ) => void;
  checkOptIns: ICheckOptIns[];
  handleInitOptIns: (init: any[]) => void;
  isEditMyprofile?: boolean;
}

export interface ICheckOptIns {
  organization_id: string;
  type: EOptIns;
  disabled: boolean;
}

export interface IAllSchool {
  _id: string;
  children: string[];
}
export interface IHandleIdOrg {
  allIdInOrg: string[];
  allSchool: IAllSchool[];
}
// Update Otp Ins User api

export interface IOptInData {
  type: EOptIns;
  organization_ids: string[];
}
export interface IOptInsFormValues {
  admin_user_id: string;
  optin_data: IOptInData[];
}
export interface IPayloadUpdateOptInsUser {
  values: IOptInsFormValues;
  onSuccess?: () => void;
  onError?: (errorCode: string) => void;
}

export interface IActionUpdateOptInsUser {
  type: string;
  payload: IPayloadUpdateOptInsUser;
}

// Opt Ins User api
export interface IQueryGetOptInsUser {
  user_id: string;
}
export interface IPayloadGetOptInsUser {
  query: IQueryGetOptInsUser;
  onSuccess: (data: any) => void;
  onError?: (errorCode: string) => void;
}
export interface IActionGetOptInsUser {
  type: string;
  payload: IPayloadGetOptInsUser;
}

// Get detail user api
export interface IQueryGetDetailUser {
  user_id: string;
}
export interface IPayloadGetDetailUser {
  query: IQueryGetDetailUser;
  onSuccess: (data: any) => void;
  onError?: (errorCode: string) => void;
}
export interface IActionGetDetailUser {
  type: string;
  payload: IPayloadGetDetailUser;
}

// Change status user api
export interface IChangeStatusUserFormValues {
  user_id: string;
  is_active: boolean;
}
export interface IPayloadChangeStatusUser {
  values: IChangeStatusUserFormValues;
  onSuccess?: () => void;
  onError?: (errorCode: string) => void;
}
export interface IActionChangeStatusUser {
  type: string;
  payload: IPayloadChangeStatusUser;
}

// unlock user api
export interface IUnlockUserFormValues {
  user_id: string;
}
export interface IPayloadUnlockUser {
  values: IUnlockUserFormValues;
  onSuccess?: () => void;
  onError?: (errorCode: string) => void;
}
export interface IActionUnlockUser {
  type: string;
  payload: IPayloadUnlockUser;
}

/**
 * Deactive Users
 */

// loading deactive users
export interface IQueryLoadingDeactiveUsers {
  organization_id: string;
  page: number;
  page_size: number;
  show_hidden?: boolean;
}
export interface IPayloadLoadingDeactiveUsers {
  query: IQueryLoadingDeactiveUsers;
  onSuccess?: (data: any) => void;
  onError?: (errorCode: string) => void;
}
export interface IActionLoadingDeactiveUsers {
  type: string;
  payload: IPayloadLoadingDeactiveUsers;
}

// reactive users
export interface IPayloadReactiveUsers {
  userIds: string[];
  onSuccess?: () => void;
  onError?: (errorCode: string) => void;
}
export interface IActionReactiveUsers {
  type: string;
  payload: IPayloadReactiveUsers;
}

export interface IListUsersResponse {
  list: any[];
  pagination: Record<string, any>;
}

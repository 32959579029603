const queryString = (params: any): string => {
  return Object.keys(params)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');
};

export const isEmail = (email: string): boolean => {
  const re =
    // eslint-disable-next-line no-useless-escape
    /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

  if (!email) return false;

  const emailParts: string[] = email.split('@');

  if (emailParts.length !== 2) return false;

  const account: string = emailParts[0];
  const address: string = emailParts[1];

  if (account.length > 64) return false;
  if (address.length > 255) return false;

  const domainParts: string[] = address.split('.');

  if (domainParts.some((part: string) => part.length > 63)) {
    return false;
  }

  return re.test(String(email).toLowerCase());
};

export function validateIpAddress(IP: string): boolean {
  if (
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      IP
    )
  ) {
    return true;
  }

  return false;
}

export const validateAccessCodeRegex = /^[a-zA-Z0-9~!@#$%^&*()]+$/;

const AA = 'AA';
const OS = 'OS';
const OA = 'OA';
const RM = 'RM';
const RO = 'RO';

const OK = 'OK';
const ERROR = 'ERROR';
const TFA = 'TFA';

export const MANAGE_ROLES = [AA, OS, OA];

const EMAIL_NOT_EXIST = 'AD10001';
const PASSWORD_WRONG = 'AD20001';
const EMAIL_PASSWORD_WRONG = 'AD00001';
const BLOCK_60MINS = 'AD20003';
const ACCOUNT_DEACTIVE = 'AD30001';
const IP_NOT_ALLOWED = 'AD30002';
const SESSION_INVALID = 'AD40001';
const TFA_INVALID = 'AD50001';
const TFA_EXPIRED = 'AD50002';
const TOKEN_INVALID = 'C00004';
const ACCESS_CODE_EXIST = 'OG11003';
const ACCESS_CODE_ALREADY_EXIST = 'OG10003';
const INVALID_PARENT_ORG = 'OG80001';
//const DEACTIVE_ACCESS_CODE_BEFORE_HIDE = 'OG19001';
const INVALID_PARENT_OG = 'OG80001';
const FORBIDDEN = 'AUTH00002';
const USER_NOT_SAME_ORG = 'ADU20003';
const EMAIL_EXIST = 'ADU30003';
const ADMIN_NOT_SAME_ORG_USER = 'ADU20001';
const USER_NOT_PERMISS = 'ADU10002';
const TARGET_USER_NOT_PERMISS = 'ADU20005';
const QUESTION_NOT_EDIT = 'IA10009';
const WORKING_TIME_INVALID = 'WK00003';
const CANT_VIEW_INCIDENT = 'IC1002';
const INVALID_INCIDENT_ID = 'IC1003';
const OS_CANT_MANAGE_INCIDENT = 'IC1006';
const SYSTEM_MAINTENANCE = 'SYS10001';
const LINK_EXPIRED = 'SYS00005';
const SCHEDULE_AFTER_NOW = 'BCT30003';
const INACTIVE_ORG = 'OG10000';
const DELETED_ORG1 = 'OG10004';
const DELETED_ORG2 = 'OG10001';

export {
  DELETED_ORG1,
  DELETED_ORG2,
  OK,
  ERROR,
  PASSWORD_WRONG,
  EMAIL_NOT_EXIST,
  EMAIL_EXIST,
  IP_NOT_ALLOWED,
  TFA,
  SESSION_INVALID,
  TFA_INVALID,
  TFA_EXPIRED,
  TOKEN_INVALID,
  BLOCK_60MINS,
  ACCOUNT_DEACTIVE,
  ACCESS_CODE_EXIST,
  ACCESS_CODE_ALREADY_EXIST,
  //  DEACTIVE_ACCESS_CODE_BEFORE_HIDE,
  INVALID_PARENT_OG,
  FORBIDDEN,
  AA,
  OS,
  OA,
  RM,
  RO,
  USER_NOT_SAME_ORG,
  ADMIN_NOT_SAME_ORG_USER,
  EMAIL_PASSWORD_WRONG,
  TARGET_USER_NOT_PERMISS,
  QUESTION_NOT_EDIT,
  USER_NOT_PERMISS,
  WORKING_TIME_INVALID,
  CANT_VIEW_INCIDENT,
  OS_CANT_MANAGE_INCIDENT,
  INVALID_PARENT_ORG,
  SYSTEM_MAINTENANCE,
  LINK_EXPIRED,
  SCHEDULE_AFTER_NOW,
  // function
  queryString,
  INVALID_INCIDENT_ID,
  INACTIVE_ORG,
};

export const NOT_SHOW_ERROR_CODE = [
  EMAIL_PASSWORD_WRONG,
  INVALID_INCIDENT_ID,
  'OG14002',
  FORBIDDEN,
];

export const getFileType = (fileName: string) => {
  const tmp = fileName.split('.');
  return tmp[tmp.length - 1];
};

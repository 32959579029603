/* eslint-disable react-hooks/exhaustive-deps */
import './header.scss';
import { Menu, Divider, Typography, Space, Dropdown, Layout, Avatar } from 'antd';
import { PlusOutlined, QuestionCircleFilled } from '@ant-design/icons';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { logoutAction } from 'actions/auth';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Notifications from '../notification/Notifications';
import { OS, RM, RO } from 'utils';
import { getTerms } from 'api/home';
import LogoIcon from 'components/common/icon-svg/logo';
import { ETypeMenuFooter } from '../footer/Footer';

const { Header } = Layout;

const menuData: any[] = [
  { label: 'incidents', path: '/admin/incidents' },
  { label: 'broadcasts', path: '/admin/broadcasts' },
  { label: 'reports', path: '/admin/reports' },
  { label: 'setting', path: '/admin/setting' },
];

const HeaderComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const [menus, setMenus] = React.useState<any[]>(menuData);
  const [selectedMenu, setSelectedMenu] = React.useState<string[]>(['incidents']);

  const role: string = useAppSelector((state) => state.auth.role);
  const profile: any = useAppSelector((state) => state.auth.profile);

  const isRMRO: boolean = React.useMemo(() => [RM, RO].includes(role), [role]);
  const isOS: boolean = React.useMemo(() => role === OS, [role]);

  React.useEffect(() => {
    if (pathname) {
      const [, , path] = pathname.split('/');

      if (path === 'profile') {
        setSelectedMenu(['setting']);
      } else {
        setSelectedMenu([path]);
      }
    }
  }, [pathname]);

  React.useEffect(() => {
    if (isRMRO) {
      const newMenus = menuData.filter((menu) => menu.label !== 'setting');
      setMenus(newMenus);
      return;
    }

    if (isOS) {
      const newMenus = menuData.filter(
        (menu) => menu.label !== 'incidents' && menu.label !== 'broadcasts'
      );
      setMenus(newMenus);
      return;
    }
    setMenus(menuData);
  }, [role]);

  const handleLogout = () => dispatch(logoutAction());

  const navigateProfileSetting = () => history.push('/admin/profile');

  const handleChangeMenu = (event: any) => {
    setSelectedMenu(event.selectedKeys);
  };

  const menuDropDown = () => (
    <Menu onClick={navigateProfileSetting}>
      <Menu.Item key="user">
        <Space size={10}>
          <img src="/images/ic-user.png" width="20" height="20" alt="" />
          <Typography.Text className="txt-menu">{t('settingPage.profile')}</Typography.Text>
        </Space>
      </Menu.Item>

      <Menu.Divider />

      <Menu.Item key="logout" onClick={handleLogout}>
        <Space size={12}>
          <PlusOutlined style={{ marginLeft: 4 }} />
          <Typography.Text className="txt-menu">{t('menu.logout')}</Typography.Text>
        </Space>
      </Menu.Item>
    </Menu>
  );
  const onClickMenuItem = ({ path }: any) => history.push(path);

  const onClickLogo = () => {
    setSelectedMenu([isOS ? 'setting' : 'incidents']);
  };

  const navigateSupport = () => {
    getTerms(ETypeMenuFooter.SUPPORT)
      .then((response: any) => {
        window.open(response?.data, '_blank');
      })
      .catch((error: any) => {
        console.log('🚀 ~ error', error);
      });
  };

  const integrateName: string = `${profile?.last_name || ''} ${profile?.first_name || ''}`;

  const fullName: string = integrateName.trim().length === 0 ? profile?.email : integrateName;
  return (
    <Header className="header">
      <div className="logo-container">
        <Link to="/">
          <div className="logo" onClick={onClickLogo}>
            <LogoIcon />
          </div>
        </Link>
      </div>
      <div className="midle-header-container" style={{ width: '65%' }}>
        <Menu
          mode="horizontal"
          selectedKeys={selectedMenu}
          className="menu-tab"
          onSelect={handleChangeMenu}
        >
          {menus.map((menu) => (
            <Menu.Item key={menu.label} onClick={() => onClickMenuItem(menu)}>
              <div className="wrapper-menu">
                <div
                  className={
                    pathname !== '/admin/profile'
                      ? `menu-standby ${
                          selectedMenu[0] === menu.label ? 'menu-standby-active' : ''
                        }`
                      : 'menudefault'
                  }
                >
                  <span>{t(`menu.${menu.label}`)}</span>
                </div>
              </div>
            </Menu.Item>
          ))}
        </Menu>
      </div>
      <div className="extra-profile">
        <Space className="icon" size={20}>
          <QuestionCircleFilled className="ic-qs" onClick={navigateSupport} />

          <Notifications />

          <Divider type="vertical" className="divider" />
        </Space>

        <Dropdown
          overlay={menuDropDown}
          className="menu-user"
          placement="bottomRight"
          trigger={['click']}
          overlayClassName="dropdown-menu-user"
        >
          <Space className="avatar">
            <Avatar src="/images/ic-avatar.png" className="ic-avatar" />
            <Typography.Text className="profile-name">{fullName}</Typography.Text>
          </Space>
        </Dropdown>
      </div>
    </Header>
  );
};

export default React.memo(HeaderComponent);

/* eslint-disable react-hooks/exhaustive-deps */
import { CloseCircleFilled, DeleteOutlined, InfoCircleFilled } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
  Divider,
  Radio,
  Spin,
} from 'antd';
import { Organizations } from 'components/common';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IDetailBroadCastProps,
  IPayloadDeleteBroadcast,
  IPayloadResendBroadcast,
  IPayloadUpdateBroadcast,
  IUpdateBroadcastFormValues,
} from '../interfaces/detail-broadcast';
import { EActionBroadcast, formatSchedule } from '../interfaces';
import { RO } from 'utils';
import { ModalConfirm } from 'components/modal-confirm';
import { showError, showSuccess } from 'components/standby-notice';
import {
  deleteBroadcastAction,
  resendBroadcastAction,
  updateBroadcastAction,
} from 'actions/broadcast';
import moment from 'moment';
import { IFile } from 'pages/incidents/interfaces';
import { apiPreviewBroadcast } from 'api/incidents';
import { getBroadcastDetail } from 'api/broadcast';
import { ESelectOrganizationValues } from 'pages/setting/interfaces';
import trim from 'lodash/trim';
import locale from 'antd/es/date-picker/locale/ja_JP';
import { isEmpty, isArrayLikeObject } from 'lodash';
const { TextArea } = Input;
const { Option } = Select;

const DetailBroadcast = ({
  isVisible,
  handleSave,
  handleCancel,
  selectedItem,
  loadingListBroadcast,
}: IDetailBroadCastProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const [isVisibleSelectOrg, setIsVisibleSelectOrg] = React.useState<boolean>(false);
  const [optionsOrganizations, setOptionsOrganizations] = React.useState<any[]>([]);
  const [isVisibleDelete, setIsVisibleDelete] = React.useState<boolean>(false);
  const [filesList, setFilesList] = React.useState<IFile[]>([]);
  const [isDisabledSent, setIsDisabledSent] = React.useState<boolean>(false);
  const [logs, setLogs] = React.useState<any[]>([]);
  const [detailBroadcast, setDetailBroadcast] = React.useState<any>({});
  const [loadingDetail, setLoadingDetail] = React.useState<boolean>(false);

  const role: string = useAppSelector((state) => state.auth.role);
  const roles: any = useAppSelector((state) => state.auth.roles);
  const permissionOrganizations: any[] = useAppSelector(
    (state) => state.auth.permissionOrganizations
  );

  const isLoading: boolean = useAppSelector((state) => state.broadcasts.isLoading);
  const [canEdit, setCanEdit] = React.useState(false);
  const isRO: boolean = React.useMemo(() => role === RO, [role]);
  const sentBroadcast: boolean = detailBroadcast?.status === 'SENT';

  React.useEffect(() => {
    let canEdit = false;
    for (const role of roles) {
      if (role.role_name === RO) continue;
      if (
        selectedItem?.org_id._id === role.org_id ||
        selectedItem?.org_id.path?.includes(role.org_id)
      ) {
        canEdit = true;
        break;
      }
    }
    setCanEdit(canEdit);
  }, [roles, selectedItem]);

  const format = formatSchedule.FORMAT;

  const getDetail = (id: string) => {
    setLoadingDetail(true);
    getBroadcastDetail(id)
      .then((res: any) => {
        if (res?.data) {
          const { data } = res;
          setDetailBroadcast(data);
          if (Array.isArray(data?.files)) {
            setFilesList(data.files);
          }
          if (Array.isArray(data?.logs)) {
            setLogs(data.logs);
          }
          const optionsOrganizationsToApi = [
            { label: getPathNameOrg(data?.org_id?._id), value: data?.org_id?._id },
          ];

          setOptionsOrganizations(optionsOrganizationsToApi);
          setIsDisabledSent(data.status === 'SENT');
          form.setFieldsValue({
            subject: data?.subject,
            message: data?.message,
            organization_id: data?.org_id?._id,
            schedule: data?.schedule ? moment(data?.schedule) : '',
          });
        }
      })
      .catch((error: any) => {
        console.log('🚀 ~ error', error);
        handleCancel();
      })
      .finally(() => {
        setLoadingDetail(false);
      });
  };

  React.useEffect(() => {
    if (isVisible && selectedItem) {
      form.resetFields();
      getDetail(selectedItem?._id);
    }

    return () => {
      if (isVisible) {
        form.resetFields();
      }
    };
  }, [selectedItem, isVisible]);

  const onFindOrganization = (valueObj: any, comparisonNumber: string) => {
    return valueObj.find((item: any) => item._id === comparisonNumber);
  };

  const onSelectedOrganization = useCallback(
    (selectedItem: any) => {
      handleVisibleOrg(false);
      if (selectedItem) {
        const { key } = selectedItem;
        let organizationName: string = '';
        let organizationValue: string = '';

        if (Array.isArray(permissionOrganizations)) {
          const [orgId, schoolId, gradeId] = key.split('-');

          if (!isEmpty(orgId)) {
            const findOrg: any = permissionOrganizations.find((item: any) => item._id === orgId);

            if (!isEmpty(findOrg)) {
              organizationName = findOrg.name;
              organizationValue = findOrg._id;
              if (!isEmpty(schoolId) && isArrayLikeObject(findOrg?.children)) {
                const findSchool = onFindOrganization(findOrg?.children, schoolId);

                if (!isEmpty(findSchool)) {
                  organizationName = `${findOrg.name} > ${findSchool.name}`;
                  organizationValue = findSchool._id;
                  if (gradeId && Array.isArray(findSchool?.children)) {
                    const findGrade = onFindOrganization(findSchool.children, gradeId);
                    if (findGrade) {
                      organizationName = `${findOrg.name} > ${findSchool.name} > ${findGrade.name}`;
                      organizationValue = findGrade._id;
                    }
                  }
                }
              }
            }
          }
        }
        const newOptionsOrganizations = [{ label: organizationName, value: organizationValue }];
        form.setFieldsValue({
          organization_id: organizationValue,
        });

        setOptionsOrganizations(newOptionsOrganizations);

        /**
         * Has value org_id, will call api list @status by @org_id
         * Default set status to - All Status - if change organization
         */
        form.setFieldsValue({ org_id: organizationValue, status: '' });
      } else {
        form.setFieldsValue({ org_id: 'all', status: '' });
      }
    },
    [permissionOrganizations]
  );

  const handleVisibleOrg = (visible: boolean) => {
    setIsVisibleSelectOrg(visible);
  };

  const onFinish = ({
    organization_id,
    subject,
    message,
    schedule,
    ...values
  }: IUpdateBroadcastFormValues) => {
    const isResend: boolean = !!values?.resend;

    const payloadSent: IPayloadResendBroadcast = {
      id: selectedItem?._id,
      onSuccess: () => {
        handleCancel();
        loadingListBroadcast();
        showSuccess(t('broadcast.sent.success'));
      },
      onError: (errorCode: string) => {
        console.log('🚀 ~ errorCode', errorCode);
      },
    };

    if (isResend) {
      return dispatch(resendBroadcastAction(payloadSent));
    }

    const payloadUpdate: IPayloadUpdateBroadcast = {
      values: {
        organization_id:
          organization_id === ESelectOrganizationValues.ALL_IN_CHARGE ? '' : organization_id,
        files: selectedItem?.files || [],
        id: selectedItem?._id,
        message: trim(message),
        subject: trim(subject),
        schedule: schedule ? moment(schedule).format(format) : '',
        action: EActionBroadcast.UPDATE,
      },
      onSuccess: () => {
        loadingListBroadcast();
        handleCancel();
        showSuccess(t(`broadcast.create.${schedule ? 'schedule' : 'send'}.success`));
      },
      onError: (errorCode: string) => {
        console.log('🚀 ~ errorCode', errorCode);
      },
    };

    return dispatch(updateBroadcastAction(payloadUpdate));
  };

  const renderTitleModal = () => {
    return (
      <div className="title-modal-detail">
        <Typography.Text className="txt-title-detailModal">
          {t('broadcast.detail.title')}
        </Typography.Text>
        {!sentBroadcast && canEdit && (
          <Button
            className="btn-del-broadcast"
            icon={<DeleteOutlined />}
            onClick={handleVisibleDelete}
          >
            {t('broadcast.modal.view.deleteBroadcast')}
          </Button>
        )}
      </div>
    );
  };

  const columnsLogs: any[] = [
    {
      title: t('broadcast.modal.table.title.status'),
      dataIndex: 'new_value',
      key: 'new_value',
      width: 193,
      render: (_: any, record: any) => {
        if (record?.extra_data?.boardcast_status === 'SENT') {
          return (
            <Space className="schedule" direction="vertical" size={0}>
              <Typography.Text className="title-schedule">
                {t('broadcast.list.status.sent')}
              </Typography.Text>
              {record?.extra_data?.action ? (
                <Typography.Text className="txt-schedule">
                  ({t('broadcast.list.status.resent')})
                </Typography.Text>
              ) : null}
            </Space>
          );
        }

        return (
          <Space className="schedule" direction="vertical" size={0}>
            <Typography.Text className="title-schedule">
              {t('broadcast.list.status.schedule')}
            </Typography.Text>
            <Typography.Text className="txt-schedule">
              {record?.extra_data?.date ? moment(record.extra_data.date).format(format) : ''}
            </Typography.Text>
          </Space>
        );
      },
    },
    {
      title: t('broadcast.modal.table.title.sender'),
      dataIndex: 'sender',
      key: 'sender',
      width: 390,
      render: (_: any, record: any) =>
        record?.user_id?.email ||
        `${record?.user_id?.last_name || ''} ${record?.user_id?.first_name || ''}`,
    },
    {
      title: t('broadcast.modal.table.title.createdDate'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 180,
      render: (createdAt: string) => moment(createdAt).format(format),
    },
  ];

  const onPreviewImage = (file: IFile) => {
    const params: any = { files: [file?.fileKey] };

    apiPreviewBroadcast(params)
      .then((res: any) => {
        window.open(res?.data?.[0]?.url);
      })
      .catch(() => {
        showError(t('error.common'));
      });
  };

  const renderFileImage = (file: IFile) => {
    let text = '';

    if (file?.original_name) {
      const getExt: string[] = file.original_name.split('.');
      const myExt: string | undefined = getExt.pop() || '';
      text = myExt.toUpperCase();
    }

    return (
      <div className="wrap-fileType" style={{ width: 50, height: 50 }}>
        <span>{text}</span>
      </div>
    );
  };

  const handleClickResend = (event: any) => {
    const { value } = event.target;
    const rensendValue = form.getFieldValue('resend');
    form.setFieldsValue({ resend: !value });

    setIsDisabledSent(rensendValue);
  };

  const handleDelete = () => {
    const payload: IPayloadDeleteBroadcast = {
      values: {
        action: EActionBroadcast.REMOVE,
        id: selectedItem?._id,
        files: selectedItem?.files || [],
        message: selectedItem?.message,
        organization_id: selectedItem?.org_id?._id,
        schedule: selectedItem?.schedule,
        subject: selectedItem?.subject,
      },
      onSuccess: () => {
        loadingListBroadcast();
        handleCancelDelete();
        handleCancel();
        showSuccess(t('broadcast.modal.delete.success'));
      },
      onError: (errorCode: string) => {
        console.log('🚀 ~ errorCode', errorCode);
        showError(t(`errors.code.${errorCode}`));
      },
    };

    return dispatch(deleteBroadcastAction(payload));
  };

  const handleVisibleDelete = () => {
    setIsVisibleDelete(true);
  };

  const handleCancelDelete = () => {
    setIsVisibleDelete(false);
  };

  const getPathNameOrg = (org_id: any) => {
    console.log(permissionOrganizations);
    let permission = permissionOrganizations[0];
    if (permission?._id === org_id) {
      return permission?.name;
    }
    if (permission?.childrens && permission?.childrens.length > 0) {
      for (let i = 0; i < permission?.childrens.length; i++) {
        let childOrg = permission.childrens[i];
        if (childOrg._id === org_id) {
          return `${permission.name} > ${childOrg.name}`;
        }
        if (childOrg.childrens && childOrg?.childrens.length > 0) {
          for (let j = 0; j < childOrg.childrens.length; j++) {
            let grandChildOrg = childOrg.childrens[j];
            if (grandChildOrg._id === org_id) {
              return `${permission.name} > ${childOrg.name} > ${grandChildOrg.name}`;
            }
          }
        }
      }
    }
    return '';
  };

  const handleCheckVisibleOrg = (): boolean => {
    if (sentBroadcast || !canEdit) return false;

    return isVisibleSelectOrg;
  };

  return (
    <div>
      <Modal
        className="modal md-detail-broadcast"
        centered
        maskClosable={false}
        title={renderTitleModal()}
        visible={isVisible}
        onOk={handleSave}
        onCancel={handleCancel}
        width={800}
        footer={[
          canEdit && (
            <Button
              key="large"
              type="primary"
              onClick={() => form.submit()}
              className="btn-save"
              size="large"
              loading={isLoading}
              disabled={isDisabledSent}
            >
              {sentBroadcast ? t('broadcast.modal.sendBtn') : t('broadcast.detail.save')}
            </Button>
          ),
          <Button key="cancel" className="btn-cancel" size="large" onClick={handleCancel}>
            {t('broadcast.detail.cancel')}
          </Button>,
        ]}
        destroyOnClose
      >
        <Spin spinning={loadingDetail} size="small">
          <Form
            layout="vertical"
            onFinish={onFinish}
            form={form}
            requiredMark={false}
            initialValues={{ files: [] }}
            scrollToFirstError
          >
            <Dropdown
              overlay={
                <Organizations
                  onSelected={onSelectedOrganization}
                  isDisabledAmInCharge
                  isVisibleShowHidden={false}
                />
              }
              trigger={['click']}
              overlayClassName="dropdown-organizations"
              onVisibleChange={handleVisibleOrg}
              visible={handleCheckVisibleOrg()}
              disabled={!canEdit}
            >
              <Form.Item
                name="organization_id"
                label={t('broadcast.create.reciptients')}
                rules={[{ required: true, message: t('broadcast.create.reciptientsRequired') }]}
              >
                <Select
                  size="large"
                  className="select-organization"
                  onClick={() => handleVisibleOrg(true)}
                  placeholder={t('broadcast.create.selectOrg')}
                  dropdownRender={() => <div />}
                  disabled={!canEdit || sentBroadcast}
                  notFoundContent={t('no_data', { name: t('list.empty.organization') })}
                >
                  {optionsOrganizations.map((org) => (
                    <Option key={org?.value} value={org.value}>
                      {org?.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Dropdown>

            <Form.Item
              name="subject"
              label={t('broadcast.create.subject')}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: t('broadcast.create.subjectRequired'),
                },
              ]}
            >
              <Input
                className="inp-subject"
                size="large"
                placeholder={t('broadcast.create.subjectInput')}
                disabled={!canEdit || sentBroadcast}
                maxLength={256}
              />
            </Form.Item>
            <Form.Item
              name="message"
              label={t('broadcast.create.message')}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: t('broadcast.create.messageRequired'),
                },
              ]}
            >
              <TextArea
                className="inp-message"
                autoSize={{ minRows: 5, maxRows: 7 }}
                placeholder={t('broadcast.create.messageInput')}
                disabled={!canEdit || sentBroadcast}
                maxLength={12000}
              />
            </Form.Item>

            {!sentBroadcast && (
              <Form.Item name="schedule">
                <DatePicker
                  dropdownClassName="calendar"
                  size="large"
                  suffixIcon={
                    <React.Fragment>
                      <div className="schedule-clock-container">
                        <img
                          className="schedule-clock-icon"
                          alt=""
                          src="/images/clock-icon.svg"
                        ></img>
                        <span className="schedule-clock-text">
                          {t('broadcast.detail.schedule')}:
                        </span>
                      </div>
                    </React.Fragment>
                  }
                  showNow={false}
                  showTime
                  format={format}
                  allowClear
                  clearIcon={<CloseCircleFilled style={{ color: 'red' }} />}
                  className={`picker-schedule`}
                  disabled={isRO}
                  disabledDate={(current) => moment().subtract(1, 'days') >= current}
                  locale={locale}
                />
              </Form.Item>
            )}

            <div className="attachment">
              <Space className="label-info">
                <Typography.Text className="txt">
                  {t('broadcast.detail.attachement')}
                </Typography.Text>
                <Tooltip title={t('broadcast.detail.attachement.description')}>
                  <InfoCircleFilled className="ic-info" />
                </Tooltip>
              </Space>

              <Divider className="div-label-info" />

              <div className="all-files">
                {filesList.map((file: IFile) => (
                  <Space key={file?.fileKey} className="space-file" size={10}>
                    {renderFileImage(file)}
                    <div style={{ position: 'relative' }}>
                      <Typography.Title
                        className="file-name"
                        ellipsis={{ tooltip: true, rows: 1, expandable: false, symbol: '...' }}
                      >
                        {decodeURIComponent(file.original_name)}
                      </Typography.Title>
                      <img
                        src="/images/ic-open-in-new.png"
                        alt=""
                        className="file-preview"
                        onClick={() => onPreviewImage(file)}
                      />
                      <Typography.Text className="file-createdAt">
                        {moment(selectedItem?.createdAt).format('YYYY/MM/DD HH:mm')}
                      </Typography.Text>
                    </div>
                  </Space>
                ))}
              </div>
            </div>

            {canEdit && sentBroadcast && (
              <React.Fragment>
                <Space className="label-info">
                  <Typography.Text className="txt">
                    {t('broadcast.modal.resendThis')}
                  </Typography.Text>
                  <Tooltip title={t('broadcast.modal.resendThis.description')}>
                    <InfoCircleFilled className="ic-info" />
                  </Tooltip>
                </Space>

                <Divider className="div-label-info" />
                <Form.Item name="resend">
                  <Radio.Group className="radio-resend">
                    <Radio value={true} onClick={handleClickResend}>
                      {t('broadcast.modal.resendAll')}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </React.Fragment>
            )}

            <Space className="label-info">
              <Typography.Text className="txt">{t('broadcast.modal.activityLog')}</Typography.Text>
              <Tooltip title={t('broadcast.modal.activityLog.description')}>
                <InfoCircleFilled className="ic-info" />
              </Tooltip>
            </Space>

            <Divider className="div-label-info" />

            <Table
              className="table table-activity-log"
              pagination={false}
              dataSource={logs}
              columns={columnsLogs}
              rowKey="_id"
              locale={{
                emptyText: t('no_data', {
                  name: t('no_data', { name: t('broadcast.detail.actionlog') }),
                }),
              }}
            />
          </Form>
        </Spin>
        <ModalConfirm
          isDeleted
          width={630}
          loading={isLoading}
          visible={isVisibleDelete}
          handleOk={handleDelete}
          handleCancel={handleCancelDelete}
          title={t('broadcast.delete.title')}
          textConfirm={t('broadcast.modal.delete.sure')}
          textConfirmBtn={t('broadcast.modal.delete.textBtn')}
        />
      </Modal>
    </div>
  );
};

export default React.memo(DetailBroadcast);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import './styles/common.scss';
import { Redirect, Route } from 'react-router-dom';
import { PrivateRouter } from 'components/common';
import { PrivateLayout } from 'components/Layout';
import LoginPage from 'pages/auth/LoginPage';
import TwoFactorPage from 'pages/auth/components/TwoFactorAuthenticate';
import ForgotPasswordPage from 'pages/auth/components/ForgotPassword';
import BlockIpPage from 'pages/auth/components/Block-Ip';
import { useAppSelector } from 'app/hooks';
import ShareIncident from 'components/share-incident';
import { OS, updateLanguage } from 'utils';
import { ELanguage } from 'pages/auth/interfaces';
import UserInactivePage from 'pages/auth/components/UserInactive';
import SystemMaintemancePage from 'pages/auth/components/SystemMaintenance';
import NoPermisisonPage from 'pages/auth/components/NoPermission';
import LinkExpiredPage from 'pages/auth/components/LinkExpired';
import { Fragment } from 'react';
import 'moment/locale/ja';
import SpinCenter from 'components/spin-center';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';

const App = () => {
  const role: string = useAppSelector((state) => state.auth.role);
  const isLoggedIn: boolean = useAppSelector((state) => state.auth.isLoggedIn);

  const isOS: boolean = React.useMemo(() => role === OS, [role]);

  const myLanguage: ELanguage = ELanguage.JAPANESE;

  const { ready, t } = useTranslation();

  useEffect(() => {
    window.addEventListener(
      'resize',
      (event) => {
        const width = window.outerWidth;
        document
          .querySelector('meta[name=viewport]')
          ?.setAttribute('content', `width=1366, initial-scale=${width / 1366}`);
      },
      true
    );
    return () => {
      window.removeEventListener(
        'resize',
        (event) => {
          const width = window.outerWidth;
          document
            .querySelector('meta[name=viewport]')
            ?.setAttribute('content', `width=1366, initial-scale=${width / 1366}`);
        },
        true
      );
    };
  }, []);

  useEffect(() => {
    window.addEventListener(
      'load',
      (event) => {
        const width = window.outerWidth;
        document
          .querySelector('meta[name=viewport]')
          ?.setAttribute('content', `width=1366, initial-scale=${width / 1366}`);
      },
      true
    );
    return () => {
      window.removeEventListener(
        'load',
        (event) => {
          const width = window.outerWidth;
          document
            .querySelector('meta[name=viewport]')
            ?.setAttribute('content', `width=1366, initial-scale=${width / 1366}`);
        },
        true
      );
    };
  }, []);

  const onMessErrConnect = () => {
    return notification.error({
      message: t(`error.network.fail`),
    });
  };

  useEffect(() => {
    window.addEventListener('online', () => {
      window.location.reload();
    });
    window.addEventListener('offline', () => {
      onMessErrConnect();
    });

    return () => {
      window.removeEventListener('online', () => ({}));
      window.removeEventListener('offline', () => ({}));
    };
  }, []);

  React.useEffect(() => {
    updateLanguage(myLanguage);
  }, [myLanguage]);

  const redirectTo = (): string => {
    if (isLoggedIn && role) {
      if (isOS) {
        return '/admin/setting';
      }
      return '/admin/incidents';
    }
    return '/auth/login';
  };
  if (!ready) return <SpinCenter />;
  return (
    <Fragment>
      <Route exact path="/" render={() => <Redirect to={redirectTo()} />} />
      <Route path="/auth/login" component={LoginPage} />
      <Route path="/auth/two-factor" component={TwoFactorPage} exact strict />
      <Route path="/auth/forgot-password" component={ForgotPasswordPage} />
      <Route
        exact
        strict
        path="/auth/new-password"
        component={() => <ForgotPasswordPage isCreateNewPassword />}
      />
      <Route path="/admin/share-incident" component={ShareIncident} />
      <Route path="/admin/block-ip" component={BlockIpPage} />
      <Route path="/admin/user-inactive" component={UserInactivePage} />
      <Route path="/admin/no-permission" component={NoPermisisonPage} />
      <Route path="/admin/link-expired" component={LinkExpiredPage} />
      <Route path="/system/maintenance" component={SystemMaintemancePage} />
      <PrivateRouter>
        <PrivateLayout />
      </PrivateRouter>
    </Fragment>
  );
};

export default App;

/* eslint-disable react-hooks/exhaustive-deps */
import { CloseCircleFilled, PlusOutlined } from '@ant-design/icons';
import {
  loadingAllIncidentAttr,
  loadingListOrgAdminAction,
  updateDetailIncidentAction,
} from 'actions/incidents';
import { Form, Input, Select, DatePicker, Typography, Row, Col, Space } from 'antd';
import { apiGetListIncidentAttrByOrg } from 'api/incident-attr';
import { showError, showSuccess } from 'components/standby-notice';
import moment from 'moment';
import { useAppSelector } from 'app/hooks';
import isEqual from 'lodash/isEqual';
import {
  IPayloadActionUpdateDetailIncident,
  IPayloadGetListAttrAll,
  IPayloadGetOrgAdmin,
  IResponseIncidentDetail,
} from 'pages/incidents/interfaces';
import { INCIDENT_ATTR_TYPES, IPayloadGetListIncidentAttrByOrg } from 'pages/setting/interfaces';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { EStatus } from './Notes';
import CloseIcon from 'components/common/icon-svg/close';
import locale from 'antd/es/date-picker/locale/ja_JP';
import get from 'lodash/get';

const { Option } = Select;

interface IFormDetailProps {
  data: IResponseIncidentDetail | null;
  id: string;
  handleShowCloseStatus: (key: any, id: string) => void;
  getActivityLog: () => void;
  getIncidentDetail: () => void;
  currentRole: string;
  onRef?: any;
}

const FormIncidentDetail = ({
  data,
  id,
  handleShowCloseStatus,
  getActivityLog,
  getIncidentDetail,
  currentRole,
  onRef,
}: IFormDetailProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const isLoading: boolean = useAppSelector((state) => state.incidents.isLoading);
  const [optionsStatus, setOptionsStatus] = React.useState<any[]>([]);
  const [optionsType, setOptionsType] = React.useState<any[]>([]);
  const [optionsLabel, setOptionsLabel] = React.useState<any[]>([]);
  const [optionsAction, setOptionsAction] = React.useState<any[]>([]);
  const [optionsAssignees, setOptionsAssignees] = React.useState<any[]>([]);
  const [optionsTargets, setOptionsTargets] = React.useState<any[]>([]);
  const [optionsPerpetrators, setOptionsPerpetrators] = React.useState<any[]>([]);
  const [multileSelect, setMultileSelect] = React.useState<object>({});
  const myOrgId: string = data?.org_id?._id || '';
  const [searchTargetValue, setSearchTargetValue] = React.useState<any>('');
  const [searchPerpetratorsValue, setPerpetratorsValue] = React.useState<any>('');

  const assignRef = React.useRef<any>();
  const perRef = React.useRef<any>();
  const targetRef = React.useRef<any>();
  const labelRef = React.useRef<any>();
  const actionRef = React.useRef<any>();
  React.useEffect(() => {
    return () => {
      onRef?.(undefined);
      setOptionsStatus([]);
      setOptionsType([]);
      setOptionsLabel([]);
      setOptionsAction([]);
      setOptionsAssignees([]);
      setOptionsTargets([]);
      setOptionsPerpetrators([]);
      setMultileSelect({});
    };
  }, []);

  const loadingIncidentAtrr = () => {
    const payload: IPayloadGetListAttrAll = {
      query: {
        organization_id: myOrgId,
      },
      onSuccess: (data) => {
        if (data) {
          setValue(data);
        }
      },
      onError: () => {},
    };

    return dispatch(loadingAllIncidentAttr(payload));
  };

  const loadingTargetsByOrg = React.useCallback(async () => {
    const query: IPayloadGetListIncidentAttrByOrg['query'] = {
      organization_id: myOrgId,
      type: INCIDENT_ATTR_TYPES.TARGETS,
    };

    const getListType = await apiGetListIncidentAttrByOrg(query);

    setOptionsTargets(getListType?.data || []);
  }, [data]);

  const loadingPerpetratorsByOrg = React.useCallback(async () => {
    const query: IPayloadGetListIncidentAttrByOrg['query'] = {
      organization_id: myOrgId,
      type: INCIDENT_ATTR_TYPES.PERPETRATORS,
    };

    const getListType = await apiGetListIncidentAttrByOrg(query);

    setOptionsPerpetrators(getListType?.data || []);
  }, [data]);

  const loadingAdmin = () => {
    const payload: IPayloadGetOrgAdmin = {
      query: {
        organization_id: myOrgId,
      },
      onSuccess: (d: any[]) => {
        setOptionsAssignees(d);
        setFormValue();
      },
      onError: () => {},
    };

    return dispatch(loadingListOrgAdminAction(payload));
  };

  React.useEffect(() => {
    if (data) {
      loadingIncidentAtrr();
      loadingTargetsByOrg();
      loadingPerpetratorsByOrg();
      loadingAdmin();
    }
  }, [data?._id]);

  React.useEffect(() => {
    onRef?.(reloadStatus);
  }, [data?.status?.name]);

  const reloadStatus = () => {
    form.setFieldsValue({
      status: data?.status?.name,
    });
  };

  const setFormValue = () => {
    const assigneessMap = data?.assignees.map((e) => `${e?._id}`);
    const labelsMap = data?.labels.map((e) => e?.name);
    const actionsTakenMap = data?.actions_taken.map((e) => e?.name);
    const convertDate = data?.happened_at ? moment(data.happened_at) : '';
    const targetMap = data?.targets.map((e) => e.trim());
    const perpetratorsMap = data?.perpetrators.map((e) => e.trim());

    form.setFieldsValue({
      status: data?.status?.name,
      type: data?.type?.name,
      happened_at: convertDate,
      location: data?.location,
      assignees: assigneessMap || [],
      labels: labelsMap || [],
      targets: targetMap || [],
      perpetrators: perpetratorsMap || [],
      actions_taken: actionsTakenMap || [],
    });
  };

  const setValue = (data: any[]) => {
    setOptionsLabel(data.filter((e) => e?.type === 'LABEL'));
    setOptionsStatus(data.filter((e) => e?.type === 'STATUS' && e?.code !== 1));
    setOptionsType(data.filter((e) => e?.type === 'TYPE'));
    setOptionsAction(data.filter((e) => e?.type === 'ACTION'));
  };

  const loadingUpdateDetailIncident = (updateData: any, key: string | string[]) => {
    if (updateData?.happened_at) {
      updateData = {
        ...updateData,
        happened_at: moment(updateData.happened_at).format('YYYY/MM/DD'),
      };
    }
    if (
      isEqual(data![key as string], updateData[key as string]) ||
      isEqual(get(data![key as string], '_id'), updateData[key as string])
    )
      return;
    const payload: IPayloadActionUpdateDetailIncident = {
      data: updateData,
      onSuccess: () => {
        showSuccess(t(`incidents.detail.update.${key}.success`));
        setMultileSelect({});
        getActivityLog();
        getIncidentDetail();
      },
      onError: () => {
        showError(t(`error.common`));
        setFormValue();
      },
    };
    dispatch(updateDetailIncidentAction(payload));
  };

  const handleChangeValue = (value: any, nameField, keyEvent) => {
    const nameStatus: any = optionsStatus.find((item) => item?._id === value?.status);
    const key: string[] = value && Object.keys(value);

    if (
      (nameStatus?.code === EStatus.CLOSED || data?.status?.code === EStatus.CLOSED) &&
      keyEvent === null &&
      nameStatus?.code !== data?.status?.code
    ) {
      if (nameStatus?.code === EStatus.CLOSED) {
        handleShowCloseStatus(EStatus.CLOSED, value?.status);
      } else {
        if (value?.status) {
          handleShowCloseStatus('CHANGE', value.status);
        } else {
          showError(t('incidents.detail.formDetail.status.message.error'));
        }
      }
      return;
    } else if (
      value !== null &&
      (value.hasOwnProperty('location') ||
        value.hasOwnProperty('assignees') ||
        value.hasOwnProperty('labels') ||
        value.hasOwnProperty('targets') ||
        value.hasOwnProperty('perpetrators') ||
        value.hasOwnProperty('actions_taken'))
    ) {
      checkTrimObject(value);
    } else {
      if (keyEvent) {
        const keys: any = Object.keys(multileSelect);
        if (keys.length > 0) {
          for (const key of keys) {
            let listIdSelectUpdate: any[] = [];
            if (key === 'assignees') {
              multileSelect[key]?.forEach((element: string) => {
                const list = optionsAssignees.find((e) => e.user_id._id === element);

                listIdSelectUpdate = list ? listIdSelectUpdate?.concat(list) : listIdSelectUpdate;
              });

              const listIdAssignees = {
                assignees: listIdSelectUpdate?.map((e) => e?.user_id?._id),
              };
              loadingUpdateDetailIncident(
                {
                  incident_id: id,
                  ...listIdAssignees,
                },
                key
              );
            } else if (key === 'actions_taken') {
              multileSelect[key]?.forEach((element) => {
                const list = optionsAction?.find((e) => e.name === element);
                listIdSelectUpdate = list ? listIdSelectUpdate?.concat(list) : listIdSelectUpdate;
              });
              const listIdAction = { actions_taken: listIdSelectUpdate?.map((e) => e._id) };
              loadingUpdateDetailIncident(
                {
                  incident_id: id,
                  ...listIdAction,
                },
                key
              );
            } else {
              if (key === 'labels') {
                multileSelect[key]?.forEach((element) => {
                  const list = optionsLabel?.find((e) => e.name === element);
                  listIdSelectUpdate = list ? listIdSelectUpdate?.concat(list) : listIdSelectUpdate;
                });
                const listIdLabel = { labels: listIdSelectUpdate?.map((e) => e._id) };
                loadingUpdateDetailIncident(
                  {
                    incident_id: id,
                    ...listIdLabel,
                  },
                  key
                );
              }
            }
          }
        }
        switch (nameField) {
          case 'targets':
            if (!isEqual(value, data?.targets))
              loadingUpdateDetailIncident({ incident_id: id, targets: value }, nameField);
            break;
          case 'perpetrators':
            if (!isEqual(value, data?.perpetrators))
              loadingUpdateDetailIncident({ incident_id: id, perpetrators: value }, nameField);
            break;
          case 'happened_at':
            loadingUpdateDetailIncident({ incident_id: id, happened_at: value }, nameField);
            break;
          case 'location':
            if (value !== data?.location)
              loadingUpdateDetailIncident({ incident_id: id, location: value }, nameField);
            break;
        }
      } else {
        let updateIncidentData;

        if (value.hasOwnProperty('type') && !value.type) {
          updateIncidentData = {
            incident_id: id,
            type: null,
          };
        } else {
          updateIncidentData = {
            incident_id: id,
            ...value,
          };
        }
        loadingUpdateDetailIncident(updateIncidentData, key);
      }
    }
  };

  const checkTrimObject = (value: any) => {
    const key: string[] = Object.keys(value);
    let valueFiled: any = {};
    if (typeof value[key[0]] === 'object') {
      const valueTrim = Object.keys(value[key[0]])
        .map((_, id) => (value[key[0]][id] = value[key[0]][id].trim()))
        .filter((e) => e !== '');
      if (valueTrim.length > 0) {
        valueFiled[key[0]] = valueTrim;
        form.setFieldsValue({ ...valueFiled });
        setMultileSelect({ ...multileSelect, ...valueFiled });
      } else {
        valueFiled[key[0]] = [];
        form.setFieldsValue(valueFiled);
        setMultileSelect(valueFiled);
      }
    } else {
      Object.keys(value).map((k) => (value[k] = value[k].trim()));
      if (value[key[0]].length > 0) {
        setMultileSelect(value);
      } else {
        setMultileSelect(value);
        valueFiled[key[0]] = '';
        form.setFieldsValue(valueFiled);
      }
    }
  };

  const onDeselect = (type) => {
    if (type === INCIDENT_ATTR_TYPES.TARGETS) {
      return targetRef.current.focus();
    } else if (type === INCIDENT_ATTR_TYPES.PERPETRATORS) {
      return perRef.current.focus();
    } else if (type === INCIDENT_ATTR_TYPES.ACTION) {
      return actionRef.current.focus();
    } else if (type === INCIDENT_ATTR_TYPES.LABEL) {
      return labelRef.current.focus();
    } else {
      return assignRef.current.focus();
    }
  };

  return (
    <Form
      name="complex-form"
      form={form}
      layout="vertical"
      onValuesChange={(value) => handleChangeValue(value, null, null)}
    >
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            name="status"
            label={t('incidents.detail.formDetail.label.status')}
            className="hide-content-multi"
          >
            <Select
              disabled={currentRole === 'RO' ? true : false}
              className="select-item"
              placeholder={t('incidents.detail.formDetail.placeholder.status')}
              size="large"
              dropdownClassName="select-reponse-status"
            >
              {optionsStatus?.map((item: any, index: number) => {
                const id = item._id;
                return (
                  <Option value={id} key={index} className="multile-item">
                    {item?.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="type"
            label={t('incidents.detail.formDetail.label.type')}
            className="hide-content-multi"
          >
            <Select
              disabled={
                data?.status?.code === EStatus.CLOSED || currentRole === 'RO' ? true : false
              }
              className="select-item"
              placeholder={t('incidents.detail.formDetail.placeholder.type')}
              size="large"
              allowClear
            >
              {optionsType?.map((item: any, index: number) => {
                return (
                  <Option value={item?._id} key={index} className="multile-item">
                    {item?.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="happened_at"
            label={t('incidents.detail.formDetail.label.date')}
            // className="hide-content-multi"
          >
            <DatePicker
              disabled={
                data?.status?.code === EStatus.CLOSED || currentRole === 'RO' ? true : false
              }
              placeholder={t('incidents.detail.formDetail.placeholder.date')}
              size="large"
              allowClear
              clearIcon={<CloseCircleFilled style={{ color: 'red' }} />}
              dropdownClassName="date-picker"
              format="YYYY/MM/DD"
              disabledDate={(current: any) => moment().add(0, 'days') < current}
              locale={locale}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="location"
            label={t('incidents.detail.formDetail.label.location')}
            className="hide-content-multi"
          >
            <Input
              onBlur={() => handleChangeValue(form.getFieldValue('location'), 'location', 'blur')}
              disabled={
                data?.status?.code === EStatus.CLOSED || currentRole === 'RO' ? true : false
              }
              placeholder={t('incidents.detail.formDetail.placeholder.location')}
              size="large"
              maxLength={255}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name="assignees" label={t('incidents.detail.formDetail.label.assign')}>
        <Select
          notFoundContent={t('no_data', { name: t('incidents.detail.formDetail.label.assign') })}
          onDeselect={() => onDeselect('ASSIGNEES')}
          ref={assignRef}
          disabled={data?.status?.code === EStatus.CLOSED || currentRole === 'RO' ? true : false}
          mode="multiple"
          placeholder={t('incidents.detail.formDetail.placeholder.assign')}
          style={{ width: '100%' }}
          className="select-item box-select-detail"
          onBlur={() => handleChangeValue(form.getFieldValue('assignees'), 'assignees', 'blur')}
          loading={isLoading}
          removeIcon={
            <div className="close-select">
              <CloseIcon />
            </div>
          }
        >
          {optionsAssignees?.map((item: any, index: number) => {
            const fullName: string = `${item.user_id.last_name || ''} ${
              item.user_id.first_name || ''
            }`;
            return (
              <Option value={item.user_id._id} key={item.user_id._id} className="multile-item">
                <pre className="pre-text">{fullName}</pre>
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item name="labels" label={t('incidents.detail.formDetail.label.labels')}>
        <Select
          notFoundContent={t('no_data', { name: t('incidents.detail.formDetail.label.labels') })}
          onDeselect={() => onDeselect(INCIDENT_ATTR_TYPES.LABEL)}
          ref={labelRef}
          disabled={data?.status?.code === EStatus.CLOSED || currentRole === 'RO' ? true : false}
          mode="multiple"
          placeholder={t('incidents.detail.formDetail.placeholder.labels')}
          size="large"
          className="select-item"
          onBlur={() => handleChangeValue(form.getFieldValue('labels'), 'labels', 'blur')}
          removeIcon={
            <div className="close-select">
              <CloseIcon />
            </div>
          }
        >
          {optionsLabel?.map((item: any, index: number) => {
            return (
              <Option value={item?.name} key={index} className="multile-item">
                {item?.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item name="targets" label={t('incidents.detail.formDetail.label.targets')} shouldUpdate>
        <Select
          notFoundContent={t('no_data', {
            name: t('incidents.detail.formDetail.label.targets'),
          })}
          onDeselect={() => onDeselect(INCIDENT_ATTR_TYPES.TARGETS)}
          ref={targetRef}
          disabled={data?.status?.code === EStatus.CLOSED || currentRole === 'RO' ? true : false}
          removeIcon={
            <div className="close-select">
              <CloseIcon />
            </div>
          }
          onSearch={(e) => {
            setSearchTargetValue(e.substring(0, 255));
          }}
          onBlur={() => handleChangeValue(form.getFieldValue('targets'), 'targets', 'blur')}
          mode="tags"
          placeholder={t('incidents.detail.formDetail.placeholder.targets')}
          // size="large"
          className="select-item"
          showSearch
          autoClearSearchValue
          dropdownClassName="select-dropdown"
          searchValue={searchTargetValue}
          dropdownRender={(menu) => (
            <>
              <Space className="dropdown-addnew" size={4}>
                <PlusOutlined className="plus-icon" />
                <Typography.Text className="plus-text">
                  {t('incidents.detail.formDetail.addnew')}
                </Typography.Text>
              </Space>
              {menu}
            </>
          )}
        >
          {optionsTargets?.map((item: string, index: number) => {
            return (
              <Option value={item} key={index} className="multile-item">
                <pre className="pre-text">{item}</pre>
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item name="perpetrators" label={t('incidents.detail.formDetail.label.perpetrators')}>
        <Select
          notFoundContent={t('no_data', {
            name: t('incidents.detail.formDetail.label.perpetrators'),
          })}
          onSearch={(e) => {
            setPerpetratorsValue(e.substring(0, 255));
          }}
          searchValue={searchPerpetratorsValue}
          onDeselect={() => onDeselect(INCIDENT_ATTR_TYPES.PERPETRATORS)}
          ref={perRef}
          disabled={data?.status?.code === EStatus.CLOSED || currentRole === 'RO' ? true : false}
          removeIcon={
            <div className="close-select">
              <CloseIcon />
            </div>
          }
          mode="tags"
          onBlur={() =>
            handleChangeValue(form.getFieldValue('perpetrators'), 'perpetrators', 'blur')
          }
          placeholder={t('incidents.detail.formDetail.placeholder.perpetrators')}
          // size="large"
          className="select-item"
          showSearch
          dropdownClassName="select-dropdown"
          dropdownRender={(menu) => (
            <>
              <Space className="dropdown-addnew" size={4}>
                <PlusOutlined className="plus-icon" />
                <Typography.Text className="plus-text">
                  {t('incidents.detail.formDetail.addnew')}
                </Typography.Text>
              </Space>
              {menu}
            </>
          )}
        >
          {optionsPerpetrators?.map((item: string, index: number) => {
            return (
              <Option value={item} key={index} className="multile-item">
                <pre className="pre-text">{item}</pre>
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item name="actions_taken" label={t('incidents.detail.formDetail.label.action_taken')}>
        <Select
          notFoundContent={t('no_data', {
            name: t('incidents.detail.formDetail.label.action_taken'),
          })}
          onDeselect={() => onDeselect(INCIDENT_ATTR_TYPES.ACTION)}
          ref={actionRef}
          disabled={data?.status?.code === EStatus.CLOSED || currentRole === 'RO' ? true : false}
          mode="multiple"
          size="large"
          className="select-item"
          onBlur={() =>
            handleChangeValue(form.getFieldValue('actions_taken'), 'actions_taken', 'blur')
          }
          removeIcon={
            <div className="close-select">
              <CloseIcon />
            </div>
          }
        >
          {optionsAction?.map((item: any, index: number) => {
            return (
              <Option value={item?.name} key={index} className="multile-item">
                {item?.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item></Form.Item>
    </Form>
  );
};

export default FormIncidentDetail;

/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, Space, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Reactivated from './components/Reactivate';
import { showSuccess } from 'components/standby-notice';
import OtherIncidentByUser from './components/OtherIncidentByUser';
import { useAppDispatch, useAppSelector, useMyOrganizationID, useReadOnly } from 'app/hooks';
import { IPayloadReactiveUsers } from 'pages/setting/interfaces';
import { loadingDeactiveUsersAction, reactiveUsersAction } from 'actions/users';
import moment from 'moment';
import InfinityLoadTable from 'components/common/InfinityLoadTable';

const ListDeactivatedUser = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const list: any[] = useAppSelector((state) => state.users.deactivatedUsers.list);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<string[]>([]);
  const [isVisibleReactivate, setIsVisibleReactivate] = React.useState<boolean>(false);
  const [isVisibleIncidentUser, setIsVisibleIncidentUser] = React.useState<boolean>(false);
  const [isLoadingReactive, setIsLoadingReactive] = React.useState<boolean>(false);
  const [selectedItem, setSelectedItem] = React.useState<any>(null);
  const [query, setQuery] = React.useState<any>(null);
  const [trigger, setTrigger] = React.useState(0);

  const myOrgId: string = useMyOrganizationID();

  const isJustView: boolean = useReadOnly();

  React.useEffect(() => {
    setQuery({
      organization_id: myOrgId,
    });
  }, [myOrgId, trigger]);

  const triggerReloadList = () => {
    setTrigger((pre) => pre + 1);
  };

  const onSelectChange = (rowKeys: React.Key[]) => setSelectedRowKeys(rowKeys as string[]);

  const handleVisibleReactivate = (item: any) => {
    setSelectedItem(item);
    setIsVisibleReactivate(true);
  };

  const handleCancelReactivate = () => {
    setSelectedItem(null);
    setIsVisibleReactivate(false);
  };

  const handleVisibleIncidentUser = (item: any) => {
    setSelectedItem(item);
    setIsVisibleIncidentUser(true);
  };

  const handleCancelIncidentUser = () => {
    setSelectedItem(null);
    setIsVisibleIncidentUser(false);
  };

  const handleReactivateMulti = () => {
    setIsVisibleReactivate(true);
  };

  const onReactivate = () => {
    setIsLoadingReactive(true);

    const userIds: string[] = selectedItem ? [selectedItem?._id] : selectedRowKeys;

    const payload: IPayloadReactiveUsers = {
      userIds,
      onSuccess: () => {
        triggerReloadList();
        setIsLoadingReactive(false);
        handleCancelReactivate();
        setSelectedRowKeys([]);
        showSuccess(t('settingPage.deactivatedUser.reactivateSuccess'));
      },
      onError: (errorCode: string) => {
        console.log('🚀 ~ errorCode', errorCode);
        setIsLoadingReactive(false);
      },
    };

    return dispatch(reactiveUsersAction(payload));
  };

  const getNumberUsers = (): number => {
    if (selectedItem) {
      return 1;
    }

    return selectedRowKeys.length;
  };

  const columns: any = [
    {
      title: t('settingPage.deactivatedUser.appId'),
      dataIndex: 'appId',
      width: '20%',
      // ellipsis: {
      //   showTitle: false,
      // },
      render: (_: any, record: any) => {
        const appId: string = record?.device_user_id?._id;
        return (
          <Tooltip placement="bottomLeft" title={appId}>
            <Typography.Link className="app-id" onClick={() => handleVisibleIncidentUser(record)}>
              {appId}
            </Typography.Link>
          </Tooltip>
        );
      },
    },
    {
      title: t('settingPage.deactivatedUser.numIncidents'),
      dataIndex: 'incident_number',
      width: '10%',
      ellipsis: {
        showTitle: false,
      },
      render: (incident_number: number) => {
        return (
          <Tooltip placement="bottomLeft" title={incident_number}>
            <Typography.Text>{incident_number}</Typography.Text>
          </Tooltip>
        );
      },
    },
    {
      title: t('settingPage.deactivatedUser.org'),
      dataIndex: 'organization',
      width: '20%',
      ellipsis: {
        showTitle: false,
      },
      render: (_: any, record: any) => {
        const orgName: string = record?.active_org_id?.name;
        return (
          <Tooltip placement="bottomLeft" title={orgName}>
            <Typography.Text>{orgName}</Typography.Text>
          </Tooltip>
        );
      },
    },
    {
      title: t('settingPage.deactivatedUser.deactivatedBy'),
      dataIndex: 'deactivatedBy',
      width: '20%',
      ellipsis: {
        showTitle: false,
      },
      render: (_: any, record: any) => {
        const fullName: string = `${record?.user_deactive?.last_name || ''} ${
          record?.user_deactive?.first_name || ''
        }`;
        return (
          <Tooltip placement="bottomLeft" title={fullName}>
            <Typography.Text> {fullName}</Typography.Text>
          </Tooltip>
        );
      },
    },
    {
      title: t('settingPage.deactivatedUser.deactivatedDate'),
      dataIndex: 'deactive_date',
      width: '15%',
      ellipsis: {
        showTitle: false,
      },
      render: (deactive_date: string, record: any) => {
        const fullTime = deactive_date
          ? moment(deactive_date).format('YYYY/MM/DD HH:mm')
          : moment(record.updatedAt).format('YYYY/MM/DD HH:mm');

        return (
          <Tooltip placement="bottomLeft" title={fullTime}>
            <Typography.Text>{fullTime}</Typography.Text>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: 'action',
      render: (_: any, record: any) => (
        <Typography.Link
          className="txt-reactivate"
          onClick={() => handleVisibleReactivate(record)}
          disabled={isJustView}
        >
          {t('settingPage.deactivatedUser.reactivate')}
        </Typography.Link>
      ),
      align: 'right',
    },
  ];

  return (
    <div className="list-deactivated-user">
      {selectedRowKeys.length > 0 && (
        <Space className="checkable-action-container" size={10}>
          <Space className="selected-txt-block" size={4}>
            <Typography.Text className="txt-selected">
              {t('settingPage.deactivatedUser.selected', {
                count: selectedRowKeys.length,
              })}
            </Typography.Text>
          </Space>
          {!isJustView && (
            <Button className="btn-reactivate" onClick={handleReactivateMulti}>
              {t('settingPage.deactivatedUser.reactivate')}
            </Button>
          )}
        </Space>
      )}
      <InfinityLoadTable
        dataSource={list}
        columns={columns}
        className="table-broadcast"
        rowSelection={{
          selectedRowKeys,
          onChange: onSelectChange,
        }}
        scroll={{ y: 'calc(100vh - 290px)' }}
        loadDataAction={loadingDeactiveUsersAction}
        query={query}
        dataType={t('list.empty.deactiveusers')}
      />

      {/* Modal Reactivate */}
      <Reactivated
        isVisible={isVisibleReactivate}
        onCancel={handleCancelReactivate}
        isLoading={isLoadingReactive}
        numberUsers={getNumberUsers()}
        onReactivate={onReactivate}
      />

      {/* Modal Other Incident By User */}
      <OtherIncidentByUser
        isVisible={isVisibleIncidentUser}
        onCancel={handleCancelIncidentUser}
        selectedItem={selectedItem}
      />
    </div>
  );
};

export default React.memo(ListDeactivatedUser);

/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Form, DatePicker, Row, Col, Dropdown, Select, Button, Typography } from 'antd';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { Organizations } from 'components/common';
import { useAppSelector } from 'app/hooks';
import { apiGetListIncidentAttrByOrg, INCIDENT_ATTR_TYPES } from 'api/incident-attr';
import { IPayloadGetListIncidentAttrByOrg } from 'pages/setting/interfaces';
import { IExportBulkIncidentsFormValues, IQueryBulkIncidents } from 'pages/reports/interfaces';
import { API_URL } from 'config';
import { storage } from 'utils';
import uniqBy from 'lodash/uniqBy';
import locale from 'antd/es/date-picker/locale/ja_JP';
import { CloseCircleFilled } from '@ant-design/icons';

const { RangePicker } = DatePicker;
const { Option } = Select;

const BulkIncidents = () => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();

  const initOptionOrganization: any[] = [
    { label: t('settingPage.adminUser.allCharge'), value: 'all' },
  ];
  const initOptionStatus: any[] = [
    { label: t('reports.bulkIncidents.status.placeholder'), value: '' },
  ];

  const showHidden: boolean = useAppSelector((state) => state.auth.showHidden);
  const permissionOrganizations: any[] = useAppSelector(
    (state) => state.auth.permissionOrganizations
  );

  const [isVisibleSelectOrg, setIsVisibleSelectOrg] = React.useState<boolean>(false);
  const [optionsOrganizations, setOptionsOrganizations] =
    React.useState<any[]>(initOptionOrganization);
  const [optionsStatus, setOptionsStatus] = React.useState<any[]>(initOptionStatus);

  const loadingStatusByOrg = React.useCallback(async (organization_id: string = '') => {
    const query: IPayloadGetListIncidentAttrByOrg['query'] = {
      organization_id,
      type: INCIDENT_ATTR_TYPES.STATUS,
    };

    const getListStatus = await apiGetListIncidentAttrByOrg(query);

    if (Array.isArray(getListStatus?.data?.values)) {
      const newOptionsStatus: any[] = getListStatus.data.values.map((value: any) => {
        return {
          value: value?.name,
          label: value?.name,
        };
      });

      const uniqOptions: any[] = uniqBy(newOptionsStatus, 'value');

      setOptionsStatus([...initOptionStatus, ...uniqOptions]);
    }
  }, []);

  React.useEffect(() => {
    loadingStatusByOrg();
  }, []);

  const extraRanges = (): any => {
    return {
      [t('reports.bulkIncidents.today')]: [moment(), moment()],
      [t('reports.bulkIncidents.yesterday')]: [
        moment().subtract(1, 'days'),
        moment().subtract(1, 'days'),
      ],
      [t('reports.bulkIncidents.last7days')]: [moment().subtract(7, 'days'), moment()],
      [t('reports.bulkIncidents.last30days')]: [moment().subtract(30, 'days'), moment()],
      [t('reports.bulkIncidents.thisMonth')]: [moment().startOf('month'), moment().endOf('month')],
      [t('reports.bulkIncidents.lastMonth')]: [
        moment().subtract(1, 'months').startOf('month'),
        moment().subtract(1, 'months').endOf('month'),
      ],
      [t('reports.bulkIncidents.onceYear')]: [moment().startOf('year'), moment().endOf('year')],
    };
  };

  const handleVisibleOrg = (visible: boolean) => {
    setIsVisibleSelectOrg(visible);
  };

  const handleChangeOrganization = (value: string) => {
    if (!value) {
      form.setFieldsValue({ org_id: '', status: '' });
    }
  };

  const handleChangeStatus = (value: string) => {
    if (!value) {
      form.setFieldsValue({ status: '' });
    }
  };

  const onSelectedOrganization = React.useCallback(
    (selectedItem: any) => {
      handleVisibleOrg(false);
      if (selectedItem) {
        const { key } = selectedItem;
        let organizationName: string = '';
        let organizationValue: string = '';

        if (Array.isArray(permissionOrganizations)) {
          const [orgId, schoolId, gradeId] = key.split('-');

          if (orgId) {
            const findOrg: any = permissionOrganizations.find((item: any) => item._id === orgId);
            if (findOrg) {
              organizationName = findOrg.name;
              organizationValue = findOrg._id;

              if (schoolId && Array.isArray(findOrg?.children)) {
                const findSchool = findOrg.children.find((item: any) => item._id === schoolId);

                if (findSchool) {
                  organizationName = `${findOrg.name} > ${findSchool.name}`;
                  organizationValue = findSchool._id;

                  if (gradeId && Array.isArray(findSchool?.children)) {
                    const findGrade = findSchool.children.find((item: any) => item._id === gradeId);

                    if (findGrade) {
                      organizationName = `${findOrg.name} > ${findSchool.name} > ${findGrade.name}`;
                      organizationValue = findGrade._id;
                    }
                  }
                }
              }
            }
          }
        }

        setOptionsOrganizations([
          { label: organizationName, value: organizationValue },
          ...optionsOrganizations,
        ]);

        /**
         * Has value org_id, will call api list @status by @org_id
         * Default set status to - All Status - if change organization
         */

        form.setFieldsValue({ org_id: organizationValue, status: '' });
        loadingStatusByOrg(organizationValue);
      } else {
        form.setFieldsValue({ org_id: 'all', status: '' });
      }
    },
    [permissionOrganizations]
  );

  const onFinish = ({ org_id, range, status }: IExportBulkIncidentsFormValues) => {
    let fromDate: string = '';
    let toDate: string = '';
    if (Array.isArray(range)) {
      const [startDate, endDate]: Moment[] = range;

      fromDate = moment(startDate).format('YYYY/MM/DD');
      toDate = moment(endDate).format('YYYY/MM/DD');
    }

    const token: string = storage.getToken();

    const query: IQueryBulkIncidents | any = {
      lang: i18n.language,
      show_hidden: showHidden,
      from_date: fromDate,
      to_date: toDate,
      standby_token: token,
    };
    if (org_id && org_id !== 'all') {
      query.org_id = org_id;
    }

    if (status) {
      query.status = status;
    }
    const queryString: string = new URLSearchParams(query).toString();
    const urlDownload: string = `${API_URL}/admin/reports/incident?${queryString}`;
    window.open(urlDownload);
  };

  return (
    <div className="bulk-incidents">
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={{ status: '' }}
        onFinish={onFinish}
      >
        <Row>
          <Col span={12}>
            <Form.Item
              name="range"
              label={t('reports.bulkIncidents.dateRange')}
              rules={[{ required: true, message: t('reports.bulkIncidents.dateRangeRequired') }]}
            >
              <RangePicker
                className="range-picker"
                size="large"
                placeholder={[t('reports.time.placeholder'), t('reports.time.placeholder')]}
                ranges={extraRanges()}
                dropdownClassName="dropdown-range-picker range-bulk"
                format="YYYY/MM/DD"
                locale={locale}
                disabledDate={(current) => moment() <= current}
                defaultPickerValue={[
                  moment().subtract(1, 'months'),
                  moment().subtract(1, 'months'),
                ]}
                allowClear
                clearIcon={<CloseCircleFilled style={{ color: 'red' }} />}
              />
            </Form.Item>
          </Col>
          <Col span={12} />

          <Col span={12}>
            <Typography.Text id="text" className="txt-drop">
              {t('incidents.list.org')}
            </Typography.Text>
            <Dropdown
              overlay={<Organizations onSelected={onSelectedOrganization} />}
              trigger={['click']}
              overlayClassName="dropdown-organizations"
              onVisibleChange={handleVisibleOrg}
              visible={isVisibleSelectOrg}
            >
              <Form.Item
                name="org_id"
                rules={[{ required: true, message: t('reports.bulkIncidents.orgRequired') }]}
              >
                <Select
                  size="large"
                  className="select-organization"
                  onClick={() => handleVisibleOrg(true)}
                  placeholder={t('reports.bulkIncidents.allOrg')}
                  dropdownRender={() => <div />}
                  allowClear
                  onChange={handleChangeOrganization}
                  notFoundContent={t('no_data', { name: t('list.empty.organization') })}
                >
                  {optionsOrganizations.map((org) => (
                    <Option key={org?.value} value={org.value}>
                      {org?.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Dropdown>
          </Col>
          <Col span={12} />

          <Col span={12}>
            <Form.Item name="status" label={t('reports.bulkIncidents.status')}>
              <Select
                size="large"
                className="select-status"
                placeholder={t('select')}
                allowClear
                onChange={handleChangeStatus}
              >
                {optionsStatus.map((status: any, index: number) => (
                  <Option key={`${status?.value}-${index}`} value={status.value}>
                    {status?.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Button type="primary" onClick={() => form.submit()} className="btn-export">
          {t('reports.activationVolume.export')}
        </Button>
      </Form>
    </div>
  );
};

export default React.memo(BulkIncidents);

import { Tabs } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Timeline from './components/Timeline';
import ActiveByOrg from './components/ActiveByOrg';
import { IActiveVolumeProps } from 'pages/reports/interfaces';

const { TabPane } = Tabs;

export enum ETabActivation {
  TIMELINE = 'TIMELINE',
  ORGANIZATION = 'ORGANIZATION',
}

const ActivationVolume = React.forwardRef((props: IActiveVolumeProps, ref: any) => {
  const { t } = useTranslation();

  const timelineRef: any = React.useRef();
  const activeOrgRef: any = React.useRef();
  const [activeKey, setActiveKey] = React.useState<ETabActivation>(ETabActivation.TIMELINE);

  React.useImperativeHandle(ref, () => ({
    getFilterTimelineValues: () =>
      activeKey === ETabActivation.TIMELINE && timelineRef?.current?.getFilterTimelineValues?.(),
    getFilterActiveUsersValues: () =>
      activeKey === ETabActivation.ORGANIZATION &&
      activeOrgRef?.current?.getFilterActiveUsersValues?.(),
  }));

  const handleChangeTab = (key: string) => setActiveKey(key as ETabActivation);

  return (
    <div className="activity-volume">
      <Tabs className="activation-volume-tabs" onChange={handleChangeTab} activeKey={activeKey}>
        <TabPane
          tab={t('reports.activationVolume.activationTimeline')}
          key={ETabActivation.TIMELINE}
        >
          <Timeline ref={timelineRef} activeKey={activeKey} />
        </TabPane>
        <TabPane tab={t('reports.activationVolume.organization')} key={ETabActivation.ORGANIZATION}>
          <ActiveByOrg ref={activeOrgRef} activeKey={activeKey} />
        </TabPane>
      </Tabs>
    </div>
  );
});

export default React.memo(ActivationVolume);

import { Modal, Button, Typography, Space } from 'antd';
import { IDeactiveCodeProps } from 'pages/setting/interfaces';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

const DeactiveCode = ({
  isVisible,
  onCancel,
  code,
  handleDeactive,
  selectedItem,
  isLoading,
}: IDeactiveCodeProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      maskClosable={false}
      title={t('settingPage.ogSetup.deactiveAccessCode')}
      centered
      visible={isVisible}
      onCancel={onCancel}
      footer={[
        <Button
          key="large"
          type="primary"
          className="btn-download"
          size="large"
          onClick={() => handleDeactive(code)}
          loading={isLoading}
        >
          {t('settingPage.ogSetup.deactiveThisCode')}
        </Button>,
        <Button key="cancel" className="btn-close" size="large" onClick={onCancel}>
          {t('common.cancelBtn')}
        </Button>,
      ]}
      className="md-deactive-code"
    >
      <Space size={20} direction="vertical" className="space-des">
        <Typography.Text className="txt-deactive  break-line-localize">
          {t('settingPage.ogSetup.deactiveSure1', { code })}
        </Typography.Text>
      </Space>
    </Modal>
  );
};

export default memo(DeactiveCode);

export interface INotificationSelector {
  isVisibleNoticeDetail: boolean;
  isVisibleSystemAlert: boolean;
  typeNoticeResponse: ETypeNoticeResponse;
}

export enum ETypeNotifications {
  NEW_INCIDENT = 'NEW_INCIDENT',
  NEW_MESSAGE = 'NEW_MESSAGE',
  ASSIGNED = 'INCIDENT_ASSIGNEE',
  CMS_SYSTEM = 'CMS_SYSTEM',
}

export interface INotificationDetailProps {
  isVisible: boolean;
  onCancel: () => void;
  selectedItem: any;
}

export interface ISystemAlertProps extends INotificationDetailProps {}

export interface IListNotificationProps {
  onCancelPopUp: () => void;

  // option for pop up, trigger loading notifications when pop up show
  isVisiblePopUp?: boolean;
  callback?: () => void;
  isReload?: boolean;
  isPageNotification?: boolean;
}

// loading list notifications
export interface IQueryLoadingNotifications {
  is_view?: string;
  page_size: number;
  page_number: number;
}
export interface IPayloadLoadingNotifications {
  query: IQueryLoadingNotifications;
  onSuccess?: (data: any) => void;
  onError?: (errorCode: string) => void;
}
export interface IActionLoadingNotifications {
  type: string;
  payload: IPayloadLoadingNotifications;
}

// loading count notifications
export interface IPayloadLoadingCountNotifications {
  onSuccess?: (data: any) => void;
  onError?: (errorCode: string) => void;
}
export interface IActionLoadingCountNotifications {
  type: string;
  payload: IPayloadLoadingCountNotifications;
}

// view list notifications
export interface IPayloadViewListNotifications {
  onSuccess?: (data: any) => void;
  onError?: (errorCode: string) => void;
}
export interface IActionViewListNotifications {
  type: string;
  payload: IPayloadViewListNotifications;
}

export interface IDataReadNotification {
  notification_id: string;
}
export interface IPayloadReadNotification {
  data: IDataReadNotification;
  onSuccess?: (data: any) => void;
  onError?: (errorCode: string) => void;
}

export interface IActionReadNotification {
  type: string;
  payload: IPayloadReadNotification;
}

// modal notice reponse
export enum ETypeNoticeResponse {
  NO_PERMISSION = 'NO_PERMISSION',
  EXPIRED = 'EXPIRED',
  MAINTANCE = 'MAINTANCE',
  DEACTIVE = 'DEACTIVE',
  NONE = 'NONE',
}
export interface IActionNoticeResponse {
  type: string;
  payload: ETypeNoticeResponse;
}

/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Form, Input, Select, Button } from 'antd';
import { INCIDENT_ATTR_TYPES } from 'api/incident-attr';
import { useAppSelector } from 'app/hooks';
import { IElementIncidentType } from 'pages/setting/interfaces';
import { useTranslation } from 'react-i18next';
import { PickerColor } from 'components/picker-color';
import { useState, useEffect } from 'react';
import trim from 'lodash/trim';
import ConfirmButton from 'components/common/ConfirmButton';

const { Option } = Select;
interface IProps {
  isOpen: boolean;
  handleOk: (data: any) => void;
  handleCancel?: () => void;
  type: INCIDENT_ATTR_TYPES;
  dataSelected?: IElementIncidentType | null;
  name: string;
}

const CRUD = ({ isOpen = false, type, name, dataSelected, handleOk, handleCancel }: IProps) => {
  const [form] = Form.useForm();

  const [colorPicker, setColorPicker] = useState(dataSelected?.color);
  const [validateColorPicker, setValidateColorPicker] = useState(false);
  const selectedOrgId: string = useAppSelector((state) => state.users.selectedOrgId);
  const [openSlcColor, setOpenSlcColor] = useState(false);
  const listAttrs: any[] = useAppSelector((state) => state.incidentAttr.listAttrs);
  const { t } = useTranslation();

  const handleCancelModal = () => {
    cbOpenSlcColor(false);
    setColorPicker(undefined);
    if (handleCancel) {
      handleCancel();
      return;
    }
  };
  const renderTitle = (): string => {
    return t(`settingPage.incidentManagement.${dataSelected ? 'edit' : 'add'}${type}.title`);
  };

  const getFamilyObject = (name: string) => listAttrs.find((ele: any) => ele.title === name);

  useEffect(() => {
    if (type === INCIDENT_ATTR_TYPES.PRIORITY) {
      setColorPicker(dataSelected?.color);
    }
  }, [dataSelected]);

  const handleSubmit = (values: any) => {
    let dataReq: any = { type };

    dataReq.action = dataSelected ? 'UPDATE' : 'ADD';
    dataReq.name = trim(values.name);
    dataReq.family = getFamilyObject(values.family);

    if (dataSelected) {
      dataReq.id = dataSelected._id;
      dataReq.organization_id = dataSelected.org_id;
    }

    if (dataReq.action === 'ADD') {
      dataReq.organization_id = selectedOrgId;
    }

    if (type === INCIDENT_ATTR_TYPES.PRIORITY) {
      if (!colorPicker) {
        setValidateColorPicker(true);
        return;
      }
      dataReq.color = colorPicker;
    }

    //reset color
    setColorPicker(undefined);
    return handleOk(dataReq);
  };

  const showChooseFamily = () => {
    switch (type) {
      case INCIDENT_ATTR_TYPES.TYPE:
        return true;
      default:
        return false;
    }
  };

  const showPickerColor = () => {
    switch (type) {
      case INCIDENT_ATTR_TYPES.PRIORITY:
        return true;
      default:
        return false;
    }
  };

  const onSubmit = async () => {
    if (type === INCIDENT_ATTR_TYPES.PRIORITY) {
      if (!colorPicker) {
        setValidateColorPicker(true);
      }
    }
    form.submit();
  };

  useEffect(() => {
    if (isOpen) {
      form.resetFields();
      setOpenSlcColor(true);
    }
    return () => setValidateColorPicker(false);
  }, [isOpen]);

  const cbOpenSlcColor = (value: boolean) => {
    if (value) {
      setOpenSlcColor(!value);
      return;
    }
    setOpenSlcColor(false);
  };

  return (
    <Modal
      footer={[
        <ConfirmButton
          className="btn-save"
          buttonTitle={t(
            `${
              dataSelected
                ? 'settingPage.incidentManagement.editTYPE.save'
                : 'settingPage.incidentManagement.addTYPE.addBtn'
            }`
          )}
          onClick={onSubmit}
          form={form}
          keyName="confirm"
          size="large"
          onValidate={() => {
            if (type === INCIDENT_ATTR_TYPES.PRIORITY) {
              if (!colorPicker) {
                setValidateColorPicker(true);
                return false;
              }
            }
            return true;
          }}
        />,
        <Button onClick={handleCancelModal} size="large" key="cancel" className="btn-cancel">
          {t('common.cancelBtn')}
        </Button>,
      ]}
      title={renderTitle()}
      maskClosable={false}
      visible={isOpen}
      className="modal modal-incident-attr-crud"
      width={625}
      centered
      onCancel={handleCancelModal}
    >
      <Form
        name="basic"
        layout="vertical"
        initialValues={{ name: dataSelected?.name, family: dataSelected?.family?.title }}
        onFinish={handleSubmit}
        autoComplete="off"
        form={form}
        requiredMark={false}
        scrollToFirstError
      >
        <div className="wrapper-content">
          <Form.Item
            label={t(`settingPage.incidentManagement.add${type}.name`)}
            name="name"
            rules={[
              {
                required: true,
                whitespace: true,
                message: t(`settingPage.incidentManagement.add${type}.name.required`),
              },
            ]}
          >
            <Input
              size="large"
              placeholder={t('settingPage.incidentManagement.add.placeholder', {
                name: t(`settingPage.incidentManagement.add${type}.name`),
              })}
              maxLength={70}
              className="input-name"
            />
          </Form.Item>

          {showChooseFamily() ? (
            <Form.Item
              name="family"
              label={`${t('settingPage.incidentManagement.crud.modal.form.family')}`}
              rules={[
                {
                  required: true,
                  message: t('settingPage.incidentManagement.crud.modal.form.family.required'),
                },
              ]}
            >
              <Select
                placeholder={t('settingPage.incidentManagement.crud.modal.form.family.placeholder')}
                size="large"
                className="select-family"
              >
                {listAttrs.map((ele, index) => (
                  <Option key={index} value={ele.title}>
                    {t(`default.incidentFamily.type_${ele.code}`)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : null}

          {showPickerColor() ? (
            <PickerColor
              triggerValidate={validateColorPicker}
              color={colorPicker || dataSelected?.color}
              callback={(color) => setColorPicker(color)}
              isOpenSlcColor={openSlcColor}
              cbCloseSlcColor={cbOpenSlcColor}
            />
          ) : null}
        </div>
      </Form>
    </Modal>
  );
};

export default CRUD;

export interface IListIncidentsProps {}

export enum EFilterStatusIncidents {
  ALL = 'ALL',
  INPROGRESS = 'INPROGRESS',
  PENDING = 'PENDING',
  CLOSED = 'CLOSED',
}
export enum EFilterResponseStatusIncidents {
  ALL = 'ALL',
  UNREAD = 'UNREAD',
  READ_UNANSWERED = 'UNANSWERED',
  READ_ANSWERED = 'ANSWERED',
}

export interface IFiltersIncidentsProps {
  onFilter: (values: IFiltersIncidentsFormValues) => void;
  handleVisibleCreate: () => void;
}

export interface IFiltersIncidentsFormValues {
  search: string;
  status: string;
  responseStatus: string;
  assigned: boolean;
}

export interface ICreateIncidentsProps {
  isVisible: boolean;
  onCancel: () => void;
}

// upload file - url upload
export interface IPayloadUrlUpload {
  organization_id: string;
  files: string[];
}

// loading incidents
export interface IQueryLoadingIncidents {
  organization_id: string;
  assigned_to_me: boolean;
  status: string;
  response_status: string;
  search: string;
  page_size: number;
  page_number: number;
  show_hidden: boolean;
  is_message_unread?: number;

  // for optional when loading at list notification
  new_incident?: boolean;
  sort_by?: string;
  sort_order?: number;
}
export interface IPayloadLoadingIncidents {
  query: IQueryLoadingIncidents;
  onSuccess?: (data: any) => void;
  onError?: (errorCode: string) => void;

  ignoreDispatchReducer?: boolean;
}
export interface IActionLoadingIncidents {
  type: string;
  payload: IPayloadLoadingIncidents;
}

// create incident api
export interface IFile {
  original_name: string;
  fileKey: string;
}
export interface ICreateIncidentsFormValues {
  org_id: string;
  description: string;
  files: IFile[];
  questions: any[];
}
export interface IPayloadCreateIncidents {
  values: ICreateIncidentsFormValues;
  onSuccess?: (data: any) => void;
  onError?: (errorCode: string) => void;
}
export interface ICreateIncidentsAction {
  type: string;
  payload: IPayloadCreateIncidents;
}

// total info incidents
export interface ITotalInfo {
  totalNew: number;
  totalPending: number;
  totalUnreadMessage: number;
}
export interface IQueryLoadingTotalInfoIncidents {
  organization_id: string;
  show_hidden: boolean;
}
export interface IPayloadLoadingTotalInfoIncidents {
  query: IQueryLoadingTotalInfoIncidents;
  onSuccess?: (data: any) => void;
  onError?: (errorCode: string) => void;
}
export interface IActionLoadingTotalInfoIncidents {
  type: string;
  payload: IPayloadLoadingTotalInfoIncidents;
}

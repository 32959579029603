/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, List, Space, Spin, Typography } from 'antd';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  ETypeNotifications,
  IListNotificationProps,
  IPayloadLoadingNotifications,
  IPayloadReadNotification,
} from '../interfaces';
import SystemAlert from './SystemAlert';
import NotificationDetail from './NotificationDetail';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  cancelNoticeDetailAction,
  cancelSystemAlertAction,
  loadingNotificationsAction,
  readNotificationAction,
  visibleNoticeDetailAction,
  visibleSystemAlertAction,
} from 'actions/notifications';
import SpinCenter from 'components/spin-center';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { UserOutlined } from '@ant-design/icons';
import sortBy from 'lodash/sortBy';
import { OS } from 'utils';
import { get } from 'lodash';
import { CONTENT_TYPES } from './types';
const { Text, Paragraph } = Typography;

const ListNotification = ({
  onCancelPopUp,
  isVisiblePopUp = false,
  callback,
  isReload,
  isPageNotification,
}: IListNotificationProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [selectedItem, setSelectedItem] = React.useState<any>(null);

  const [page, setPage] = React.useState<number>(1);
  const [notifications, setNotifications] = React.useState<any[]>([]);
  const [groups, setGroups] = React.useState<any[]>([]);
  const [hasMore, setHasMore] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const role: string = useAppSelector((state) => state.auth.role);
  const isOS: boolean = React.useMemo(() => role === OS, [role]);

  const typeSpecical: ETypeNotifications[] = [
    ETypeNotifications.NEW_INCIDENT,
    ETypeNotifications.NEW_MESSAGE,
  ];

  const isVisibleNoticeDetail: boolean = useAppSelector(
    (state) => state.notifications.isVisibleNoticeDetail
  );
  const isVisibleSystemAlert: boolean = useAppSelector(
    (state) => state.notifications.isVisibleSystemAlert
  );

  const firstTimeLoadingRef = React.useRef<boolean>(true);

  const loadingNotifications = React.useCallback(
    (pageNumber = 1) => {
      const payload: IPayloadLoadingNotifications = {
        query: {
          is_view: '1',
          page_number: pageNumber,
          page_size: 10,
        },
        onSuccess: (data: any) => {
          if (callback) callback();
          const newGroups: any[] = [...(data?.groups || [])];
          const newHasMore: boolean = !!data?.others?.pagination?.hasNextPage;
          let newNotifications: any[] = [];

          if (pageNumber === 1) {
            newNotifications = [...(data?.others?.list || [])];
          } else {
            /**
             * pagination when page greater than 1
             */
            newNotifications = [...notifications, ...(data?.others?.list || [])];
          }

          const sortGroups: any[] = sortBy(newGroups, [(group: any) => group._id]);

          if (!isOS) {
            setGroups(sortGroups);
          }

          setHasMore(newHasMore);
          setNotifications(newNotifications);
          setIsLoading(false);

          firstTimeLoadingRef.current = false;
        },
        onError: (errorCode: string) => {
          console.log('🚀 ~ errorCode', errorCode);
          setIsLoading(false);
        },
      };

      return dispatch(loadingNotificationsAction(payload));
    },
    [notifications]
  );

  React.useEffect(() => {
    if (isPageNotification) {
      loadingNotifications();
    } else {
      if (isVisiblePopUp && !firstTimeLoadingRef.current) {
        loadingNotifications();
      }
    }
  }, [isVisiblePopUp, isReload]);

  React.useEffect(() => {
    loadingNotifications();

    return () => {
      setNotifications([]);
      setGroups([]);
      setHasMore(false);
      setIsLoading(false);
      setPage(1);
    };
  }, []);

  React.useEffect(() => {
    if (page > 1) {
      loadingNotifications(page);
    }
  }, [page]);

  const renderAvatar = (item: any) => {
    let src: string = '';

    if (typeSpecical.includes(item?._id)) {
      switch (item._id) {
        case ETypeNotifications.NEW_INCIDENT:
          src = '/images/ic-incident.png';
          break;
        case ETypeNotifications.NEW_MESSAGE:
          src = '/images/ic-msg.png';
          break;
        default:
          break;
      }
    } else {
      switch (item?.type) {
        case ETypeNotifications.CMS_SYSTEM:
          src = '/images/ic-system.png';
          break;
        case ETypeNotifications.ASSIGNED:
          src = '/images/ic-avatar.png';
          break;
        default:
          break;
      }
    }

    return <Avatar className="avatar" src={src} icon={<UserOutlined />} />;
  };

  const renderTitle = (item: any) => {
    let title: string = '';

    if (typeSpecical.includes(item?._id)) {
      switch (item._id) {
        case ETypeNotifications.NEW_INCIDENT:
          title = t('notifications.newIncident.title', { total: item?.total || 0 });
          break;
        case ETypeNotifications.NEW_MESSAGE:
          title = t('notifications.newMessage.title', { total: item?.total || 0 });
          break;
        default:
          break;
      }
    } else {
      switch (item?.type) {
        case ETypeNotifications.CMS_SYSTEM:
          title = get(item, 'data.cms_content_management_id.title');
          break;
        case ETypeNotifications.ASSIGNED:
          title = t('notifications.assigned.title', {
            name: `${item?.sender?.last_name || ''} ${item?.sender?.first_name || ''}`,
          });
          break;
        default:
          break;
      }
    }

    return <Paragraph ellipsis={{ rows: 1, expandable: false, symbol: '' }}>{title}</Paragraph>;
  };

  const handelClickItem = (item: any) => {
    setSelectedItem(item);
    onCancelPopUp();

    if (typeSpecical.includes(item?._id)) {
      switch (item._id) {
        case ETypeNotifications.NEW_INCIDENT:
        case ETypeNotifications.NEW_MESSAGE:
          dispatch(visibleNoticeDetailAction());
          break;
        default:
          break;
      }
    } else {
      const newNotifications: any[] = [...notifications];

      const indexItem: number = newNotifications.findIndex((e: any) => e?._id === item?._id);

      if (indexItem >= 0) {
        newNotifications.splice(indexItem, 1, {
          ...newNotifications[indexItem],
          read_at: moment().format(),
        });
      }

      setNotifications(newNotifications);

      switch (item?.type) {
        case ETypeNotifications.CMS_SYSTEM:
          dispatch(visibleSystemAlertAction());
          break;
        case ETypeNotifications.ASSIGNED:
          readMessage(item?._id);
          const win: any = window.open(`/admin/incidents/${item?.data?.incident_id}`, '_blank');
          win.focus();
          break;
        default:
          break;
      }
    }
  };

  const readMessage = (_id: string) => {
    const payload: IPayloadReadNotification = {
      data: {
        notification_id: _id,
      },
    };
    return dispatch(readNotificationAction(payload));
  };

  const handleCancelDetail = () => {
    setSelectedItem(null);
    dispatch(cancelNoticeDetailAction());
  };

  const handleCancelSystemAlert = () => {
    setSelectedItem(null);
    dispatch(cancelSystemAlertAction());
  };

  const fetchMoreData = () => {
    setPage((prevPage: number) => (prevPage = prevPage + 1));
  };

  const renderMessage = (item) => {
    const type = get(item, 'data.cms_content_management_id.content_type');
    if (type === CONTENT_TYPES.NOTICE) {
      return get(item, 'data.cms_content_management_id.description');
    }

    return get(item, 'data.cms_content_management_id.content');
  };

  return (
    <div className="list-notifications">
      <Spin spinning={isLoading}>
        <InfiniteScroll
          dataLength={notifications.length}
          loader={<SpinCenter includePadding={false} />}
          next={fetchMoreData}
          hasMore={hasMore}
          height={'calc(100vh - 200px)'}
        >
          {groups.length > 0 && (
            <List
              locale={{ emptyText: t('no_data', { name: t('notifications.popUp.title') }) }}
              itemLayout="horizontal"
              dataSource={groups}
              renderItem={(item: any) => (
                <List.Item onClick={() => handelClickItem(item)}>
                  <List.Item.Meta
                    avatar={renderAvatar(item)}
                    title={renderTitle(item)}
                    description={
                      <span style={{ marginTop: 20 }}>
                        {item?.last_time && moment(item.last_time).format('YYYY/MM/DD HH:mm A')}
                      </span>
                    }
                  />
                </List.Item>
              )}
            />
          )}
          {notifications.length > 0 && (
            <List
              locale={{ emptyText: t('no_data', { name: t('notifications.popUp.title') }) }}
              itemLayout="horizontal"
              dataSource={notifications}
              renderItem={(item: any) => {
                const isRead: boolean = !!item?.read_at;

                return (
                  <List.Item onClick={() => handelClickItem(item)}>
                    <List.Item.Meta
                      avatar={renderAvatar(item)}
                      title={
                        <Space direction="vertical" size={4}>
                          {renderTitle(item)}
                          {item?.type === ETypeNotifications.ASSIGNED && (
                            <Text className="incident-number">{item?.data?.incident_number}</Text>
                          )}
                          <Paragraph ellipsis={{ rows: 2, expandable: false, symbol: '' }}>
                            {renderMessage(item)}
                          </Paragraph>
                        </Space>
                      }
                      description={
                        <span style={{ marginTop: 20 }}>
                          {moment(item?.createdAt).format('YYYY/MM/DD HH:mm A')}
                        </span>
                      }
                    />
                    {!isRead && <div className="is-read" />}
                  </List.Item>
                );
              }}
            />
          )}
        </InfiniteScroll>
      </Spin>

      {/* Modal Notification Detail */}
      {isVisibleNoticeDetail && selectedItem ? (
        <NotificationDetail
          isVisible={isVisibleNoticeDetail && selectedItem}
          onCancel={handleCancelDetail}
          selectedItem={selectedItem}
        />
      ) : null}

      {/* Modal System Alert */}
      {isVisibleSystemAlert && selectedItem ? (
        <SystemAlert
          isVisible={isVisibleSystemAlert && selectedItem}
          onCancel={handleCancelSystemAlert}
          selectedItem={selectedItem}
        />
      ) : null}
    </div>
  );
};

export default React.memo(ListNotification);

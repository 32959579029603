import { useEffect, memo, useState } from 'react';
import { Button, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { ILock60MinsProps } from '../interfaces';

const Lock60Mins = ({ returnToLogin, mins = 60 }: ILock60MinsProps) => {
  const { t } = useTranslation();
  const goToLogin = () => returnToLogin();

  const [timeLocked, setTimeLocked] = useState<number>(mins);

  useEffect(() => {
    if (timeLocked > 0) {
      setTimeout(() => setTimeLocked(timeLocked - 1), 60000);
    } else {
      setTimeLocked(0);
    }
  }, [timeLocked]);

  useEffect(() => {
    setTimeLocked(mins);
  }, [mins]);

  return (
    <div className="lock-block">
      <div className="two-fac-icon">
        <img src="/images/ic-lock.png" alt="Lock" />
      </div>

      <div style={{ textAlign: 'center' }}>
        <Typography.Text className="txt-title">{t('loginPage.lockTitle')}</Typography.Text>
      </div>

      <div className="description">
        <Typography.Text className="txt-description">
          {t('loginPage.lockDescription', { min: timeLocked })}
        </Typography.Text>
      </div>

      <Button type="primary" block size="large" className="btn-login" onClick={goToLogin}>
        {t('loginPage.returnToLogin')}
      </Button>
    </div>
  );
};

export default memo(Lock60Mins);

/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Form, Input, Button } from 'antd';
import { forwardRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { validateIpAddress } from 'utils';
import { CRUDTYPE } from './List';

interface IProps {
  isOpen: boolean;
  handleOk: (data: any) => void;
  handleCancel?: () => void;
  valueEdit: string;
  data: CRUDTYPE;
}

const CRUD = ({ isOpen = false, data, valueEdit, handleOk, handleCancel }: IProps, ref) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const handleCancelModal = () => {
    handleCancel && handleCancel();
  };

  const renderTitle = (): string => {
    return t('settingPage.security.ip.title');
  };

  useEffect(() => {
    form.setFieldsValue({ ip: valueEdit });
  }, [isOpen, valueEdit]);

  const handleSubmit = (values: any) => {
    const dataform = {
      ...values,
      action: data.action,
      organization_id: data.organization_id,
      indexUpdate: data.indexUpdate,
    };

    handleOk(dataform);
  };

  return (
    <Modal
      footer={[
        <Button
          type="primary"
          onClick={() => form.submit()}
          size="large"
          className="btn-save"
          key="1"
        >
          {t(
            (data as any).action === 'ADD'
              ? t('settingPage.security.addIp.addBtn')
              : t('settingPage.security.editIp.save')
          )}
        </Button>,
        <Button onClick={handleCancelModal} size="large" className="btn-cancel" key="2">
          {t('common.cancelBtn')}
        </Button>,
      ]}
      title={renderTitle()}
      visible={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      className="modal modal-incident-attr-crud"
      centered
      width={800}
      maskClosable={false}
    >
      <Form
        ref={ref}
        name="basic"
        layout="vertical"
        initialValues={{ ip: data && (data as any).action === 'UPDATE' ? valueEdit : '' }}
        onFinish={handleSubmit}
        autoComplete="off"
        requiredMark={false}
        form={form}
      >
        <div className="wrapper-content">
          <Form.Item
            label={t('settingPage.security.ip.title')}
            name="ip"
            rules={[
              {
                required: true,
                message: t('settingPage.security.addIp.ip.required'),
              },
              () => ({
                validator(_rule, value) {
                  if (!value) return Promise.reject();

                  const isIP: boolean = validateIpAddress(value);

                  if (isIP) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error(t('settingPage.security.addIp.ip.format')));
                },
              }),
            ]}
          >
            <Input size="large" placeholder={t('settingPage.security.addIp.ip.placeholder')} />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default forwardRef(CRUD);

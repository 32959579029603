/* eslint-disable react-hooks/exhaustive-deps */
import { forgotPasswordAction, newPasswordAction } from 'actions/auth';
import { Button, Form, Input, notification, Typography, Spin } from 'antd';
import { useAppDispatch } from 'app/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { EMAIL_NOT_EXIST, isEmail, LINK_EXPIRED, TOKEN_INVALID } from 'utils';
import {
  IForgotPasswordFormValues,
  IForgotPasswordProps,
  IPayloadForgotPassword,
} from '../interfaces';
import PopupOk from './PopupOk';
import Wrapper from './Wrapper';
import { API_URL } from 'config';
import axios from 'axios';
import { InfoCircleFilled, LeftOutlined } from '@ant-design/icons';

const ForgotPassword = ({ isCreateNewPassword = false }: IForgotPasswordProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { search } = useLocation();
  const queryString = new URLSearchParams(search);

  const token: string = queryString.get('token') || '';
  const emailQs: string = queryString.get('email') || '';
  const typeQs: string = queryString.get('type') || '';
  const isTypeSetNew: boolean = typeQs === 'SET_NEW';
  const [isLoadingPage, setIsLoadingPage] = React.useState<boolean>(false);

  const [isShowPopup, setIsShowPopup] = React.useState<boolean>(false);

  // const isLoading: boolean = useAppSelector((state) => state.auth.isLoading);

  const checkToken = React.useCallback(async () => {
    setIsLoadingPage(true);
    const queryString = new URLSearchParams(search);
    const token: string | null = queryString.get('token');

    try {
      await axios.get(
        `${API_URL}/admin/check-token?token=${token}&token_type=${
          isTypeSetNew ? 'INVITE_ADMIN_USER' : 'FORGOT'
        }`
      );
    } catch (error: any) {
      if (error) {
        const { response } = error;
        if (response?.status === 400) {
          if (response?.data?.error_code === LINK_EXPIRED) {
            return history.push({
              pathname: '/admin/link-expired',
            });
          }
        }
      } else {
        notification.error({
          message: 'Internal Server Error',
        });
      }
    } finally {
      setIsLoadingPage(false);
    }
  }, []);

  React.useEffect(() => {
    return () => {
      setIsShowPopup(false);
    };
  }, []);

  React.useEffect(() => {
    if (token) {
      checkToken();
    }
  }, []);

  const onForgotPasswordError = (errorCode: string) => {
    switch (errorCode) {
      case EMAIL_NOT_EXIST:
        if (isCreateNewPassword) {
          // notification.error({ message: t('loginPage.emailNotExist') });
          history.push({
            pathname: '/admin/link-expired',
          });
          break;
        }
        form.setFields([
          {
            name: 'email',
            errors: [t('loginPage.emailNotExist')],
          },
        ]);
        break;
      case TOKEN_INVALID:
        notification.error({ message: t('fgpwPage.tokenExpired') });
        break;
      default:
        break;
    }
  };

  const onFinish = ({ email, new_password, confirm_password }: IForgotPasswordFormValues) => {
    const payload: IPayloadForgotPassword = {
      values: { email },
      onSuccess: () => {
        setIsShowPopup(true);
      },
      onError: (errorCode: string) => {
        console.log('🚀 ~ errorCode', errorCode);

        onForgotPasswordError(errorCode);
      },
    };

    if (!isCreateNewPassword) {
      return dispatch(forgotPasswordAction(payload));
    }

    /**
     * Create new password
     */

    payload.values = {
      email: emailQs,
      token,
      new_password,
      confirm_password,
      is_create: isTypeSetNew,
    };

    return dispatch(newPasswordAction(payload));
  };

  const handleReturnLogin = () => history.push('/auth/login');

  return (
    <Spin spinning={isLoadingPage} size="small">
      <Wrapper>
        {isShowPopup ? (
          <PopupOk
            title={
              isCreateNewPassword
                ? t(`fgpwPage.${isTypeSetNew ? 'newPwCreated2' : 'newPwSet'}`)
                : t('fgpwPage.pwLinkSent')
            }
            description={!isCreateNewPassword ? t('fgpwPage.checkYourInbox') : ''}
            isReturnLogin
            icon="/images/ic-checked.png"
            handleOk={handleReturnLogin}
          />
        ) : (
          <div className="forgot-pw">
            <Button style={{}} onClick={() => history.goBack()}>
              <LeftOutlined />
              {t('button.Back.title')}
            </Button>
            <div className="two-fac-icon">
              <img src="/images/ic-forgot-pw.png" alt="Forgot password" />
            </div>

            <div className="txt-title-main">
              <Typography.Text className="txt-title">
                {isCreateNewPassword
                  ? t(`fgpwPage.${isTypeSetNew ? 'setPassword' : 'createNewPw'}`)
                  : t('fgpwPage.forgotPassword')}
              </Typography.Text>
            </div>

            {!isCreateNewPassword && (
              <div className="description">
                <Typography.Text className="txt-description">{t('fgpwPage.alert')}</Typography.Text>
              </div>
            )}

            <Form
              onFinish={onFinish}
              layout="vertical"
              className={isCreateNewPassword ? 'form-new-pw' : ''}
              form={form}
              scrollToFirstError
              autoComplete="off"
            >
              {isCreateNewPassword ? (
                <div>
                  <Form.Item
                    label={isTypeSetNew ? t('loginPage.password') : t('fgpwPage.newPassword')}
                    name="new_password"
                    rules={[
                      {
                        required: true,
                        message: isTypeSetNew
                          ? t('loginPage.passwordRequired')
                          : t('fgpwPage.newPasswordRequired'),
                      },
                      {
                        pattern: new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/),
                        message: t('fgpwPage.password.wrong_format'),
                      },
                    ]}
                    tooltip={{
                      title: `${t('loginPage.passwordTooltip')}`,
                      icon: <InfoCircleFilled />,
                    }}
                  >
                    <Input.Password
                      size="large"
                      placeholder={t('fgpwPage.passwordPlaceholder')}
                      className="input-password"
                      maxLength={16}
                    />
                  </Form.Item>

                  <Form.Item
                    label={
                      isTypeSetNew
                        ? t('fgpwPage.reEnterSetPassword')
                        : t('fgpwPage.reEnterNewPassword')
                    }
                    name="confirm_password"
                    rules={[
                      { required: true, message: t('fgpwPage.reEnterNewPasswordRequired') },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('new_password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(t('fgpwPage.twoPwNotMatch')));
                        },
                      }),
                    ]}
                    dependencies={['new_password']}
                  >
                    <Input.Password
                      size="large"
                      placeholder={t('fgpwPage.reEnterNewPasswordPlaceholder')}
                      className="input-password"
                      maxLength={16}
                    />
                  </Form.Item>
                </div>
              ) : (
                <Form.Item
                  label={t('fgpwPage.email')}
                  name="email"
                  rules={[
                    { required: true, message: t('loginPage.emailRequired') },
                    () => ({
                      validator(_rule, value) {
                        if (!value) return Promise.reject();

                        const checkEmail: boolean = isEmail(value);

                        if (checkEmail) {
                          return Promise.resolve();
                        }

                        return Promise.reject(new Error(t('loginPage.emailFormat')));
                      },
                    }),
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={t('loginPage.emailPlaceholder')}
                    allowClear
                    className="input-email"
                    maxLength={256}
                  />
                </Form.Item>
              )}

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  size="large"
                  className="btn-rs-link"
                  // loading={isLoading}
                >
                  {isCreateNewPassword
                    ? t(`fgpwPage.${isTypeSetNew ? 'setPassword' : 'setNewPassword'}`)
                    : t('fgpwPage.sendResetLink')}
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}
      </Wrapper>
    </Spin>
  );
};

export default ForgotPassword;

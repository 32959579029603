/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Modal, Space, Tooltip, Typography, Table } from 'antd';
import React, { useRef, useState } from 'react';
import { ETypeNotifications, INotificationDetailProps } from '../interfaces';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  EFilterResponseStatusIncidents,
  IPayloadLoadingIncidents,
} from 'pages/incidents/interfaces';
import { FlagFilled, FlagOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { useAppDispatch } from 'app/hooks';
import { loadingIncidentsAtNoticeAction } from 'actions/incidents';
import { get } from 'lodash';

const NotificationDetail = ({ isVisible, onCancel, selectedItem }: INotificationDetailProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [incidents, setIncidents] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const hasNextPage = useRef<boolean>(true);
  const currentPage = useRef<number>(0);
  const [sortType, setSortType] = React.useState<string>('');
  const [ascOrder, setAscOrder] = React.useState<boolean>(true);

  const loadingIncidents = React.useCallback(
    (pageNumber = 1, sortType = 'sequence', sortOrder = -1) => {
      if (pageNumber === 1) {
        setIsLoading(true);
      }

      const payload: IPayloadLoadingIncidents = {
        query: {
          organization_id: '',
          page_number: pageNumber,
          page_size: 20,
          search: '',
          status: '',
          response_status:
            selectedItem?._id === ETypeNotifications.NEW_MESSAGE
              ? EFilterResponseStatusIncidents.UNREAD
              : '',
          assigned_to_me: false,
          show_hidden: true,
          is_message_unread: 1,
          new_incident: selectedItem?._id === ETypeNotifications.NEW_INCIDENT,
          sort_by: sortType,
          sort_order: sortOrder,
        },
        onSuccess: (data: any) => {
          let newIncidents: any[] = [];
          hasNextPage.current = data?.pagination.hasNextPage;
          if (pageNumber > 1) {
            newIncidents = [...incidents, ...data?.list];
          } else {
            newIncidents = [...data?.list];
          }
          if (currentPage.current !== data?.pagination.page) {
            currentPage.current = data?.pagination.page;
          }
          setIncidents(newIncidents);
          setIsLoading(false);
        },
        onError: (errorCode: string) => {
          console.log('🚀 ~ errorCode', errorCode);
          setIsLoading(false);
          onCancel();
        },
        ignoreDispatchReducer: true,
      };
      return dispatch(loadingIncidentsAtNoticeAction(payload));
    },
    [incidents, selectedItem]
  );

  React.useEffect(() => {
    if (isVisible && selectedItem) {
      loadingIncidents();
    }
  }, [isVisible, selectedItem]);

  React.useEffect(() => {
    return () => {
      setPage(1);
      setIncidents([]);
    };
  }, []);

  React.useEffect(() => {
    onLoadScroll();
  }, []);

  React.useEffect(() => {
    if (page > 1) {
      loadingIncidents(page);
    }
  }, [page]);

  const onLoadScroll = React.useCallback(() => {
    const tableContent: any = document.querySelector('.table-incident-notice .ant-table-body');
    if (tableContent) {
      tableContent.addEventListener('scroll', (event: any) => {
        const maxScroll: number = event.target.scrollHeight - event.target.clientHeight;
        const currentScroll: number = event.target.scrollTop;
        if (
          maxScroll - currentScroll <= 5 &&
          maxScroll !== 0 &&
          !isLoading &&
          hasNextPage.current
        ) {
          setPage(currentPage.current + 1);
        }
      });
    }
  }, [isLoading]);

  const handleClickRow = (item: any) => {
    onCancel();

    const defaultVisibleChat: boolean = selectedItem?._id === ETypeNotifications.NEW_MESSAGE;
    const indexMessageChat: number = new Date().valueOf();

    return history.push(`/admin/incidents/${item?._id}`, { defaultVisibleChat, indexMessageChat });
  };

  const handleClickSorter = (sorter: any) => {
    const newAscOrder = sorter.order === 'ascend';
    const type = sorter.field;
    if (sorter.order) {
      setSortType(type);
      setAscOrder(newAscOrder);
    } else {
      setSortType('');
      setAscOrder(newAscOrder);
    }
    const sortOrder = newAscOrder ? 1 : -1;
    loadingIncidents(1, type, sortOrder);
  };

  const columns: any[] = [
    {
      title: () => {
        return (
          <div className="table-header">
            <span
              className={
                sortType === 'sequence' ? (ascOrder === true ? 'upIcon' : 'downIcon') : 'normalIcon'
              }
            >
              {t('incidents.list.incident')}
            </span>
          </div>
        );
      },
      dataIndex: 'sequence',
      width: 104,
      className: 'incident-sequence',
      showSorterTooltip: false,
      ellipsis: {
        showTitle: false,
      },
      sorter: (a: any, b: any) => a.sequence - b.sequence,
      render: (sequence: number) => (
        <Tooltip placement="bottomLeft" title={sequence}>
          <Typography.Text className="txt-incident">{sequence}</Typography.Text>
        </Tooltip>
      ),
    },
    {
      title: t('incidents.list.msgs'),
      dataIndex: 'totalMessage',
      width: 96,
      render: (totalMessage: number, record: any) => {
        let src: string = '';
        let tooltip: string = '';
        switch (record?.response_status) {
          case EFilterResponseStatusIncidents.READ_ANSWERED:
            src = '/images/ic-read-anwered.png';
            tooltip = 'readAndAnwered';
            break;
          case EFilterResponseStatusIncidents.READ_UNANSWERED:
            src = '/images/ic-read-unanwered.png';
            tooltip = 'readAndUnanwered';
            break;
          case EFilterResponseStatusIncidents.UNREAD:
            if (totalMessage > 0) {
              src = '/images/ic-unread-dot.png';
            } else {
              src = '/images/ic-unread.png';
            }
            tooltip = 'unread';
            break;
          default:
            break;
        }
        return (
          <Space>
            {src && (
              <Tooltip placement="bottomLeft" title={t(`incidents.list.${tooltip}`)}>
                <img alt="" src={src} />
              </Tooltip>
            )}
            <Typography.Text>{totalMessage || 0}</Typography.Text>
          </Space>
        );
      },
    },
    {
      title: t('incidents.list.status'),
      dataIndex: 'status',
      width: 146,
      ellipsis: {
        showTitle: false,
      },
      render: (status: any) => {
        return (
          <Tooltip placement="bottomLeft" title={status?.name}>
            <Typography.Text>{status?.name}</Typography.Text>
          </Tooltip>
        );
      },
    },
    {
      title: t('incidents.list.description'),
      dataIndex: 'description',
      width: 339,
      ellipsis: {
        showTitle: false,
      },
      render: (record: string) => (
        <Tooltip placement="bottomLeft" title={record}>
          {record}
        </Tooltip>
      ),
    },
    {
      title: t('incidents.list.org'),
      dataIndex: 'organization',
      width: 300,
      ellipsis: {
        showTitle: false,
      },
      render: (_: any, record: any) => {
        const orgName: string | null = record?.org_id?.name;
        return (
          <Tooltip placement="bottomLeft" title={orgName}>
            {orgName}
          </Tooltip>
        );
      },
    },
    {
      title: () => {
        return (
          <div className="table-header">
            <span
              className={
                sortType === 'updatedAt'
                  ? ascOrder === true
                    ? 'upIcon'
                    : 'downIcon'
                  : 'normalIcon'
              }
            >
              {t('incidents.list.updated')}
            </span>
          </div>
        );
      },
      dataIndex: 'updatedAt',
      width: 125,
      sorter: (a: any, b: any) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
      showSorterTooltip: false,
      ellipsis: {
        showTitle: false,
      },
      render: (record: string) => {
        const text: string = moment(record).format('YYYY/MM/DD');
        return (
          <Tooltip placement="bottomLeft" title={text}>
            {text}
          </Tooltip>
        );
      },
    },
    {
      title: () => {
        return (
          <div className="table-header">
            <span
              className={
                sortType === 'createdAt'
                  ? ascOrder === true
                    ? 'upIcon'
                    : 'downIcon'
                  : 'normalIcon'
              }
            >
              {t('incidents.list.submitted')}
            </span>
          </div>
        );
      },
      dataIndex: 'createdAt',
      width: 125,
      sorter: (a: any, b: any) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      showSorterTooltip: false,
      ellipsis: {
        showTitle: false,
      },
      render: (record: string) => {
        const text: string = moment(record).format('YYYY/MM/DD');
        return (
          <Tooltip placement="bottomLeft" title={text}>
            {text}
          </Tooltip>
        );
      },
    },
    {
      title: t('incidents.list.priority'),
      dataIndex: 'priority',
      width: 80,
      align: 'center',
      ellipsis: {
        showTitle: false,
      },
      render: (priority: any) => {
        if (!priority || !priority.color) {
          return null;
        }
        return (
          <Tooltip placement="bottomLeft" title={priority?.name}>
            {priority?.color ? <FlagFilled style={{ color: priority?.color }} /> : <FlagOutlined />}
          </Tooltip>
        );
      },
    },
  ];

  const renderTitle = (): string => {
    switch (selectedItem?._id) {
      case ETypeNotifications.NEW_INCIDENT:
        return t('notifications.newIncident.title', { total: selectedItem?.total || 0 });
      case ETypeNotifications.NEW_MESSAGE:
        return t('notifications.newMessage.title', { total: selectedItem?.total || 0 });
      default:
        return '';
    }
  };

  const onRow = (item: any, index: any) => {
    const isUnread = get(item, 'status.code', '') === 1;
    let className = index % 2 === 0 ? 'row-odd' : '';
    className = isUnread ? `${className} row-bold` : className;
    return {
      className: className,
      onClick: () => handleClickRow(item),
    };
  };

  const onChange = (pagination, filters, sorter, extra) => {
    handleClickSorter(sorter);
  };

  return (
    <Modal
      className="modal md-detail-notification"
      width={1366}
      maskClosable={false}
      title={renderTitle()}
      visible={isVisible}
      onCancel={onCancel}
      centered
      footer={[
        <Button key="cancel" className="btn-close" size="large" onClick={onCancel}>
          {t('common.popup.closeBtn')}
        </Button>,
      ]}
    >
      <Table
        columns={columns}
        dataSource={incidents}
        className="table-incident-notice"
        onRow={onRow}
        scroll={{ y: 'calc(100vh - 290px)' }}
        pagination={false}
        rowKey="_id"
        locale={{ emptyText: t('no_data', { name: t('list.empty.incident') }) }}
        loading={isLoading}
        onChange={onChange}
      />
    </Modal>
  );
};

export default React.memo(NotificationDetail);

/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, Space, Switch, Table, Tooltip, Typography } from 'antd';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { InfoCircleFilled, MenuOutlined, PlusOutlined } from '@ant-design/icons';
import { arrayMoveImmutable } from 'array-move';
import { useTranslation } from 'react-i18next';
import FormIncidentQs from './components/FormIncidenQs';
import { useAppDispatch, useAppSelector, useMyOrganizationID, useReadOnly } from 'app/hooks';
import {
  EActionIncidentQuestion,
  EQuestionType,
  ICreateIncidentQuestionFormValues,
  IPayloadChangeVisibilityIncidentQuestion,
  IPayloadCreateIncidentQuestion,
  IPayloadDeleteIncidentQuestion,
  IPayloadDragIncidentQuestion,
  IPayloadEditIncidentQuestion,
  IPayloadLoadingIncidentQs,
} from 'pages/setting/interfaces';
import {
  changeVisibilityIncidentQuestionAction,
  createIncidentQuestionAction,
  deleteIncidentQuestionAction,
  dragIncidentQuestionAction,
  editIncidentQuestionAction,
  loadingIncidentQuestionAction,
} from 'actions/incident-question';
import trim from 'lodash/trim';
import ConfirmDeleteModal from 'components/common/ConfirmDeleteModal';
import { ModalConfirm } from 'components/modal-confirm';
import { showError, showSuccess } from 'components/standby-notice';

export const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
));
export const SortableItem = SortableElement((props: any) => <tr {...props} />);
export const SortableContainers = SortableContainer((props: any) => <tbody {...props} />);

interface IIncidentQuestionProps {
  selectedOrg: any;
}

const IncidentQuestion = ({ selectedOrg }: IIncidentQuestionProps) => {
  const { t } = useTranslation();

  const formRef: any = React.createRef();
  const childFormRef: any = React.useRef();

  const dispatch = useAppDispatch();

  const questions: any[] = useAppSelector((state) => state.questions.questions);
  const isLoading: boolean = useAppSelector((state) => state.questions.isLoading);

  const isJustView: boolean = useReadOnly();
  const myOrgId: string = useMyOrganizationID();
  const [dataSource, setDataSource] = React.useState<any[]>([]);
  const [isVisibleForm, setIsVisibleForm] = React.useState<boolean>(false);
  const [isVisibleDelete, setIsVisibleDelete] = React.useState<boolean>(false);
  const [isDisabled, setIsDisabled] = React.useState<boolean>(false);
  const [selectedItem, setSelectedItem] = React.useState<any>(null);
  const myOrg = useAppSelector((state) => state.users.selectedOrg);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [payloadOrder, setPayloadOrder] = React.useState<any>({});

  const loadingQuestions = React.useCallback(() => {
    const payload: IPayloadLoadingIncidentQs = {
      query: {
        organization_id: myOrgId,
      },
    };

    return dispatch(loadingIncidentQuestionAction(payload));
  }, [myOrgId]);

  React.useEffect(() => {
    loadingQuestions();
  }, [myOrgId]);

  React.useEffect(() => {
    if (Array.isArray(questions)) {
      setDataSource(questions);
    }
  }, [questions]);

  const handleVisibleDelete = (item: any) => {
    setSelectedItem(item);
    setIsVisibleDelete(true);
  };

  const handleCancelDelete = () => {
    setSelectedItem(null);
    setIsVisibleDelete(false);
  };

  const onDeleteIncidentQs = () => {
    const payload: IPayloadDeleteIncidentQuestion = {
      values: {
        action: EActionIncidentQuestion.REMOVE,
        organization_id: myOrgId,
        id: selectedItem?.question_detail?._id,
        question_name: selectedItem?.question_detail?.question_name,
        answer_value: selectedItem?.question_detail?.answer_value,
        question_type: selectedItem?.question_detail?.question_type,
        visibility: selectedItem?.question_detail?.visibility,
        is_required: selectedItem?.question_detail?.is_required,
        editable: selectedItem?.question_detail?.editable,
        question_detail: selectedItem?.question_detail,
      },
      onSuccess: () => {
        loadingQuestions();
        handleCancelDelete();
        showSuccess(t('settingPage.incidentQuestion.deleteSuccess'));
      },
    };

    return dispatch(deleteIncidentQuestionAction(payload));
  };

  const onClickAddQuestion = () => {
    setIsVisibleForm(true);
  };

  const handleVisibleForm = (item: any, checkOrgId = '') => {
    const disabled = isJustView || item?.question_detail.org_id !== checkOrgId;
    setSelectedItem(item);
    setIsVisibleForm(true);
    setIsDisabled(disabled);
  };

  const handleCancelForm = () => {
    setSelectedItem(null);
    setIsVisibleForm(false);
    setIsDisabled(false);
  };

  const onFinishForm = ({
    visibility,
    question_name,
    question_type,
    is_required,
    editable,
  }: ICreateIncidentQuestionFormValues) => {
    const isEdited: boolean = !!selectedItem;

    const containDataAnswers: EQuestionType[] = [
      EQuestionType.DROPDOWN,
      EQuestionType.CHECKBOXLIST,
      EQuestionType.RATING,
    ];

    let dataAnswers: string | string[] = '';

    if (containDataAnswers.includes(question_type)) {
      dataAnswers = childFormRef.current.getDataAnswers();
    }

    if (isEdited) {
      const payload: IPayloadEditIncidentQuestion = {
        values: {
          action: EActionIncidentQuestion.UPDATE,
          id: selectedItem?.question_detail?._id,
          organization_id: myOrgId,
          answer_value: dataAnswers,
          visibility,
          question_name: trim(question_name),
          question_type,
          is_required,
          editable,
        },
        onSuccess: () => {
          loadingQuestions();

          handleCancelForm();
          showSuccess(t('settingPage.incidentQuestion.editSuccess'));
        },
      };

      return dispatch(editIncidentQuestionAction(payload));
    }

    const payload: IPayloadCreateIncidentQuestion = {
      values: {
        action: EActionIncidentQuestion.ADD,
        organization_id: myOrgId,
        visibility,
        question_name: trim(question_name),
        question_type,
        answer_value: dataAnswers,
        is_required,
        editable,
      },
      onSuccess: () => {
        loadingQuestions();

        handleCancelForm();
        showSuccess(t('settingPage.incidentQuestion.createSuccess'));
      },
    };

    return dispatch(createIncidentQuestionAction(payload));
  };

  const renderTitleTable = (title: string) => {
    return (
      <Space>
        <Typography.Text>{t(`settingPage.incidentQuestion.${title}`)}</Typography.Text>
        <Tooltip title={t(`settingPage.incidentQuestion.${title}.description`)}>
          <InfoCircleFilled className="ic-info" />
        </Tooltip>
      </Space>
    );
  };

  const handleChangeVisibility = (checked: boolean, question_id: string) => {
    const payload: IPayloadChangeVisibilityIncidentQuestion = {
      values: {
        status: checked,
        question_id,
      },
      onError: (code) => {
        showError(t(`error.common`));
      },
      onSuccess: () => {
        // showSuccess(t(`settingPage.incidentQuestion.${checked ? 'unhide' : 'hide'}_success`));
      },
    };

    return dispatch(changeVisibilityIncidentQuestionAction(payload));
  };

  const columns: any = [
    {
      dataIndex: 'sort',
      width: 48,
      align: 'center',
      className: 'drag-visible',
      render: () => (
        <Tooltip placement="topLeft" title={t('setting.tooltip.dragIcon')}>
          <div>
            <DragHandle />
          </div>
        </Tooltip>
      ),
    },
    {
      title: renderTitleTable('question'),
      dataIndex: 'question_name',
      ellipsis: {
        showTitle: false,
      },
      render: (_: any, record: any) => {
        const questionName: string = record?.question_detail.question_name;

        return (
          <Tooltip placement="bottomLeft" title={questionName}>
            <Typography.Text
              onClick={() => handleVisibleForm(record, myOrgId)}
              className="txt-question"
            >
              {questionName}
            </Typography.Text>
          </Tooltip>
        );
      },
    },
    {
      title: renderTitleTable('questionType'),
      dataIndex: 'type',
      width: 220,
      render: (_: any, record: any) =>
        t(`default.question.type.${record?.question_detail?.question_type?.toLocaleLowerCase?.()}`),
    },
    {
      title: t('settingPage.incidentQuestion.questionType.required'),
      dataIndex: 'is_required',
      width: 90,
      align: 'center',
      render: (_: any, record: any) => {
        const isRequired: boolean = record?.question_detail?.is_required;

        if (isRequired) {
          return <img alt="" src="/images/ic-check-grey.png" />;
        }

        return null;
      },
    },
    {
      title: t('settingPage.incidentQuestion.visibility'),
      dataIndex: 'visibility_custom',
      width: 190,
      align: 'center',
      render: (_: any, record: any) => {
        const createdByCurrentOrg = record?.question_detail?.org_id === myOrgId;
        const allowEdit = record?.question_detail?.editable;
        return (
          <Switch
            defaultChecked={!!record?.visibility_custom}
            size="small"
            className="visibility"
            onChange={(checked: boolean) => handleChangeVisibility(checked, record?._id)}
            disabled={!allowEdit && !createdByCurrentOrg}
          />
        );
      },
    },
    {
      title: () => {
        if (isJustView) return null;

        return (
          <Button
            icon={<PlusOutlined />}
            type="primary"
            className="btn-add-question"
            onClick={onClickAddQuestion}
          >
            {t('settingPage.incidentQuestion.addQuestion')}
          </Button>
        );
      },
      dataIndex: 'delete',
      width: 180,
      align: 'right',
      render: (_: any, record: any) => {
        if (isJustView || record?.question_detail.org_id !== myOrgId) {
          return null;
        }
        return (
          <Space size={15}>
            <Tooltip placement="topLeft" title={t('setting.tooltip.editIcon')}>
              <img
                alt=""
                src="/images/ic-edit.png"
                className="ic-edit"
                onClick={() => handleVisibleForm(record, myOrgId)}
              />
            </Tooltip>
            <Tooltip placement="topLeft" title={t('setting.tooltip.deleteIcon')}>
              <img
                alt=""
                src="/images/ic-delete.png"
                className="ic-delete"
                onClick={() => handleVisibleDelete(record)}
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable([].concat(...dataSource), oldIndex, newIndex).filter(
        (el) => !!el
      );
      const newOrders: string[] = newData.map((e: any) => e?._id);
      const payload: IPayloadDragIncidentQuestion = {
        values: {
          organization_id: myOrgId,
          orders: newOrders,
        },
        onSuccess: () => loadingQuestions(),
      };
      setPayloadOrder(payload);
      if (!myOrg.childrens || !myOrg.childrens.length) {
        dispatch(dragIncidentQuestionAction(payload));
        return;
      }
      setIsModalOpen(true);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleConfirm = () => {
    dispatch(dragIncidentQuestionAction(payloadOrder));
    setIsModalOpen(false);
  };

  const DraggableContainer = (props: any) => (
    <SortableContainers
      useDragHandle
      helperClass="row-dragging incident-question-drag"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex((x) => x._id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };
  return (
    <div className="incident-question">
      <Table
        pagination={false}
        dataSource={dataSource}
        columns={columns}
        rowKey={(item: any) => item?._id || item?.question_detail?._id}
        components={
          dataSource.length
            ? {
                body: {
                  wrapper: DraggableContainer,
                  row: DraggableBodyRow,
                },
              }
            : undefined
        }
        className="table-question"
        scroll={{ y: 'calc(100vh - 233px)' }}
        loading={isLoading}
        locale={{ emptyText: t('no_data', { name: t('settingMenu.incidentQuestion') }) }}
      />

      {/* Modal Form Incident Question */}
      <FormIncidentQs
        isVisible={isVisibleForm}
        onCancel={handleCancelForm}
        isLoading={isLoading}
        onFinish={onFinishForm}
        formRef={formRef}
        ref={childFormRef}
        selectedItem={selectedItem}
        loadingQuestions={loadingQuestions}
        isDisabled={isDisabled}
        selectedOrg={selectedOrg}
      />
      <ConfirmDeleteModal
        isVisible={isVisibleDelete}
        handleDelete={onDeleteIncidentQs}
        handleCancel={handleCancelDelete}
        modalTitle={t('settingPage.incidentQuestion.delete')}
        confirmText={t('settingPage.incidentQuestion.sure')}
        deleteButtonTitle={t('settingPage.incidentQuestion.deleteBtn')}
        isLoading={isLoading}
      />
      <ModalConfirm
        textConfirmBtn={t('settingMenu.message.saveBtn')}
        visible={isModalOpen}
        handleCancel={handleCancel}
        textConfirm={t('modal.confirmEdit.confirmText')}
        title={t('modal.confirmEdit.title')}
        handleOk={handleConfirm}
      />
    </div>
  );
};

export default React.memo(IncidentQuestion);

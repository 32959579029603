/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Modal, Space, Table, Tooltip, Typography } from 'antd';
import { IOtherIncidentByUserProps } from 'pages/setting/interfaces';
import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  EFilterResponseStatusIncidents,
  IPayloadLoadingOtherIncidents,
} from 'pages/incidents/interfaces';
import { FlagFilled } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { loadingOtherIncidentsAction } from 'actions/incidents';
import { OS } from 'utils';

const OtherIncidentByUser = ({ isVisible, onCancel, selectedItem }: IOtherIncidentByUserProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [dataSource, setDataSource] = React.useState<any[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const userRole: string = useAppSelector((state) => state.auth.role);

  const isOS: boolean = React.useMemo(() => userRole === OS, [userRole]);

  const getIncident = React.useCallback(() => {
    setIsLoading(true);

    const payload: IPayloadLoadingOtherIncidents = {
      device_user_id: selectedItem?.device_user_id?._id,
      onSuccess: (data: any) => {
        if (Array.isArray(data)) {
          setDataSource(data);
        }
        setIsLoading(false);
      },
      onError: (errorCode: string) => {
        console.log('🚀 ~ errorCode', errorCode);
        setIsLoading(false);
      },
    };

    return dispatch(loadingOtherIncidentsAction(payload));
  }, [selectedItem]);

  React.useEffect(() => {
    if (selectedItem && isVisible) {
      getIncident();
    }

    return () => {
      setDataSource([]);
    };
  }, [selectedItem, isVisible]);

  const handleClickRow = (item: any) => {
    if (isOS) return;

    onCancel();

    return history.push(`/admin/incidents/${item?._id}`);
  };

  const [sortType, setSortType] = React.useState<string>('');
  const [ascOrder, setAscOrder] = React.useState<boolean>(true);

  const handleClickSorter = (type) => {
    if (sortType === type) {
      if (ascOrder === false) {
        setSortType('');
      } else {
        setAscOrder(!ascOrder);
      }
    } else {
      setSortType(type);
      setAscOrder(true);
    }
  };

  const columns: any = [
    {
      title: () => {
        return (
          <div onClick={() => handleClickSorter('sequence')} className="table-header">
            <span
              className={
                sortType === 'sequence' ? (ascOrder === true ? 'upIcon' : 'downIcon') : 'normalIcon'
              }
            >
              {t('incidents.list.incident')}
            </span>
          </div>
        );
      },
      dataIndex: 'sequence',
      width: 104,
      className: 'incident-sequence',
      showSorterTooltip: false,
      ellipsis: {
        showTitle: false,
      },
      sorter: (a: any, b: any) => a.sequence - b.sequence,
      render: (sequence: number) => (
        <Tooltip placement="bottomLeft" title={sequence}>
          <Typography.Text className="txt-incident">{sequence}</Typography.Text>
        </Tooltip>
      ),
    },
    {
      title: t('incidents.list.msgs'),
      dataIndex: 'totalMessage',
      width: 96,
      render: (totalMessage: number, record: any) => {
        let src: string = '';
        let tooltip: string = '';
        switch (record?.response_status) {
          case EFilterResponseStatusIncidents.READ_ANSWERED:
            src = '/images/ic-read-anwered.png';
            tooltip = 'readAndAnwered';
            break;
          case EFilterResponseStatusIncidents.READ_UNANSWERED:
            src = '/images/ic-read-unanwered.png';
            tooltip = 'readAndUnanwered';
            break;
          case EFilterResponseStatusIncidents.UNREAD:
            if (totalMessage > 0) {
              src = '/images/ic-unread-dot.png';
            } else {
              src = '/images/ic-unread.png';
            }
            tooltip = 'unread';
            break;
          default:
            break;
        }
        return (
          <Space>
            {src && (
              <Tooltip placement="bottomLeft" title={t(`incidents.list.${tooltip}`)}>
                <img alt="" src={src} />
              </Tooltip>
            )}
            <Typography.Text>{totalMessage || 0}</Typography.Text>
          </Space>
        );
      },
    },
    {
      title: t('incidents.list.status'),
      dataIndex: 'status',
      width: 146,
      ellipsis: {
        showTitle: false,
      },
      render: (status: any) => {
        return (
          <Tooltip placement="bottomLeft" title={status?.name}>
            <Typography.Text>{status?.name}</Typography.Text>
          </Tooltip>
        );
      },
    },
    {
      title: t('incidents.list.description'),
      dataIndex: 'description',
      width: 330,
      ellipsis: {
        showTitle: false,
      },
      render: (record: string) => (
        <Tooltip placement="bottomLeft" title={record}>
          {record}
        </Tooltip>
      ),
    },
    {
      title: t('incidents.list.org'),
      dataIndex: 'organization',
      width: 300,
      ellipsis: {
        showTitle: false,
      },
      render: (_: any, record: any) => {
        const orgName: string | null = record?.org_id?.name;
        return (
          <Tooltip placement="bottomLeft" title={orgName}>
            {orgName}
          </Tooltip>
        );
      },
    },
    {
      title: () => {
        return (
          <div onClick={() => handleClickSorter('updatedAt')} className="table-header">
            <span
              className={
                sortType === 'updatedAt'
                  ? ascOrder === true
                    ? 'upIcon'
                    : 'downIcon'
                  : 'normalIcon'
              }
            >
              {t('incidents.list.updated')}
            </span>
          </div>
        );
      },
      dataIndex: 'updatedAt',
      width: 125,
      sorter: (a: any, b: any) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
      showSorterTooltip: false,
      ellipsis: {
        showTitle: false,
      },
      render: (record: string) => {
        const text: string = moment(record).format('YYYY/MM/DD');
        return (
          <Tooltip placement="bottomLeft" title={text}>
            {text}
          </Tooltip>
        );
      },
    },
    {
      title: () => {
        return (
          <div onClick={() => handleClickSorter('createdAt')} className="table-header">
            <span
              className={
                sortType === 'createdAt'
                  ? ascOrder === true
                    ? 'upIcon'
                    : 'downIcon'
                  : 'normalIcon'
              }
            >
              {t('incidents.list.submitted')}
            </span>
          </div>
        );
      },
      dataIndex: 'createdAt',
      width: 125,
      sorter: (a: any, b: any) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      showSorterTooltip: false,
      ellipsis: {
        showTitle: false,
      },
      render: (record: string) => {
        const text: string = moment(record).format('YYYY/MM/DD');
        return (
          <Tooltip placement="bottomLeft" title={text}>
            {text}
          </Tooltip>
        );
      },
    },
    {
      title: t('incidents.list.priority'),
      dataIndex: 'priority',
      width: 80,
      align: 'center',
      ellipsis: {
        showTitle: false,
      },
      render: (priority: any) => {
        return (
          <Tooltip placement="bottomLeft" title={priority?.name}>
            {priority?.color ? <FlagFilled style={{ color: priority?.color }} /> : null}
          </Tooltip>
        );
      },
    },
  ];

  return (
    <Modal
      title={t('settingPage.deactivatedUser.otherIncident')}
      centered
      visible={isVisible}
      maskClosable={false}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" className="btn-close" size="large" onClick={onCancel}>
          {t('incidents.other_incident.close')}
        </Button>,
      ]}
      className="modal md-other-incident-user"
      width={1366}
    >
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        className="table-other-incident-user"
        onRow={(item: any, index: any) => ({
          className: index % 2 === 0 ? 'row-odd' : '',
          onClick: () => handleClickRow(item),
        })}
        rowKey="_id"
        loading={isLoading}
        locale={{ emptyText: t('no_data', { name: t('list.empty.incident') }) }}
      />
    </Modal>
  );
};

export default React.memo(OtherIncidentByUser);

import React, { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  Space,
  Typography,
  Tooltip,
  Divider,
  Checkbox,
  Row,
  Col,
} from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import { EShareFields, IPayloadShareIncidents } from 'pages/incidents/interfaces';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { isEmail } from 'utils';
import { shareIncidentsAction } from 'actions/incidents';
import { useAppDispatch } from 'app/hooks';
import { showError, showSuccess } from 'components/standby-notice';
import locale from 'antd/es/date-picker/locale/ja_JP';
import trim from 'lodash/trim';

interface IShareIncidentsProps {
  isVisible: boolean;
  onCancel: () => void;
}

const ShareIncidentDetail = ({ isVisible, onCancel }: IShareIncidentsProps, ref) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { id }: any = useParams();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const listChecked: any[] = [
    { key: 'status', value: EShareFields.INCIDENT_STATUS },
    { key: 'taken', value: EShareFields.INCIDENT_TAKEN },
    { key: 'type', value: EShareFields.INCIDENT_TYPE },
    { key: 'perpetrators', value: EShareFields.PERPETRATORS },
    { key: 'label', value: EShareFields.INCIDENT_LABELS },
    { key: 'targets', value: EShareFields.TARGETS },
    { key: 'incidentQuestion', value: EShareFields.INCIDENT_QUESTION },
    { key: 'notes', value: EShareFields.NOTES },
    { key: 'assignee', value: EShareFields.ASSIGNEE },
    { key: 'chat', value: EShareFields.CHAT },
    { key: 'location', value: EShareFields.INCIDENT_LOCATION },
    { key: 'pdf', value: EShareFields.ATTACH_INCIDENT_DETAIL_PDF },
    { key: 'priority', value: EShareFields.INCIDENT_PRIORITY },
  ];

  const handleCheckAll = (event: any) => {
    const { checked } = event.target;

    const setFields: any = {};

    listChecked.forEach(({ key }) => {
      setFields[key] = checked;
    });

    form.setFields([
      { name: 'all', errors: checked ? [] : [t('incidents.detail.share.checkAtLeast')] },
    ]);

    form.setFieldsValue(setFields);
  };

  const handleCheckChild = (event: any) => {
    const { checked } = event.target;

    let sameValueAllChild: boolean = true;

    for (const child of listChecked) {
      const childValue: boolean = !!form.getFieldValue(child.key);

      if (childValue !== checked) {
        sameValueAllChild = false;
        break;
      }
    }

    if (!checked && sameValueAllChild) {
      sameValueAllChild = false;
    }

    form.setFields([{ name: 'all', errors: [] }]);

    form.setFieldsValue({ all: sameValueAllChild });
  };

  const onFinish = ({ all, description, emails, expired, ...checkBoxs }: any) => {
    setLoading(true);
    const shareFields: EShareFields[] = [];

    listChecked.forEach(({ key, value }) => {
      if (checkBoxs?.[key]) {
        shareFields.push(value);
      }
    });

    if (shareFields.length === 0) return;

    const payload: IPayloadShareIncidents = {
      values: {
        incident_id: id,
        description,
        share_fields: shareFields,
        to: emails,
        expired: moment(expired).format('YYYY/MM/DD'),
      },
      onSuccess: () => {
        onCancel();
        showSuccess(t('incidents.detail.share.success', { number: emails.length }));
        ref.current!.resetFields();
        setLoading(false);
      },
      onError: (errorCode: string) => {
        console.log('🚀 ~ errorCode', errorCode);
        setLoading(false);
      },
    };

    return dispatch(shareIncidentsAction(payload));
  };

  const handleChangeEmail = (values: string[]) => {
    let isWrongEmail: boolean = false;
    const newEmails: string[] = values
      .map((email: string) => {
        const trimEmail: string = trim(email || '');

        const checkIsEmail: boolean = isEmail(trimEmail);

        if (checkIsEmail) return trimEmail;
        isWrongEmail = true;

        return '';
      })
      .filter((email: string) => !!email);

    if (isWrongEmail) {
      showError(t('loginPage.emailFormat'));
    }

    form.setFieldsValue({ emails: newEmails });
  };

  const submitForm = () => {
    const { all, description, emails, expired, ...checkBoxs }: any = form.getFieldsValue();

    let isChecked: boolean = false;

    for (const [, value] of Object.entries(checkBoxs)) {
      if (value) {
        isChecked = true;
        break;
      }
    }

    if (!isChecked) {
      form.setFields([{ name: 'all', errors: [t('incidents.detail.share.checkAtLeast')] }]);
    }

    form.submit();
  };

  return (
    <Modal
      title={t('incidents.detail.share.title')}
      centered
      maskClosable={false}
      visible={isVisible}
      onCancel={onCancel}
      className="modal modal-share-incidents"
      footer={[
        <Button
          key="large"
          type="primary"
          onClick={submitForm}
          className="btn-save"
          size="large"
          loading={loading}
        >
          {t('incident.share.share_btn')}
        </Button>,
        <Button
          key="cancel"
          className="btn-cancel"
          size="large"
          disabled={loading}
          onClick={onCancel}
        >
          {t('incident.share.cancel')}
        </Button>,
      ]}
      width={800}
    >
      <Form
        ref={ref}
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        scrollToFirstError
      >
        <Form.Item
          label={t('incidents.detail.share.email')}
          name="emails"
          rules={[{ required: true, message: t('incidents.detail.share.emailRequired') }]}
        >
          <Select
            mode="tags"
            size="large"
            placeholder={t('incidents.detail.share.emailPlaceholder')}
            className="select-email"
            onChange={handleChangeEmail}
            notFoundContent={null}
          />
        </Form.Item>

        <Form.Item
          name="description"
          label={t('incidents.detail.share.description')}
          rules={[
            {
              required: true,
              whitespace: true,
              message: t('incidents.detail.share.required.description'),
            },
          ]}
        >
          <Input.TextArea
            autoSize={{ minRows: 6, maxRows: 6 }}
            placeholder={t('incidents.detail.share.descriptionPlaceholder')}
            className="input-description"
            maxLength={12000}
          />
        </Form.Item>

        <Form.Item
          name="expired"
          rules={[{ required: true, message: t('incidents.detail.share.required.expired') }]}
        >
          <DatePicker
            size="large"
            suffixIcon={
              <React.Fragment>
                <div className="expired-date-container">
                  <img className="expired-date-icon" alt="" src="/images/clock-icon.svg"></img>
                  <span className="expired-date-text">{t('incidents.detail.share.expired')}:</span>
                </div>
              </React.Fragment>
            }
            className={`expired-date`}
            placeholder={''}
            format="YYYY/MM/DD"
            allowClear
            showToday={false}
            disabledDate={(current) => moment().isAfter(current, 'day')}
            locale={locale}
          />
        </Form.Item>

        <Space className="label-info">
          <Typography.Text className="txt">{t('incidents.detail.share.checkInfo')}</Typography.Text>
          <Tooltip title={t('incidents.detail.share.check.tooltips')}>
            <InfoCircleFilled className="ic-info" />
          </Tooltip>
        </Space>

        <Divider className="div-label-info" />

        <Form.Item name="all" valuePropName="checked">
          <Checkbox onChange={handleCheckAll}>{t('incidents.detail.share.checkAll')}</Checkbox>
        </Form.Item>

        <Row gutter={[0, 20]}>
          {listChecked.map(({ key }) => {
            return (
              <Col span={12} key={key}>
                <Form.Item name={key} valuePropName="checked" noStyle>
                  <Checkbox onChange={handleCheckChild}>
                    {t(`incidents.detail.share.${key}`)}
                  </Checkbox>
                </Form.Item>
              </Col>
            );
          })}
        </Row>
      </Form>
    </Modal>
  );
};

export default forwardRef(ShareIncidentDetail);

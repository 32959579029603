import API from 'libs/axios';
import {
  IChangeAutomatedResponseFormValues,
  ICreateHolidayFormValues,
  IEditHolidayFormValues,
} from 'pages/setting/interfaces';
import { queryString } from 'utils';

const PATH: string = 'admin/automated-response/settings';

export const getAutomatedResponse = (organization_id?: string) => {
  const params: any = {};

  if (organization_id) {
    params.organization_id = organization_id;
  }

  const qs: string = queryString(params);

  return API.get(`${PATH}?${qs}`);
};

export const postChangeAutomatedResponse = (payload: IChangeAutomatedResponseFormValues) =>
  API.post(`${PATH}/action`, payload);

export const postOnOffAutomatedResponse = (payload: IChangeAutomatedResponseFormValues) =>
  API.post(`${PATH}/action/on-off`, payload);

export const getHoliday = (org_id: string) => API.get(`${PATH}/holiday/list/${org_id}`);
export const postCreateHoliday = (payload: ICreateHolidayFormValues) =>
  API.post(`${PATH}/holiday/create`, payload);
export const putUpdateHoliday = (holiday_id: string, payload: IEditHolidayFormValues) =>
  API.put(`${PATH}/holiday/update/${holiday_id}`, payload);
export const deleteHoliday = (holiday_id: string) => API.delete(`${PATH}/delete/${holiday_id}`);
